import { ColDef } from "ag-grid-community";
import { CardsResponse } from "../../../../api/cards/types";
import ActionRenderer from "./actionRenderer";
import { StatusRenderer } from "./statusRenderer";

type CardsField = keyof CardsResponse | "action" | "sn" | undefined;

interface GridDef extends ColDef {
  field: CardsField;
}

export const columnDefsDesktop = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Sn",
      field: "sn",
      valueGetter: "node.rowIndex + 1",
      flex: 0.4,
    },
    {
      headerName: "Card Name",
      field: "name",
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1,
    },
    {
      headerName: "Date Created",
      field: "createdOn",
      sortable: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      valueFormatter: (params) => {
        const { data }: { data: CardsResponse } = params;
        const options: Intl.DateTimeFormatOptions = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
          new Date(data.createdOn)
        );
      },
    },
    {
      headerName: "Status",
      sortable: false,
      field: "cardStatus",
      filter: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Action",
      sortable: false,
      field: "cardStatus",
      filter: false,
      flex: 1.4,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

export const columnDefsTablet = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Sn",
      field: "sn",
      valueGetter: "node.rowIndex + 1",
      flex: 0.4,
    },
    {
      headerName: "Card Name",
      field: "name",
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1,
    },
    {
      headerName: "Date Created",
      field: "createdOn",
      sortable: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      valueFormatter: (params) => {
        const { data }: { data: CardsResponse } = params;
        const options: Intl.DateTimeFormatOptions = {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
          new Date(data.createdOn)
        );
      },
    },
    {
      headerName: "Action",
      sortable: false,
      field: "cardStatus",
      filter: false,
      flex: 1.4,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

export const columnDefsMobile = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Sn",
      field: "sn",
      valueGetter: "node.rowIndex + 1",
      flex: 0.3,
    },
    {
      headerName: "Card Name",
      field: "name",
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1,
    },
    {
      headerName: "Action",
      sortable: false,
      field: "cardStatus",
      filter: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

import { ReactComponent as PenIcon } from "../../../../../images/pen.svg";
import { ReactComponent as BlockIcon } from "../../../../../images/block.svg";
import { ReactComponent as ActivateIcon } from "../../../../../images/circle-tick.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Modal from "../modal";
import { useState } from "react";
import api from "../../../../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useMobileCheck } from "../../../../../hooks/useMobileCheck";
import { cryptoResponse } from "../../../../api/crypto/types";
import { refreshNeeded } from "../../../../redux/crypto/cryptoSlice";

const ActionRenderer = ({ data }: { data: cryptoResponse }) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { isMobile } = useMobileCheck();

  const handleOpen = (type: boolean) => {
    setModalStatus(type);
    setIsOpen(true);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await api.cryptoService.toggleCryptoStatus(
        data.id,
        !data.isActive
      );
      res.data.data &&
        toast.success(
          data.isActive
            ? "Crypto has been blocked successfully"
            : "Crypto has been activated successfully"
        );
      dispatch(refreshNeeded(true));
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <Container>
      {data.isActive ? (
        <>
          <ManageWrapper to={`/dashboard/crypto/edit/${data.id}`}>
            {!isMobile && <PenIcon />}
            <p>{isMobile ? `Edit` : `Edit Crypto`}</p>
          </ManageWrapper>
          <IconWrapper isActive={true} onClick={() => handleOpen(false)}>
            {!isMobile && <BlockIcon />}
            <p>{isMobile ? `Disable` : `Disable Crypto`}</p>
          </IconWrapper>
        </>
      ) : (
        <IconWrapper isDisabled={true} onClick={() => handleOpen(true)}>
          {!isMobile && <ActivateIcon />}
          <p>Enable Crypto</p>
        </IconWrapper>
      )}
      <Modal
        isDisabled={modalStatus}
        isOpen={isOpen}
        isLoading={isLoading}
        handleClose={() => setIsOpen(false)}
        handleSubmit={handleSubmit}
      />
    </Container>
  );
};

export default ActionRenderer;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ManageWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  width: 106px;
  height: 30px;
  background: #727272;
  border-radius: 3px;
  cursor: pointer;

  & > svg {
    width: 11px;
    height: 11px;
  }

  & > p {
    letter-spacing: -0.1px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 600;
  }
`;

interface IconWrapperProps {
  isDisabled?: boolean;
  isActive?: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 5px 14px;
  height: 30px;
  background: ${(props) => (props.isDisabled ? "#1ABA27" : "#F23030")};
  border-radius: 3px;
  cursor: pointer;

  & > svg {
    width: 12px;
    height: 12px;
  }

  .active {
    width: 17px;
    height: 17px;
    padding-top: 1px;
  }

  & > p {
    letter-spacing: -0.1px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 600;
  }
`;

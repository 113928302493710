import styled from "styled-components";
import mediaQueries from "../../../../../utils/mediaQueries";

const EmptyStateRenderer = () => {
  return (
    <EmptyWrapper>
      <p>Nothing to see here</p>
      <p>Looks like nothing matches that search.</p>
    </EmptyWrapper>
  );
};

export default EmptyStateRenderer;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  & > p:first-of-type {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }

  & > p:last-of-type {
    letter-spacing: 0px;
    color: #6f6f6f;
    font-size: 18px;
  }

  ${mediaQueries.mobile} {
    & > p:first-of-type {
      font-size: 15px;
    }

    & > p:last-of-type {
      font-size: 12px;
    }
  }
`;

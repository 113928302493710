import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";
import DualRing from "../../../components/loader";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isDisabled: boolean;
  isLoading: boolean;
}

const Modal: React.FC<props> = ({
  isOpen,
  handleClose,
  handleSubmit,
  isLoading,
  isDisabled,
}) => {
  let cancelLabel = "";
  let confirmLabel = "";
  let description = "";
  let title = "";

  switch (isDisabled) {
    case true:
      cancelLabel = "No, Go back";
      confirmLabel = "Yes, Enable";
      description = "This card will now be visible to users.";
      title = "Enable this card?";
      break;
    case false:
      cancelLabel = "No, Go back";
      confirmLabel = "Yes, Disable";
      description = "This card will no longer be visible to users.";
      title = "Disable this card?";
      break;

    default:
      break;
  }
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth={"xs"}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ fontSize: 22, paddingTop: 4 }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonWrapper>
          <CancelButton onClick={handleClose}>{cancelLabel}</CancelButton>
          <ConfirmButton onClick={handleSubmit} isDisabled={isDisabled}>
            {isLoading ? <DualRing width="10px" height="10px" /> : confirmLabel}
          </ConfirmButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding: 20px 15px 15px;
  border-top: 1px solid #c3c3c3;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  letter-spacing: -0.2px;
  font-weight: bold;
  font-size: 10px;
  color: #727272;
  border: 0.5px solid #727272;
  border-radius: 3px;
  cursor: pointer;
`;

interface confirmProps {
  isDisabled?: boolean;
}

const ConfirmButton = styled.div<confirmProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  background: ${(props) => (props.isDisabled ? "#1ABA27" : "#ED3838")};
  letter-spacing: -0.22px;
  color: #ffffff;
  font-size: 11px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;

  & > div {
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

import { useEffect, useState } from "react";
import styled from "styled-components";
import DashboardCard from "../../../components/dashboardCard";
import { ReactComponent as LeftIcon } from "../../../../images/grey-left-circle.svg";
import { ReactComponent as RightIcon } from "../../../../images/grey-right-circle.svg";
import { ReactComponent as ChartIcon } from "../../../../images/chart-icon.svg";
import { motion } from "framer-motion";
import { PerformanceEnum } from "../../../../types/common";
import api from "../../../api";
import { DashboardPerformanceResponse } from "../../../api/dashboard/types";
import { toast } from "react-toastify";
import mediaQueries from "../../../../utils/mediaQueries";
import { useAppSelector } from "../../../redux";
import { getAuth } from "../../../redux/auth/authSlice";

const initialState: DashboardPerformanceResponse = {
  percentage: 0,
  revenue: 0,
};

const PerformanceCard = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [dayPerformanceData, setDayPerformanceData] =
    useState<DashboardPerformanceResponse>(initialState);
  const [monthPerformanceData, setMonthPerformanceData] =
    useState<DashboardPerformanceResponse>(initialState);

  const {
    profile: { response },
  } = useAppSelector(getAuth);

  const getPerformanceData = async (
    id: PerformanceEnum = PerformanceEnum.Today
  ) => {
    try {
      const res = await api.dashboardService.getDashboardPerformance(id);
      id === PerformanceEnum.Today && setDayPerformanceData(res.data.data);
      id === PerformanceEnum.Month && setMonthPerformanceData(res.data.data);
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (
      response?.permissions.includes("DASHBOARD") ||
      (response?.roles &&
        response?.roles.length > 0 &&
        response?.roles[0].toLowerCase() === "superadmin")
    ) {
      Promise.all([
        getPerformanceData(PerformanceEnum.Month),
        getPerformanceData(),
      ]);
    }
  }, [response]);

  return (
    <Container>
      <TopLeftContent>
        {activeIndex === 1 && (
          <motion.p
            initial={{
              opacity: 0,
              x: -40,
            }}
            exit={{
              opacity: 0,
              x: -70,
              transition: { duration: 1 },
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{ duration: 0.7 }}
          >
            Today’s Performance{" "}
          </motion.p>
        )}
        {activeIndex === 2 && (
          <motion.p
            initial={{
              opacity: 0,
              x: 40,
            }}
            exit={{
              opacity: 0,
              x: 70,
              transition: { duration: 1 },
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{ duration: 0.7 }}
          >
            30 Days Performance{" "}
          </motion.p>
        )}
        <div>
          <ArrowIconWrapper active={activeIndex === 2}>
            <LeftIcon onClick={() => setActiveIndex(1)} />
          </ArrowIconWrapper>
          <ArrowIconWrapper active={activeIndex === 1}>
            <RightIcon onClick={() => setActiveIndex(2)} />
          </ArrowIconWrapper>
        </div>
      </TopLeftContent>
      {activeIndex === 1 && (
        <TodayWrapper
          initial={{
            opacity: 0,
            x: -40,
          }}
          exit={{
            opacity: 0,
            x: -70,
            transition: { duration: 1 },
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{ duration: 0.7 }}
        >
          <DashboardCard
            value={dayPerformanceData.revenue}
            percentage={dayPerformanceData.percentage}
            icon={ChartIcon}
          />
        </TodayWrapper>
      )}
      {activeIndex === 2 && (
        <MonthlyWrapper
          initial={{
            opacity: 0,
            x: 40,
          }}
          exit={{
            opacity: 0,
            x: 70,
            transition: { duration: 1 },
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{ duration: 0.7 }}
        >
          <DashboardCard
            value={monthPerformanceData.revenue}
            percentage={monthPerformanceData.percentage}
            icon={ChartIcon}
          />
        </MonthlyWrapper>
      )}
    </Container>
  );
};

export default PerformanceCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  ${mediaQueries.mobile} {
    width: 100%;
    overflow: hidden;
  }
`;

const TodayWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const MonthlyWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const TopLeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;

  & > p {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  ${mediaQueries.mobile} {
    margin-bottom: 15px;

    & > p {
      font-size: 17px;
    }
  }
`;

interface arrowProps {
  active: boolean;
}

const ArrowIconWrapper = styled.div<arrowProps>`
  & > svg {
    cursor: ${(props) => (props.active ? "pointer" : "default")};

    circle {
      fill: ${(props) => (props.active ? "#727272" : "#D8D8D8")};
      transition: fill 0.25s ease-in-out;
    }

    path {
      fill: ${(props) => (props.active ? "#FFFFFF" : "#727272")};
      transition: fill 0.25s ease-in-out;
    }
  }
`;

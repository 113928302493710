import { createAsyncThunk } from "@reduxjs/toolkit";
import parseError from "../../../utils/parseError";
import api from "../../api";
import { getCryptoSalePayload } from "../../api/cryptoSale/types";

export const getCryptoSaleAction = createAsyncThunk(
  "crypto/getCryptoSaleAction",
  async (payload: getCryptoSalePayload, thunkAPI) => {
    const { pageIndex, transactionStatus, keyword } = payload;
    try {
      const response = await api.cryptoSaleService.getCryptoSale(
        pageIndex,
        transactionStatus,
        keyword
      );
      return response.data;
    } catch (error) {
      let errorMessage = parseError(error as any);
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

import React, { useRef } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import {
  gridCellStyle,
  GridWrapper,
} from "../components/gridComponents/styles";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import EmptyStateRenderer from "../components/gridComponents/emptyStateRenderer";
import { WithdrawalResponse } from "../../../api/transactions/types";
import { useMobileCheck } from "../../../../hooks/useMobileCheck";
import {
  columnDefsDesktop,
  columnDefsMobile,
  columnDefsTablet,
} from "./components/withdrawalColumnDef";
import mediaQueries from "../../../../utils/mediaQueries";

interface GridProps {
  data: WithdrawalResponse[];
  tabValue: number;
}

type WithdrawalsField = keyof WithdrawalResponse | "action" | undefined;

export interface GridDef extends ColDef {
  field: WithdrawalsField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: WithdrawalResponse[];
}

const WithdrawalsGrid: React.FC<GridProps> = ({ data, tabValue }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: WithdrawalResponse[] = data;
  const { isMobile, isTablet } = useMobileCheck();

  const columnDefs: GridDef[] = isTablet
    ? columnDefsTablet()
    : isMobile
    ? columnDefsMobile()
    : columnDefsDesktop();

  const gridConfig: GridConfigTypes = {
    columnDefs: columnDefs,
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <>
      {GridData?.length > 0 ? (
        <GridWrapper id="OpenOrdersGridWrapper" className={`ag-theme-balham`}>
          <AgGridReact
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={43}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            defaultColDef={{
              sortable: true,
              filter: false,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            getRowNodeId={(data: WithdrawalResponse) => {
              return data.id.toString();
            }}
            noRowsOverlayComponentFramework={EmptyStateRenderer}
            gridOptions={{
              onSortChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onFilterChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>
            {tabValue === 1
              ? "You have no pending Withdrawals"
              : tabValue === 2
              ? "You have no completed Withdrawals"
              : "You have no declined Withdrawals"}
          </p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default WithdrawalsGrid;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 130px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }

  ${mediaQueries.mobile} {
    & > p {
      font-size: 15px;
    }
  }
`;

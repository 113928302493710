import { ApiResponse } from "../../redux/types";
import request from "../request";
import { faqObj, faqReponse } from "./types";

const getFaqs = async () => {
  return await request.get<ApiResponse<faqReponse[]>>("/faq");
};

const CreateFaqs = async (payload: faqObj) => {
  return await request.post<ApiResponse<boolean>>("/faq", payload);
};

const UpdateFaq = async (id: string, payload: faqObj) => {
  return await request.put<ApiResponse<boolean>>(`/faq/${id}`, payload);
};

const DeleteFaq = async (id: string) => {
  return await request.delete<ApiResponse<boolean>>(`/faq/${id}`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getFaqs,
  CreateFaqs,
  UpdateFaq,
  DeleteFaq,
};

import { useNavigate } from "react-router";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { RecentActivitiesObj } from "../../../api/dashboard/types";
import { BottomWrapper, Container, TopWrapper } from "./cardSalesGraph";
import Grid from "./grid";

interface props {
  data: RecentActivitiesObj[];
  isLoading: boolean;
}

const ActivityCard: React.FC<props> = ({ data, isLoading }) => {
  const navigate = useNavigate();
  return (
    <GridWrapper>
      <TopWrapper>
        <p>Recent Activity</p>
        <span onClick={() => navigate("/dashboard/transactions/sales")}>
          See All
        </span>
      </TopWrapper>
      <BottomWrapper>
        <Grid data={data} isLoading={isLoading} />
      </BottomWrapper>
    </GridWrapper>
  );
};

export default ActivityCard;

const GridWrapper = styled(Container)`
  margin-top: 9px;

  ${mediaQueries.mobile} {
    margin-top: 20px;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import eyeIcon from "../../../../images/eye.png";
import eyeSlashIcon from "../../../../images/eye-slash.png";

interface props {
  onChange: any;
  id?: string;
  name?: string;
  value?: string;
  label: string;
  error?: string[] | string;
}

const AuthPasswordInput: React.FC<props> = ({
  name,
  id,
  onChange,
  value,
  error,
  label,
}) => {
  const [isShowing, setIsShowing] = useState(false);

  const handleShowToggle = () => {
    setIsShowing((prev) => !prev);
  };
  return (
    <PasswordInputWrapper>
      <Label>{label}</Label>
      {name ? (
        <PasswordInput
          type={isShowing ? "text" : "password"}
          value={value}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          name={name}
        />
      ) : (
        <PasswordInput
          type={isShowing ? "text" : "password"}
          value={value}
          onChange={onChange}
          id={id}
        />
      )}
      {error && (
        <Error>{error instanceof Array ? error.join(",") : error}</Error>
      )}
      {isShowing ? (
        <Icon src={eyeSlashIcon} onClick={() => handleShowToggle()} />
      ) : (
        <Icon src={eyeIcon} onClick={() => handleShowToggle()} />
      )}
    </PasswordInputWrapper>
  );
};

export default AuthPasswordInput;

const PasswordInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const Icon = styled.img`
  position: absolute;
  right: 13px;
  top: 35px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const Label = styled.p`
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #727272;
`;

const Error = styled.span`
  text-align: left;
  font-size: 10px;
  color: #d90b2c;
`;

const PasswordInput = styled.input`
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  padding: 0 42px 0 10px;
  background: #f7f7f7;
`;

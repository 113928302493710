import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";
import DualRing from "../../../../components/loader";
import { TransactionActionPayload } from "../../../../api/transactions/types";
import api from "../../../../api";
import { TransactionStatusEnum } from "../../../../../types/common";
import { toast } from "react-toastify";
import { initialTransactionObj } from "./partialApprove";
import { useNavigate } from "react-router";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  isOpen: boolean;
  handleClose: () => void;
  id?: string;
}

const DeclineModal: React.FC<props> = ({ isOpen, handleClose, id }) => {
  const [formData, setFormData] = React.useState<TransactionActionPayload>();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleCloseModal = () => {
    handleClose();
    setFormData(initialTransactionObj);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (formData && id) {
        const res = await api.transactionService.action(id, {
          ...formData,
          amount: 0,
          transactionStatus: TransactionStatusEnum.Declined,
        });
        res?.data.data && toast.success("Transaction has been declined");
      }
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
    handleCloseModal();
    setTimeout(() => {
      navigate("/dashboard/transactions/sales", { replace: true });
    }, 1500);
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          fontSize: 21,
          paddingTop: 5,
          paddingBottom: 1,
          paddingLeft: 4,
          fontFamily: "Plus Jakarta Sans",
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        Decline this transaction?
      </DialogTitle>
      <DialogContent
        sx={{
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <DialogContentText id="alert-dialog-slide-description">
          <ContentWrapper>
            <TopContent>
              Customer will be notified and will have to restart sale process.
            </TopContent>
            <textarea
              rows={8}
              placeholder="Enter feedback for customer here"
              name="comment"
              value={formData?.comment}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </ContentWrapper>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <ButtonWrapper>
          <CancelButton onClick={() => handleCloseModal()}>
            No, Go back
          </CancelButton>
          <ConfirmButton
            onClick={() => handleSubmit()}
            disabled={!formData?.comment}
          >
            {isLoading ? (
              <DualRing width="10px" height="10px" />
            ) : (
              "Yes, Decline"
            )}
          </ConfirmButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default DeclineModal;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding: 20px 0px 15px;
  border-top: 1px solid #c3c3c3;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  letter-spacing: -0.2px;
  font-weight: bold;
  font-size: 12px;
  color: #727272;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    color: #4d4b4b;
  }
`;

const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  background: #ed3838;
  letter-spacing: -0.22px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  outline: none;
  border: none;
  cursor: pointer;

  & > div {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  &:disabled {
    background-color: #727272;
    cursor: not-allowed;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > textarea {
    height: 135px;
    padding: 10px;
    background: #ffffff;
    border: 0.5px solid #707070;
    border-radius: 5px;
    outline: none;
  }
`;

const TopContent = styled.p`
  letter-spacing: -0.26px;
  color: #727272;
  font-size: 13px;
  font-weight: 500;
`;

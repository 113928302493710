import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { ReactComponent as LeftIcon } from "../../../images/left-arrow.svg";
import mediaQueries from "../../../utils/mediaQueries";
import { faqObj } from "../../api/faq/types";
import Contact from "./components/contact";
import Faq from "./components/faq";
import HomeScreen from "./components/homeScreen";
import SystemPreferences from "./components/preferences";
import Terms from "./components/terms";
import { tabData } from "./data";
import PushNotifications from "./components/pushNotifications";

const Preferences = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [faqs, setFaqs] = useState<faqObj[]>([]);

  let navigate = useNavigate();

  const isValid = Object.values(faqs).every((x) => x.id);

  useEffect(() => {
    !isValid &&
      document.getElementById(faqs.length.toString())?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqs]);

  const handleAdd = () => {
    setFaqs([...faqs, { answer: "", question: "" }]);
  };

  const handleNavigate = () => {
    navigate("/dashboard", { replace: true });
  };

  return (
    <Container>
      <TitleWrapper>
        <h1>Admin Profile</h1>
        {activeTab === 5 && (
          <AddNewBtn onClick={() => handleAdd()} disabled={!isValid}>
            + Add New Question
          </AddNewBtn>
        )}
      </TitleWrapper>
      <ContentWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        <BottomWrapper>
          <TabsWrapper>
            {tabData.map((x, i) => (
              <Tab
                key={i}
                onClick={() => setActiveTab(i + 1)}
                isActive={activeTab === i + 1}
              >
                {x.name}
              </Tab>
            ))}
          </TabsWrapper>
          <RightContentWrapper>
            {activeTab === 1 && <SystemPreferences />}
            {activeTab === 2 && <HomeScreen />}
            {activeTab === 3 && <Terms />}
            {activeTab === 4 && <Contact />}
            {activeTab === 5 && <Faq faqs={faqs} setFaqs={setFaqs} />}
            {activeTab === 6 && <PushNotifications />}
          </RightContentWrapper>
        </BottomWrapper>
      </ContentWrapper>
    </Container>
  );
};
export default Preferences;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 35px 20px;
  width: 100%;
  margin-top: 90px;

  ${mediaQueries.mobileAndTablet} {
    padding: 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > h1 {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }
`;

const AddNewBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 40px;
  background: transparent linear-gradient(174deg, #2b5a9d 0%, #041630 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  opacity: 1;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding: 20px;

  ${mediaQueries.tablet} {
    padding: 0;
  }

  ${mediaQueries.mobile} {
    padding: 0;
    background: transparent;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  padding: 25px 15px 15px;
  border-bottom: 1px solid #e6e6e6;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 11px;
    }

    & > p {
      letter-spacing: -0.13px;
      color: #000000;
      font-size: 13px;
      font-weight: bold;
    }
  }

  ${mediaQueries.tablet} {
    padding: 10px;
  }

  ${mediaQueries.mobile} {
    padding: 10px 0;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  width: 100%;

  ${mediaQueries.mobileAndTablet} {
    flex-direction: column;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 20px 50px 20px 30px;
  height: 60vh;
  border-right: 1px solid #e6e6e6;

  ${mediaQueries.mobileAndTablet} {
    flex-direction: row;
    gap: 10px;
    padding: 0;
    height: auto;
    overflow-x: scroll;
    border-right: none;
  }

  ${mediaQueries.tablet} {
    padding: 0 10px;
  }
`;

interface tabProps {
  isActive: boolean;
}

const Tab = styled.div<tabProps>`
  display: flex;
  align-items: center;
  padding: 7px 12px;
  width: 160px;
  height: 35px;
  font-weight: bold;
  font-size: 13px;
  color: ${(props) => (props.isActive ? "#FFFFFF" : "#727272")};
  background: ${(props) => props.isActive && "#faa84f"};
  border-radius: 5px;
  transition: all 0.2s ease-in;
  cursor: pointer;

  ${mediaQueries.tablet} {
    width: auto;
    flex-shrink: 0;
    padding: 7px 12px;
  }

  ${mediaQueries.mobile} {
    width: auto;
    flex-shrink: 0;
    font-size: 12px;
    padding: 6px 10px;
  }
`;

const RightContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  padding: 20px 50px;
  max-width: 880px;
  max-height: calc(100vh - 265px);
  overflow: scroll;

  ${mediaQueries.smallDesktop} {
    padding: 20px 20px 20px 35px;
  }

  ${mediaQueries.tablet} {
    padding: 40px 10px;
  }

  ${mediaQueries.mobile} {
    padding: 0 0 20px;
    margin-top: 10px;
  }
`;

import { ApiResponse } from "../../redux/types";
import request from "../request";
import {
  CountriesResponse,
  CreateCountryPayload,
  EditCountryPayload,
} from "./types";

const getCountries = async () => {
  return await request.get<ApiResponse<CountriesResponse[]>>(
    "/Country/No-Pagination"
  );
};

const addCountry = async (payload: CreateCountryPayload) => {
  const formData = new FormData();
  const { countryName, logo } = payload;
  if (logo && countryName) {
    formData.append("countryName", countryName);
    formData.append("logo", logo);
  }
  return await request.post<ApiResponse<boolean>>("/Country", formData);
};

const getSingleCountry = async (id: string) => {
  return await request.get<ApiResponse<CountriesResponse>>(`/Country/${id}`);
};

const deleteCountry = async (id: string) => {
  return await request.delete<ApiResponse<boolean>>(`/Country/${id}`);
};

const editCountry = async (id: string, payload: EditCountryPayload) => {
  const formData = new FormData();
  const { name, logo } = payload;
  if (name) {
    formData.append("name", name);
  }
  if (logo) formData.append("logo", logo);
  return await request.put<ApiResponse<boolean>>(`/Country/${id}`, formData);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCountries,
  addCountry,
  getSingleCountry,
  deleteCountry,
  editCountry,
};

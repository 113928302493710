import { useEffect } from "react";
import { Outlet, useLocation } from "react-router";
import styled from "styled-components";
import storage from "../../utils/storage";
import Header from "./header";
import Sidebar from "./sidebar";
import { AnimatePresence, motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../redux";
import { getUserProfile } from "../redux/auth/actions";
import { getAuth } from "../redux/auth/authSlice";
import mediaQueries from "../../utils/mediaQueries";

const Layout = () => {
  const userData = storage.get("optimaUser");
  const isLoggedIn = !!userData;
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const {
    profile: { response, error, pending },
  } = useAppSelector(getAuth);

  useEffect(() => {
    !response && !error && !pending && dispatch(getUserProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence exitBeforeEnter>
      <Container>
        {isLoggedIn && <Sidebar />}
        {isLoggedIn ? (
          <LeftContainer key={pathname}>
            <Header />
            <motion.div
              initial={{
                opacity: 0,
                x: 100,
              }}
              exit={{
                opacity: 0,
                y: 200,
              }}
              animate={{
                opacity: 1,
                x: 0,
                y: 0,
              }}
              transition={{ duration: 0.55 }}
            >
              <Outlet />
            </motion.div>
          </LeftContainer>
        ) : (
          <Outlet />
        )}
      </Container>
    </AnimatePresence>
  );
};

export default Layout;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const LeftContainer = styled.div`
  width: 84%;
  margin-left: 16%;
  overflow: hidden;

  ${mediaQueries.tablet} {
    margin-left: 0;
    width: 100%;
    padding: 15px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    padding: 15px;
    margin-left: 0;
  }
`;

import { ApiResponse } from "../../redux/types";
import request from "../request";
import { AdResponse } from "./types";

const getAd = async () => {
  return await request.get<ApiResponse<AdResponse>>("/Ad");
};

const uploadAd = async (payload: File) => {
  const formData = new FormData();
  if (payload) {
    formData.append("image", payload);
  }
  return await request.post<ApiResponse<boolean>>(`/Ad`, formData);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAd,
  uploadAd,
};

import React from "react";
import styled from "styled-components";
import greyIcon from "../../../images/grey-tick.png";
import greenIcon from "../../../images/green-tick.png";

interface props {
  isActive?: boolean;
  label: string;
}

const PasswordStrength: React.FC<props> = ({ label, isActive }) => {
  const iconToDisplay = isActive ? greenIcon : greyIcon;

  return (
    <Container>
      <img src={iconToDisplay} alt="" />
      <p>{label}</p>
    </Container>
  );
};

export default PasswordStrength;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > img {
    width: 15px;
    height: 15px;
  }

  & > p {
    color: #080808;
    font-size: 12px;
  }
`;

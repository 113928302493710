import { ApiResponse } from "../../redux/types";
import request from "../request";
import { TermsResponse, UpdateTermsPayload } from "./types";

const getTerms = async () => {
  return await request.get<ApiResponse<TermsResponse>>("/terms/get");
};

const updateTerms = async (payload: UpdateTermsPayload) => {
  return await request.put<ApiResponse<string>>("/terms/update", payload);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTerms,
  updateTerms,
};

import { ApiResponse } from "../../redux/types";
import request from "../request";
import {
  AddCountryToCardPayload,
  CardResponseEdit,
  CardsResponse,
  ConfigureNormalPayload,
  ConfigureReceiptTypePayload,
  ConfigureVisaPayload,
  CreateCardPayload,
  CreateCardResponse,
  EditNormalTypePayload,
  EditReceiptTypePayload,
  EditVisaTypePayload,
  PrefixResponseConfigure,
  ReceiptResponse,
  ToggleCardStatusPayload,
  UploadCardImagePayload,
} from "./types";

const getCard = async (id: string) => {
  return await request.get<ApiResponse<CardsResponse>>(`/Card/Old/${id}`);
};

const getCardForEdit = async (id: string) => {
  return await request.get<ApiResponse<CardResponseEdit>>(`/Card/${id}`);
};

const getActiveCards = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<CardsResponse>>(
    keyword
      ? `/Card/Active?Filter=CardName&Keyword=${keyword}`
      : `/Card/Active?pageSize=10&pageIndex=${pageIndex}`
  );
};
const getInactiveCards = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<CardsResponse>>(
    keyword
      ? `/Card/Pending?Filter=CardName&Keyword=${keyword}`
      : `/Card/Pending?pageSize=10&pageIndex=${pageIndex}`
  );
};
const getDisabledCards = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<CardsResponse>>(
    keyword
      ? `/Card/Blocked?Filter=CardName&Keyword=${keyword}`
      : `/Card/Blocked?pageSize=10&pageIndex=${pageIndex}`
  );
};

const configureVisa = async (payload: ConfigureVisaPayload, id: string) => {
  return await request.post<ApiResponse<boolean>>(
    `/Card/configure-visa/${id}`,
    payload
  );
};
const configureNormal = async (payload: ConfigureNormalPayload, id: string) => {
  return await request.post<ApiResponse<boolean>>(
    `/Card/configure-normal/${id}`,
    payload
  );
};
const configureReceiptType = async (
  payload: ConfigureReceiptTypePayload,
  id: string
) => {
  return await request.post<ApiResponse<boolean>>(
    `/Card/configure-receipttype/${id}`,
    payload
  );
};

const getReceiptTypes = async () => {
  return await request.get<ApiResponse<ReceiptResponse[]>>("/Receipt");
};

const getPrefixTypes = async () => {
  return await request.get<ApiResponse<PrefixResponseConfigure[]>>("/Prefix");
};

const createCard = async (payload: CreateCardPayload) => {
  const formData = new FormData();
  const { countryIds, baseCardType, logo, name } = payload;

  if (logo && baseCardType && countryIds) {
    formData.append("logo", logo);
    formData.append("baseCardType", baseCardType);
    countryIds.forEach((x) => formData.append("countryIds", x));
    formData.append("name", name);
  }
  return await request.post<ApiResponse<CreateCardResponse>>("/Card", formData);
};

const toggleCardStatus = async (
  payload: ToggleCardStatusPayload,
  id: string
) => {
  return await request.put<ApiResponse<boolean>>(
    `/Card/Activate-Deactivate/${id}`,
    payload
  );
};

const editReceiptType = async (payload: EditReceiptTypePayload, id: string) => {
  return await request.put<ApiResponse<boolean>>(
    `/Card/update-receipttype/${id}`,
    payload
  );
};

const editVisaType = async (payload: EditVisaTypePayload, id: string) => {
  return await request.put<ApiResponse<boolean>>(
    `/Card/update-visa/${id}`,
    payload
  );
};

const editNormalType = async (payload: EditNormalTypePayload, id: string) => {
  return await request.put<ApiResponse<boolean>>(
    `/Card/update-normal/${id}`,
    payload
  );
};

const addCountryToCard = async (
  payload: AddCountryToCardPayload,
  id: string
) => {
  return await request.put<ApiResponse<boolean>>(
    `/Card/Add-Countries/${id}`,
    payload
  );
};

const uploadCardLogo = async (payload: UploadCardImagePayload, id: string) => {
  const formData = new FormData();
  if (payload.logo) {
    formData.append("logo", payload.logo);
  }
  return await request.put<ApiResponse<boolean>>(`/Card/Logo/${id}`, formData);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCard,
  getCardForEdit,
  configureVisa,
  configureReceiptType,
  configureNormal,
  getReceiptTypes,
  getPrefixTypes,
  getActiveCards,
  getDisabledCards,
  getInactiveCards,
  createCard,
  toggleCardStatus,
  editReceiptType,
  editVisaType,
  editNormalType,
  addCountryToCard,
  uploadCardLogo,
};

import styled from "styled-components";
import { ReactComponent as LeftIcon } from "../../../images/left-arrow.svg";
import TransactionGrid from "./components/transaction/grid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { userProfileResponse } from "../../api/users/types";
import { formatPrice, handleGetInitials } from "../../../utils/formatters";
import { toast } from "react-toastify";
import DualRing from "../../components/loader";
import { MainLoaderWrapper } from "../Preferences/components/denominations";
import { TopContentLeft } from "../Admins/manage";
import mediaQueries from "../../../utils/mediaQueries";

const ManageUsers = () => {
  const [profileData, setProfileData] = useState<userProfileResponse>();
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const params = useParams();

  const handleNavigate = () => {
    navigate("/dashboard/users", { replace: true });
  };

  const handleGetUserData = async () => {
    setIsLoading(true);
    if (params?.id) {
      try {
        const res = await api.userService.getUserProfile(params?.id);
        setProfileData(res?.data?.data);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <Container>
      <h1>Manage Users</h1>
      <ContentWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        {isLoading ? (
          <MainLoaderWrapper>
            <DualRing width="30px" height="30px" color="#727272" />
          </MainLoaderWrapper>
        ) : (
          <BottomWrapper>
            <PersonalInfoSection>
              <p>Personal Information</p>
              <InfoBodyWrapper>
                {profileData?.userDTO.profilePicture ? (
                  <img src={profileData?.userDTO.profilePicture} alt="" />
                ) : (
                  <TopContentLeft>
                    {profileData?.userDTO.fullName &&
                      handleGetInitials(profileData?.userDTO.fullName)}
                  </TopContentLeft>
                )}

                <PersonalInfoWrapper>
                  <label>
                    Full Name
                    <input
                      type="text"
                      disabled
                      value={profileData?.userDTO.fullName}
                    />
                  </label>
                  <label>
                    Email Address
                    <input
                      type="text"
                      disabled
                      value={profileData?.userDTO.emailAddress}
                    />
                  </label>
                </PersonalInfoWrapper>
                <PersonalInfoWrapper width="290px">
                  <PersonalInfoEntry>
                    <label>
                      Date Registered
                      <input
                        type="text"
                        disabled
                        value={new Date(
                          profileData?.userDTO.createdOn as string
                        ).toDateString()}
                      />
                    </label>
                    <div>
                      <p>Status</p>
                      <div>Active</div>
                    </div>
                  </PersonalInfoEntry>
                  <label>
                    Phone Number
                    <input
                      type="text"
                      disabled
                      value={profileData?.userDTO.phoneNumber}
                    />
                  </label>
                </PersonalInfoWrapper>
                <BalanceWrapper>
                  <p>Wallet Balance</p>
                  <h1>{formatPrice(profileData?.walletBalance || 0)}</h1>
                  <h4>NGN</h4>
                </BalanceWrapper>
              </InfoBodyWrapper>
            </PersonalInfoSection>
            <BankInfoSection>
              <p>Bank Information</p>
              <BankContentWrapper>
                {profileData?.bankAccountDTOs &&
                profileData?.bankAccountDTOs.length > 0 ? (
                  profileData?.bankAccountDTOs.map((x, i) => (
                    <BankContentEntry
                      key={i}
                      hasBorder={
                        !(i === profileData?.bankAccountDTOs.length - 1)
                      }
                    >
                      <EntryWrapper>
                        <p>Bank Name</p>
                        <span>{x.bankName}</span>
                      </EntryWrapper>
                      <EntryWrapper>
                        <p>Account Name</p>
                        <span>{x.accountName}</span>
                      </EntryWrapper>
                      <EntryWrapper>
                        <p>Account Number</p>
                        <span>{x.accountNumber}</span>
                      </EntryWrapper>
                    </BankContentEntry>
                  ))
                ) : (
                  <EmptyWrapper>
                    <p>No bank details found</p>
                  </EmptyWrapper>
                )}
              </BankContentWrapper>
            </BankInfoSection>
            <TransactionSection>
              <p>Transaction History</p>
              <TransactionGridWrapper noContent={!profileData?.creditDebitDTO}>
                <TransactionGrid data={profileData?.creditDebitDTO || []} />
              </TransactionGridWrapper>
            </TransactionSection>
          </BottomWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default ManageUsers;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 35px;
  width: 100%;
  margin-top: 90px;

  & > h1 {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }

  ${mediaQueries.mobileAndTablet} {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 35px;

  ${mediaQueries.mobile} {
    background: transparent;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 25px 30px 15px;
  border-bottom: 1px solid #e6e6e6;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 11px;
    }

    & > p {
      letter-spacing: -0.13px;
      color: #000000;
      font-size: 13px;
      font-weight: medium;
    }
  }

  ${mediaQueries.tablet} {
    padding: 10px;
  }

  ${mediaQueries.mobile} {
    padding: 10px 0;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  max-height: 530px;
  overflow-x: scroll;

  ${mediaQueries.tablet} {
    max-height: unset;
  }

  ${mediaQueries.mobile} {
    padding: 0;
    max-height: unset;
  }
`;

const PersonalInfoSection = styled.div`
  padding: 0 30px 0 50px;

  & > p {
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 13px;
    font-weight: 600;
  }

  ${mediaQueries.tablet} {
    padding: 0 10px;
  }

  ${mediaQueries.mobile} {
    padding: 0;
  }
`;

const InfoBodyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 30px 0 60px;

  & > img {
    width: 126px;
    height: 125px;
    border-radius: 63px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  ${mediaQueries.tablet} {
    gap: 30px 20px;
    flex-wrap: wrap;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 0 10px;
  min-width: 220px;
  height: 129px;
  background: #faa84f0d 0% 0% no-repeat padding-box;
  border: 0.5px solid #f8a758;
  border-radius: 10px;

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
  }

  & > h1 {
    letter-spacing: -1.13px;
    color: #faa84f;
    font-size: 28px;
    font-weight: 600;
    word-break: break-all;
    text-align: center;
  }

  & > h4 {
    letter-spacing: -0.38px;
    color: #faa84f;
    font-size: 19px;
    font-weight: 600;
  }

  ${mediaQueries.smallDesktop} {
    & > h1 {
      font-size: 24px;
    }
  }

  ${mediaQueries.mobile} {
    width: 100%;

    & > p {
      font-size: 14px;
    }

    & > h1 {
      font-size: 30px;
    }
  }
`;

interface personaInfoProps {
  width?: string;
}

const PersonalInfoWrapper = styled.div<personaInfoProps>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: ${(props) => (props.width ? props.width : "32%")};

  & > label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #000000;
    font-size: 11px;

    input {
      height: 37px;
      background: #eaeaea;
      border-radius: 5px;
      padding: 0 15px;
      width: 100%;
      outline: none;
      border: none;

      &::placeholder {
        letter-spacing: -0.12px;
        color: #727272;
        font-size: 12px;
      }
    }
  }

  ${mediaQueries.smallDesktop} {
    width: 30%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    gap: 20px;
  }
`;

const PersonalInfoEntry = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  & > label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #000000;
    font-size: 11px;

    input {
      height: 37px;
      background: #eaeaea;
      border-radius: 5px;
      padding: 0 15px;
      width: 100%;
      outline: none;
      border: none;

      &::placeholder {
        letter-spacing: -0.12px;
        color: #727272;
        font-size: 12px;
      }
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #000000;
    font-size: 11px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 27px;
      border: 2px solid #1aba27;
      border-radius: 18px;
      padding: 10px 12px;
      color: #1aba27;
      font-size: 9px;
      font-weight: bold;
    }
  }
`;

const BankInfoSection = styled.div`
  height: 135px;
  background: #faa84f0d;
  margin-bottom: 40px;
  padding: 25px 50px;

  & > p {
    color: #000000;
    letter-spacing: -0.13px;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 22px;
  }

  ${mediaQueries.tablet} {
    height: auto;
    padding: 20px 10px;
    width: 100%;
  }

  ${mediaQueries.mobile} {
    height: auto;
    padding: 20px 10px;
    width: 100%;
    box-shadow: 0 0 0 100vmax #faa84f0d;
    clip-path: inset(0 -100vmax);
  }
`;

const BankContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  overflow-x: scroll;

  ${mediaQueries.mobile} {
    gap: 25px;
  }
`;

interface bankEntryProps {
  hasBorder?: boolean;
}

const BankContentEntry = styled.div<bankEntryProps>`
  display: flex;
  align-items: center;
  gap: 30px;
  flex-shrink: 0;
  padding-right: 30px;
  border-right: ${(props) =>
    props.hasBorder ? "0.5px solid #707070" : "none"};
`;

const EntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  & > p {
    letter-spacing: -0.11px;
    color: #727272;
    font-size: 11px;
  }

  & > span {
    color: #000000;
    font-size: 12px;
  }
`;

const TransactionSection = styled.div`
  padding: 0 50px 0;

  & > p {
    letter-spacing: -0.13px;
    color: #000000;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 5px;
  }

  ${mediaQueries.tablet} {
    padding: 0 10px;
  }

  ${mediaQueries.mobile} {
    padding: 0;
  }
`;

interface gridWrapperProps {
  noContent?: boolean;
}

const TransactionGridWrapper = styled.div<gridWrapperProps>`
  width: 100%;
  height: ${(props) => (props.noContent ? "80px" : "400px")};
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 16px;
    font-weight: bold;
  }

  ${mediaQueries.mobile} {
    & > p {
      font-size: 15px;
    }
  }
`;

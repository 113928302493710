import { ApiResponse } from "../../redux/types";
import request from "../request";
import { AddDenominationPayload, DenominationResponse } from "./types";

const getDenominations = async () => {
  return await request.get<ApiResponse<DenominationResponse[]>>(
    "/Denomination"
  );
};

const addDenomination = async (payload: AddDenominationPayload) => {
  return await request.post<ApiResponse<boolean>>("/Denomination", payload);
};

const getSingleDenomination = async (id: string) => {
  return await request.get<ApiResponse<DenominationResponse>>(
    `/Denomination/${id}`
  );
};

const deleteDenomination = async (id: string) => {
  return await request.delete<ApiResponse<boolean>>(`/Denomination/${id}`);
};

const editDenomination = async (id: string) => {
  return await request.put<ApiResponse<boolean>>(`/Denomination/${id}`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDenominations,
  addDenomination,
  getSingleDenomination,
  deleteDenomination,
  editDenomination,
};

import styled from "styled-components";
import { useEffect, useRef } from "react";
import optimaLogo from "../../images/optimaLogo.png";
import { ReactComponent as LogoutIcon } from "../../images/Logout.svg";
import { sidebarData } from "../../utils/data";
import storage from "../../utils/storage";
import CustomLink from "./customLink";
import mediaQueries from "../../utils/mediaQueries";
import { useAppDispatch } from "../redux";
import { logout } from "../redux/auth/actions";
import { useNavigate } from "react-router";

interface props {
  isOpen: boolean;
  setIsNavbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileNavOverlay: React.FC<props> = ({ isOpen, setIsNavbarOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const node = useRef<HTMLDivElement>(null);
  const cachedTokens = storage.get("optimaUser");

  const handleOutsideClick = (e: any) => {
    if (
      node.current &&
      !node.current.contains(e.target) &&
      e.target.parentNode.id !== "mobile_hamburger"
    ) {
      setIsNavbarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNav = () => {
    setIsNavbarOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login", { replace: true });
  };

  return (
    <Container isOpen={isOpen} ref={node}>
      <Logo src={optimaLogo} />
      <NavWrapper>
        {sidebarData
          .filter((x) =>
            cachedTokens?.permissions?.includes(x.value.toUpperCase())
          )
          .map((item, index) => (
            <CustomLink key={index} {...item} handleCloseNav={handleCloseNav} />
          ))}
      </NavWrapper>
      <CustomLink action={handleLogout} icon={LogoutIcon} title="Logout" />
    </Container>
  );
};

export default MobileNavOverlay;

interface navProps {
  isOpen?: boolean;
}

const Container = styled.div<navProps>`
  display: flex;
  flex-direction: column;
  gap: 70px;
  position: fixed;
  top: 0;
  left: 0;
  transform: ${({ isOpen }) =>
    isOpen ? `translateX(0%)` : `translateX(100%)`};
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  padding-top: 18px;
  z-index: 9;
  transition: all 0.3s linear;

  ${mediaQueries.tablet} {
    transform: ${({ isOpen }) =>
      isOpen ? `translateX(0%)` : `translateX(100%)`};
    width: 35vw;
    left: unset;
    right: 0;
  }
`;

const Logo = styled.img`
  width: 116px;
  height: 25px;
  margin-left: 15px;

  ${mediaQueries.tablet} {
    margin: 7px 0 0 15px;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 20px;
`;

export const StyledNav = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;

  & > span {
    font-size: 18px;
    color: #afd34f;
  }
`;

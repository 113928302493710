import styled from "styled-components";

const Messages = () => {
  return <Container>Messages</Container>;
};

export default Messages;

const Container = styled.div`
  margin-top: 200px;
`;

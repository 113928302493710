import React, { useRef } from "react";
import { AgGridEvent, GridApi, GridReadyEvent } from "ag-grid-community";
import { gridCellStyle, GridWrapper } from "./gridComponents/styles";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import EmptyStateRenderer from "./gridComponents/emptyStateRenderer";
import { GridConfigTypes, GridDef } from "..";
import { useMobileCheck } from "../../../../hooks/useMobileCheck";
import {
  columnDefsDesktop,
  columnDefsMobile,
  columnDefsTablet,
} from "./gridComponents/columnDef";
import { cryptoResponse } from "../../../api/crypto/types";

interface GridProps {
  data: any;
  tabValue: number;
}

const Grid: React.FC<GridProps> = ({ data, tabValue }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: cryptoResponse[] = data;

  const { isMobile, isTablet } = useMobileCheck();

  const columnDefs: GridDef[] = isTablet
    ? columnDefsTablet()
    : isMobile
    ? columnDefsMobile()
    : columnDefsDesktop();

  const gridConfig: GridConfigTypes = {
    columnDefs: columnDefs,
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <>
      {GridData?.length > 0 ? (
        <GridWrapper id="OpenOrdersGridWrapper" className={`ag-theme-balham`}>
          <AgGridReact
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={43}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            defaultColDef={{
              sortable: true,
              filter: false,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            getRowNodeId={(data: cryptoResponse) => {
              return data.id.toString();
            }}
            noRowsOverlayComponentFramework={EmptyStateRenderer}
            gridOptions={{
              onSortChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onFilterChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>
            {tabValue === 1
              ? "You have no active cryptos"
              : "You have no blocked cryptos"}
          </p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default Grid;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 130px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }
`;

import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DocumentWrapper, UploadImageWrapper } from "../add";
import { EditImageBox } from "../edit";
import { ReactComponent as UploadIcon } from "../../../../images/upload-card.svg";
import api from "../../../api";
import { useParams } from "react-router";
import { LoaderWrapper } from "../../Preferences/components/preferences";
import DualRing from "../../../components/loader";
import mediaQueries from "../../../../utils/mediaQueries";

interface props {
  setDocumentToDisplay: React.Dispatch<React.SetStateAction<string>>;
  documentToDisplay: string;
}

const Passport: React.FC<props> = ({
  documentToDisplay,
  setDocumentToDisplay,
}) => {
  const [documentToUpload, setDocumentToUpload] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  const selectdocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let file = e!.target!.files![0];
      // check if file is greater than 1mb
      if (file.size > 1e6) {
        toast.error("Please upload file less than 1mb");
        return;
      }
      if (file.type.includes("image")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          if (event.target && event.target.result) {
            setDocumentToDisplay(event.target.result.toString());
            setDocumentToUpload(file);
          }
        });
      }
    }
  };

  //used any type to target the id of the element
  const handleFilePicking = (e: any) => {
    if (e.target.id !== "delete") {
      document!.getElementById("document")!.click();
    }
  };

  const handleFileDelete = () => {
    setDocumentToDisplay("");
    setDocumentToUpload(undefined);
  };

  const handleSubmit = async () => {
    if (params.id && documentToUpload) {
      setIsLoading(true);
      try {
        const res = await api.cardService.uploadCardLogo(
          {
            logo: documentToUpload,
          },
          params.id
        );
        res.data.data && toast.success("image uploaded successfully");
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <UploadWrapper>
        <p>Upload Card Image</p>
        <input
          type="file"
          id="document"
          accept="image/png,image/jpg,image/jpeg"
          onChange={selectdocument}
        />
        <EditImageBox>
          {!documentToDisplay ? (
            <UploadImageWrapper
              onClick={() => document!.getElementById("document")!.click()}
            >
              <UploadIcon />
              <p>Browse files</p>
              <span>Supported formats: PNG, JPG, JPEG</span>
            </UploadImageWrapper>
          ) : (
            <DocumentWrapper onClick={(e) => handleFilePicking(e)}>
              <img src={documentToDisplay} alt="" />
              <span id="delete" onClick={() => handleFileDelete()}>
                x
              </span>
            </DocumentWrapper>
          )}
        </EditImageBox>
      </UploadWrapper>
      <UploadButton onClick={() => handleSubmit()}>
        {isLoading ? (
          <LoaderWrapper>
            <DualRing width="10px" height="10px" />
          </LoaderWrapper>
        ) : (
          "Save Image"
        )}
      </UploadButton>
    </Container>
  );
};

export default Passport;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;

  & > img {
    width: 175px;
    height: 92px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  ${mediaQueries.tablet} {
    flex-direction: column;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    width: 100%;
  }
`;

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > p {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #000000;
  }

  & > input {
    display: none;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 40px;
  margin-top: 23px;
  letter-spacing: -0.11px;
  color: #ffffff;
  font-size: 11px;
  font-weight: 800;
  background: transparent linear-gradient(180deg, #2b5a9d 0%, #041630 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  cursor: pointer;

  ${mediaQueries.mobileAndTablet} {
    margin-top: 0;
  }
`;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getNotifications } from "./actions";
import { NotificationState } from "./types";

const initialState: NotificationState = {
  getNotifications: {
    error: null,
    pending: false,
  },
  notifications: [],
};

const notificationSlice = createSlice({
  initialState: initialState,
  name: "notifications",
  reducers: {
    markAsRead: (state, action: PayloadAction<string>) => {
      const index = state.notifications.findIndex(
        (x) => x.notificationId === action.payload
      );
      state.notifications[index].isRead = true;
      return state;
    },
    resetNotifications: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getNotifications.pending, (state) => {
        return {
          ...state,
          getNotifications: {
            ...state.getNotifications,
            error: null,
            pending: true,
            response: undefined,
          },
        };
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        return {
          ...state,
          getNotifications: {
            ...state.getNotifications,
            pending: false,
            error: null,
          },
          notifications: payload.data,
        };
      })
      .addCase(getNotifications.rejected, (state, action) => {
        return {
          ...state,
          getNotifications: {
            ...state.getNotifications,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
  },
});

export const { markAsRead, resetNotifications } = notificationSlice.actions;
export const notificationSelector = (state: RootState) =>
  state.notifications.notifications;

export const getNotificationSelector = (state: RootState) =>
  state.notifications.getNotifications;

export default notificationSlice.reducer;

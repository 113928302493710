export const componentSlide = {
  initial: {
    opacity: 0,
    x: 100,
  },
  exit: {
    opacity: 0,
    y: 200,
  },
  animate: {
    opacity: 1,
    x: 0,
    y: 0,
  },
};

export const pageFadeVariant = {
  animate: {
    opacity: 1,
    transition: { type: "spring", bounce: 0.25, duration: 0.5 },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
      ease: "easeOut",
      type: "tween",
    },
  },
  initial: {
    opacity: 0,
  },
};

import Select, { StylesConfig } from "react-select";
// import { CaretDown } from "@phosphor-icons/react";
import { FC } from "react";
import { useTheme } from "styled-components";
import { dropDownType } from "../../types/common";

interface Props {
  options: dropDownType[];
  onChange: (value: string | null) => void;
  isPlacementTop?: boolean;
  width?: string;
  value?: number;
}

export const CustomDropdown: FC<Props> = ({
  onChange,
  options: option,
  isPlacementTop,
  width,
  value,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme = useTheme() as any; // useTheme has no defined typing

  const selectStyles: StylesConfig = {
    control: (styles, state) => ({
      ...styles,
      width: width,
      fontFamily: "Arial, sans-serif",
      fontSize: "0.875rem",
      boxSizing: "border-box",
      padding: "0 5px",
      margin: "10px 0",
      minWidth: "65px",
      background: "#E8E8E8",
      border: "1px solid #CDD2D7",
      borderRadius: "6px",
      borderColor: state.isFocused ? "red" : "none",
      boxShadow: "none",
      color: "#1A2027",
      overflow: "auto",
      outline: "none",
      zIndex: "999",
      position: "relative",
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        borderColor: "hsl(0, 0%, 70%)",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0em",
      color: theme?.selectOption,
      cursor: "pointer",
      whiteSpace: "nowrap",
      backgroundColor: "transparent",
      "&:hover": {
        transition: "all ease-out 0.3s",
        backgroundColor: "#E8E8E8",
      },
    }),
    menu: (styles) => ({
      ...styles,
      background: theme?.wrappers,
      zIndex: "400",
    }),
    input: (styles) => ({
      ...styles,
      caretColor: "transparent",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme?.text,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "unset",
      boxSizing: "border-box",
      padding: "6px 6px 6px 0",
      "&:focus": {
        outline: "none",
      },
    }),
  };

  return (
    <Select
      key={option[0].label}
      options={option}
      styles={selectStyles}
      components={{
        IndicatorSeparator: () => null,
        // DropdownIndicator: () => <CaretDown />,
      }}
      defaultValue={option[0]}
      value={option.filter((ev) => ev.value === value?.toString())[0]}
      menuPlacement={isPlacementTop ? "top" : "bottom"}
      maxMenuHeight={200}
      onChange={(e: unknown) => {
        const data = e as dropDownType;
        onChange(data.value);
      }}
      getOptionValue={(option: unknown) => (option as dropDownType).value}
    />
  );
};

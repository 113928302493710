import styled from "styled-components";
import { ReactComponent as LeftIcon } from "../../../images/left-arrow.svg";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import {
  handleGetInitials,
  splitArrayIntoChunks,
} from "../../../utils/formatters";
import { dropDownType } from "../../../types/common";
import { toast } from "react-toastify";
import DualRing from "../../components/loader";
import CancelModal from "../../components/cancelModal";
import { CancelButton, SaveButton } from "../Profile/components/security";
import { AdminPermissions } from "../../../utils/data";
import PermissionCheck from "./components/permission";
import {
  ProfileResponse,
  UpdateAdminPermissionsPayload,
} from "../../api/auth/types";
import { LoaderWrapper } from "../Preferences/components/preferences";
import { refreshNeeded } from "../../redux/cards/cardSlice";
import { useAppDispatch } from "../../redux";
import mediaQueries from "../../../utils/mediaQueries";

const ManageAdmin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [adminDetails, setadminDetails] = useState<ProfileResponse>();
  const [formData, setFormData] = useState<UpdateAdminPermissionsPayload>({
    permissions: [],
    emailAddress: "",
  });
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const params = useParams();

  const handleNavigate = () => {
    navigate("/dashboard/admins", { replace: true });
  };

  const handleGetAdminData = async () => {
    setIsLoading(true);
    if (params?.id) {
      try {
        const res = await api.authService.getSingleAdmin(params?.id);
        const { data } = res.data;
        setadminDetails(data);
        setFormData({
          emailAddress: data.emailAddress,
          permissions: data.permissions,
        });
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetAdminData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const handleChangePermissions = (entry: dropDownType) => {
    const permIndex = formData.permissions.findIndex(
      (x) => x === entry.value.toUpperCase()
    );
    if (permIndex > -1) {
      formData.permissions.splice(permIndex, 1);
      setFormData({ ...formData });
    } else {
      setFormData({
        ...formData,
        permissions: [...formData.permissions, entry.value.toUpperCase()],
      });
    }
  };

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (formData) {
      try {
        const res = await api.authService.updateAdminPermissions(formData);
        res?.data.data && toast.success("Permissions updated successfully");
        dispatch(refreshNeeded(true));
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Container>
      <h1>Manage Admin</h1>
      <ContentWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        {isLoading ? (
          <MainLoaderWrapper>
            <DualRing width="30px" height="30px" color="#727272" />
          </MainLoaderWrapper>
        ) : (
          <BottomWrapper>
            <p>Admin Information</p>
            <TopContent>
              <TopContentLeft>
                {adminDetails?.name && handleGetInitials(adminDetails.name)}
              </TopContentLeft>
              <TopContentRight>
                <Row>
                  <DetailsEntry>
                    <p>Full Name</p>
                    <span>{adminDetails?.name}</span>
                  </DetailsEntry>
                  <DetailsWrapper>
                    <DetailsEntry>
                      <p>Date Created</p>
                      <span>
                        {adminDetails?.dateCreated &&
                          new Intl.DateTimeFormat("en-US", options).format(
                            new Date(adminDetails?.dateCreated)
                          )}
                      </span>
                    </DetailsEntry>
                    <DetailsEntry width="20%">
                      <p>Status</p>
                      <StatusWrapper>
                        <Status isActive={adminDetails?.active}>
                          <p>{adminDetails?.active ? "Active" : "Inactive"}</p>
                        </Status>
                        {!adminDetails?.verified && (
                          <span>**unconfirmed email</span>
                        )}
                      </StatusWrapper>
                    </DetailsEntry>
                  </DetailsWrapper>
                </Row>
                <Row>
                  <DetailsEntry>
                    <p>Email Address</p>
                    <span>{adminDetails?.emailAddress}</span>
                  </DetailsEntry>
                  <DetailsEntry>
                    <p>Phone Number</p>
                    <span>{adminDetails?.phoneNumber}</span>
                  </DetailsEntry>
                </Row>
              </TopContentRight>
            </TopContent>
            <BottomContent>
              <h2>Admin Permissions</h2>
              <PermissionsWrapper>
                {splitArrayIntoChunks(AdminPermissions, 4).map((row, index) => (
                  <InnerPermissionsWrapper key={index}>
                    {row.map((x, i) => (
                      <PermissionCheck
                        entry={x}
                        key={i}
                        isChecked={formData?.permissions.includes(
                          x?.value.toUpperCase()
                        )}
                        handleClick={handleChangePermissions}
                      />
                    ))}
                  </InnerPermissionsWrapper>
                ))}
              </PermissionsWrapper>
              <ButtonWrapper>
                <CancelButton onClick={() => setIsOpen(true)}>
                  Cancel
                </CancelButton>
                <SaveButton onClick={() => handleSubmit()} disabled={false}>
                  {isSubmitting ? (
                    <LoaderWrapper>
                      <DualRing width="10px" height="10px" />
                    </LoaderWrapper>
                  ) : (
                    "Save Changes"
                  )}
                </SaveButton>
              </ButtonWrapper>
            </BottomContent>
          </BottomWrapper>
        )}
      </ContentWrapper>
      <CancelModal
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
        handleSubmit={() => navigate("/dashboard/admins", { replace: true })}
      />
    </Container>
  );
};

export default ManageAdmin;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 35px;
  width: 100%;
  margin-top: 90px;

  & > h1 {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }

  ${mediaQueries.mobileAndTablet} {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;

  ${mediaQueries.mobile} {
    background: transparent;
  }
`;

const MainLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 25px 30px 15px;
  border-bottom: 1px solid #e6e6e6;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 11px;
    }

    & > p {
      letter-spacing: -0.13px;
      color: #000000;
      font-size: 13px;
      font-weight: medium;
    }
  }

  ${mediaQueries.tablet} {
    padding: 10px;
  }

  ${mediaQueries.mobile} {
    padding: 10px 0;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 0;
  max-height: 550px;
  overflow-x: scroll;

  & > p {
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-left: 50px;
  }

  ${mediaQueries.tablet} {
    padding: 0;
    max-height: unset;

    & > p {
      padding-left: 10px;
      margin-top: 20px;
    }
  }

  ${mediaQueries.mobile} {
    padding: 0;
    max-height: unset;

    & > p {
      padding-left: 0px;
      margin-top: 20px;
    }
  }
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding: 0 50px;

  ${mediaQueries.tablet} {
    padding: 0 10px;
    max-width: 750px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
`;

export const TopContentLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 126px;
  height: 125px;
  background: #fff6ed;
  letter-spacing: -0.6px;
  color: #faa84f;
  font-size: 60px;
  border: 2px solid #f8a758;
  border-radius: 50%;

  ${mediaQueries.tablet} {
    width: 110px;
    height: 110px;
  }

  ${mediaQueries.mobile} {
    width: 100px;
    height: 100px;
    font-size: 45px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 20px;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 45%;

  ${mediaQueries.tablet} {
    & > div:first-of-type {
      flex-grow: 1;
    }
  }

  ${mediaQueries.mobile} {
    width: 100%;
    gap: 30px;
  }
`;

interface entryProps {
  width?: string;
}

const DetailsEntry = styled.div<entryProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${(props) => (props.width ? props.width : "45%")};

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
  }

  & > span {
    display: flex;
    align-items: center;
    width: 100%;
    height: 37px;
    background: #eaeaea;
    letter-spacing: -0.12px;
    color: #727272;
    font-size: 13px;
    border-radius: 5px;
    padding-left: 15px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  gap: 10px;

  & > span {
    letter-spacing: -0.1px;
    color: #727272;
    font-size: 10px;
    font-weight: 600;
    font-style: italic;
  }
`;

interface statusProps {
  isActive?: boolean;
}

const Status = styled.div<statusProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 13px;
  width: fit-content;
  height: 27px;
  border-radius: 18px;
  border: ${(props) =>
    props.isActive ? "2px solid #1ABA27" : "2px solid #F8A758"};

  & > p {
    margin: 0;
    color: ${(props) => (props.isActive ? "#1ABA27" : "#F8A758")};
    font-size: 9px;
    font-weight: bold;
  }
`;

const TopContentRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  width: 80%;

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  & > h1 {
    letter-spacing: -1.38px;
    color: #faa84f;
    font-size: 55px;
    font-weight: 600;
  }

  ${mediaQueries.tablet} {
    max-width: 600px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    gap: 20px;
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 50px;
  margin-top: 50px;
  padding: 25px 50px;
  background: #fffbf6;

  & > h2 {
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }

  ${mediaQueries.tablet} {
    padding: 25px 10px;
    gap: 40px;
  }

  ${mediaQueries.mobile} {
    gap: 20px;
    padding: 20px 10px 10px;
  }
`;

const PermissionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 80%;

  ${mediaQueries.tablet} {
    width: 100%;
    gap: 40px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    margin-top: 15px;
  }
`;

const InnerPermissionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${mediaQueries.mobile} {
    gap: 30px 18px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 40px;
  margin-top: 50px;
  padding: 0;
`;

import styled from "styled-components";
import { ReactComponent as LeftIcon } from "../../../../images/left-arrow.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api";
import { WithdrawalDetailsResponse } from "../../../api/transactions/types";
import { formatPrice } from "../../../../utils/formatters";
import {
  TransactionStatusEnum,
  WithdrawActionEnum,
} from "../../../../types/common";
import { toast } from "react-toastify";
import DualRing from "../../../components/loader";
import ActionModal from "./components/actionModal";
import { StatusButton } from "../cardSales/details";
import mediaQueries from "../../../../utils/mediaQueries";

const WithdrawalDetail = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState<WithdrawActionEnum>();
  const [isLoading, setIsLoading] = useState(false);
  const [transactionDetails, setTransactionDetails] =
    useState<WithdrawalDetailsResponse>();
  let navigate = useNavigate();
  const params = useParams();

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleNavigate = () => {
    navigate("/dashboard/transactions/withdrawals", { replace: true });
  };

  const handleGetWithdrawalData = async () => {
    setIsLoading(true);
    if (params?.id) {
      try {
        const res = await api.transactionService.getWithdrawalDetails(
          params?.id
        );
        setTransactionDetails(res?.data?.data);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetWithdrawalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = (action: WithdrawActionEnum) => {
    setAction(action);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleNavigate();
  };

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <Container>
      <h1>Manage Withdrawal</h1>
      <ContentWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <DualRing width="30px" height="30px" color="#727272" />
          </LoaderWrapper>
        ) : (
          <BottomWrapper>
            <p>Transaction Information</p>
            <TopContent>
              <TopContentLeft>
                <Row>
                  <TransactionEntry>
                    <p>Transaction Date</p>
                    <span>
                      {transactionDetails?.createdOn &&
                        new Intl.DateTimeFormat("en-US", options).format(
                          new Date(transactionDetails?.createdOn)
                        )}
                    </span>
                  </TransactionEntry>
                  <TransactionEntry>
                    <p>Transaction ID</p>
                    <span>{transactionDetails?.transactionRefId}</span>
                  </TransactionEntry>
                </Row>
                <Row>
                  <TransactionEntry>
                    <p>Customer Name</p>
                    <span>{transactionDetails?.customerName}</span>
                  </TransactionEntry>
                  <TransactionEntry>
                    <p>Phone Number</p>
                    <div>
                      <span>{transactionDetails?.phone}</span>
                      <CopyToClipboard text={transactionDetails?.phone || ""}>
                        <p onClick={() => handleCopy()}>
                          {isCopied ? "Copied" : "Copy"}
                        </p>
                      </CopyToClipboard>
                    </div>
                  </TransactionEntry>
                </Row>
              </TopContentLeft>
              <TopContentRight>
                <p>Requested Withdrawal Amount</p>
                <h1>
                  {transactionDetails?.amount
                    ? formatPrice(transactionDetails?.amount)
                    : ""}
                </h1>
                <h4>NGN</h4>
                {!transactionDetails?.approvalDTO.id && (
                  <span>
                    Wallet Balance:{" "}
                    {formatPrice(transactionDetails?.balance || 0)}
                  </span>
                )}
              </TopContentRight>
            </TopContent>
            {transactionDetails?.approvalDTO.id ? (
              <ApprovalWrapper>
                <InfoEntry>
                  <StatusButton status={transactionDetails?.status}>
                    {transactionDetails?.status &&
                      TransactionStatusEnum[transactionDetails.status]}
                  </StatusButton>
                  <span>By: {transactionDetails?.approvalDTO.name}</span>
                </InfoEntry>
                <InfoEntry>
                  <p>
                    {transactionDetails.status ===
                    TransactionStatusEnum.Approved
                      ? "Total Approved Withdrawal"
                      : "Total Declined Withdrawal"}
                  </p>
                  <h2>{formatPrice(transactionDetails?.amount)}</h2>
                </InfoEntry>
                <BankInfoWrapper>
                  <InfoEntry>
                    <p>Bank Name</p>
                    <h3>{transactionDetails?.bankName}</h3>
                  </InfoEntry>
                  <InfoEntry>
                    <p>Account Name</p>
                    <h3>{transactionDetails?.accountName}</h3>
                  </InfoEntry>
                  <InfoEntry>
                    <p>Account Number</p>
                    <h3>{transactionDetails?.accountNumber}</h3>
                  </InfoEntry>
                </BankInfoWrapper>
              </ApprovalWrapper>
            ) : (
              <MiddleContent>
                <p>Bank Information</p>
                <BankInfoWrapper>
                  <InfoEntry>
                    <p>Bank Name</p>
                    <h3>{transactionDetails?.bankName}</h3>
                  </InfoEntry>
                  <InfoEntry>
                    <p>Account Name</p>
                    <h3>{transactionDetails?.accountName}</h3>
                  </InfoEntry>
                  <InfoEntry>
                    <p>Account Number</p>
                    <h3>{transactionDetails?.accountNumber}</h3>
                  </InfoEntry>
                </BankInfoWrapper>
              </MiddleContent>
            )}
            {!transactionDetails?.approvalDTO.id && (
              <ButtonWrapper>
                <DeclineButton
                  onClick={() => handleOpenModal(WithdrawActionEnum.Decline)}
                >
                  Decline
                </DeclineButton>
                <ApproveButton
                  onClick={() => handleOpenModal(WithdrawActionEnum.Approve)}
                >
                  Approve Withdrawal
                </ApproveButton>
              </ButtonWrapper>
            )}
          </BottomWrapper>
        )}
      </ContentWrapper>
      <ActionModal
        handleClose={() => handleCloseModal()}
        isOpen={isModalOpen}
        id={transactionDetails?.id}
        action={action}
      />
    </Container>
  );
};

export default WithdrawalDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 35px;
  width: 100%;
  margin-top: 90px;

  & > h1 {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }

  ${mediaQueries.tablet} {
    padding: 0;
  }

  ${mediaQueries.mobile} {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 35px;

  ${mediaQueries.tablet} {
    padding-bottom: 10px;
  }

  ${mediaQueries.mobile} {
    padding-bottom: 0px;
    background: transparent;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 25px 30px 15px;
  border-bottom: 1px solid #e6e6e6;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 11px;
    }

    & > p {
      letter-spacing: -0.13px;
      color: #000000;
      font-size: 13px;
      font-weight: medium;
    }
  }

  ${mediaQueries.tablet} {
    padding: 10px;
  }

  ${mediaQueries.mobile} {
    padding: 10px 0;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 25px;
  max-height: 560px;
  overflow-x: scroll;

  & > p {
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-left: 50px;
  }

  ${mediaQueries.tablet} {
    padding: 15px 10px 10px;
    max-height: unset;

    & > p {
      padding-left: 0;
    }
  }

  ${mediaQueries.mobile} {
    padding: 15px 0 10px;
    max-height: unset;

    & > p {
      padding-left: 0;
    }
  }
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding: 0 50px;

  ${mediaQueries.tablet} {
    gap: 25px;
    padding: 0;
    margin-top: 15px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column-reverse;
    gap: 55px;
    padding: 0;
    margin-top: 15px;
  }
`;

const TopContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 55%;

  ${mediaQueries.tablet} {
    width: 62%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TransactionEntry = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 45%;

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
  }

  & span {
    display: flex;
    align-items: center;
    width: 100%;
    height: 37px;
    background: #eaeaea;
    letter-spacing: -0.12px;
    color: #727272;
    font-size: 13px;
    border-radius: 5px;
    padding-left: 15px;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 15px;

    & > p {
      letter-spacing: -0.11px;
      color: #faa84f;
      font-size: 11px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  ${mediaQueries.tablet} {
    & span {
      font-size: 12px;
      padding-left: 10px;
    }
  }

  ${mediaQueries.mobile} {
    & span {
      font-size: 12px;
      padding-left: 10px;
    }
  }
`;

const TopContentRight = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  background: #faa84f0d;
  border: 0.5px solid #f8a758;
  border-radius: 10px;
  width: 379px;

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  & > h1 {
    letter-spacing: -1.38px;
    color: #faa84f;
    font-size: 55px;
    font-weight: 600;
  }

  & > h4 {
    letter-spacing: -0.38px;
    color: #faa84f;
    font-size: 19px;
    font-weight: 800;
  }

  & > span {
    position: absolute;
    bottom: -30px;
    letter-spacing: -0.1px;
    color: #727272;
    font-size: 12px;
    font-weight: 600;
  }

  ${mediaQueries.tablet} {
    width: 280px;

    & > h1 {
      font-size: 36px;
    }
  }

  ${mediaQueries.mobile} {
    width: 100%;
    padding: 15px 0;

    & > h1 {
      font-size: 42px;
    }
  }
`;

const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-left: 50px;
  margin-top: 40px;

  & > p {
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }

  ${mediaQueries.tablet} {
    flex-wrap: wrap;
    gap: 15px;
    padding: 20px;
  }

  ${mediaQueries.mobile} {
    flex-wrap: wrap;
    gap: 15px;
    padding: 0px;
  }
`;

const ApprovalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 120px;
  padding: 25px 50px;
  background: #faa84f0d;
  width: 100%;
  margin-top: 40px;

  ${mediaQueries.tablet} {
    gap: 30px 20px;
    margin-top: 30px;
    padding: 20px 10px;
    flex-wrap: wrap;
  }

  ${mediaQueries.mobile} {
    gap: 30px 20px;
    margin-top: 30px;
    padding: 20px 10px;
    flex-wrap: wrap;
  }
`;

const BankInfoWrapper = styled.div`
  display: flex;
  gap: 70px;

  ${mediaQueries.mobile} {
    gap: 20px;
  }
`;

const InfoEntry = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  & > h3 {
    letter-spacing: -0.14px;
    color: #727272;
    font-size: 14px;
    font-weight: bold;
  }

  & > span {
    letter-spacing: -0.11px;
    color: #727272;
    font-size: 13px;
    font-weight: 600;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 40px;
  margin-top: 50px;
  padding: 0 50px;

  ${mediaQueries.mobile} {
    padding: 0;
  }
`;

const DeclineButton = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #ed3838;
  cursor: pointer;
`;

const ApproveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  color: #ffffff;
  font-size: 13px;
  cursor: pointer;
`;

import styled from "styled-components";
import { ProfileResponse } from "../../../../api/auth/types";

export const StatusRenderer = ({ data }: { data: ProfileResponse }) => {
  return (
    <StatusWrapper isActive={data.active}>
      <p>{data.active ? "Active" : "Inactive"}</p>
    </StatusWrapper>
  );
};

interface statusProps {
  isActive: boolean;
}

const StatusWrapper = styled.div<statusProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 13px;
  border-radius: 18px;
  border: ${(props) =>
    props.isActive ? "2px solid #1ABA27" : "2px solid #F8A758"};

  & > p {
    margin: 0;
    color: ${(props) => (props.isActive ? "#1ABA27" : "#F8A758")};
    font-size: 9px;
    font-weight: bold;
  }
`;

import { createGlobalStyle } from "styled-components";
import { AgGridStyles } from "./ag-grid-styles";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }


  html,
  body {
    height: 100%;
    width: 100%;
    background-color: #F5F7F8;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  a {
      text-decoration: none;
  }

  a {
      text-decoration: none;
  }

  ul {
      list-style: none;
  }

  ::-webkit-scrollbar {
      width: 1px;
      height: 1px;
      background-color: var(--black);
  }


  /*vertical Track */

  ::-webkit-scrollbar-track:vertical {
      /* box-shadow: inset 0 0 3px grey; */
      border-radius: 10px;
  }


  /*vertical Handle */

  ::-webkit-scrollbar-thumb:vertical {
      background-color: var(--black);
      border-radius: 10px;
  }


  /*horizontal Track */

  ::-webkit-scrollbar-track:horizontal {
      /* box-shadow: inset 0 0 3px grey; */
      border-radius: 10px;
  } 


  /*horizontal Handle */

  ::-webkit-scrollbar-thumb:horizontal {
      background-color: var(--black);
      border-radius: 10px;
  }


  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
      background: var(--black);
  }

  ::selection {
      background: var(--primary);
      color: var(--white);
  }

  ::-moz-selection {
      background: var(--primary);
      color: var(--white);
  }

  * {
      scrollbar-width: thin;
  }

  ${AgGridStyles}
`;

import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../api";
import { contactUsPayload } from "../../api/contact/types";
import HomeLayout from "../../components/homeLayout";
import DualRing from "../../components/loader";
import { LoaderWrapper } from "../Preferences/components/preferences";
import mediaQueries from "../../../utils/mediaQueries";

const initialState: contactUsPayload = {
  email: "",
  fullName: "",
  question: "",
};

const ContactUs = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<contactUsPayload>(initialState);
  const isValid = Object.values(formData).every((x) => x !== "");

  const emailIsValid = (email: string = "") =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    if (!isValid) {
      toast.info("Please fill all fields");
      return;
    }

    if (!emailIsValid(formData.email)) {
      toast.info("Kindly enter a valid email adddress");
      return;
    }

    setIsSubmitting(true);
    try {
      const res = await api.contactService.contactUs(formData);
      if (res.data.data) {
        toast.success("Message delivered successfully");
      }
      setFormData(initialState);
    } catch (error) {
      const errorMessage = error as any;
      toast.error(errorMessage?.toString() || "Something went wrong");
    }
    setIsSubmitting(false);
  };

  return (
    <HomeLayout backHomeBtn>
      <Container>
        <Title>Contact Us</Title>
        <Input
          placeholder="Full Name"
          value={formData.fullName}
          name={"fullName"}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <Input
          placeholder="Email Address"
          value={formData.email}
          name={"email"}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <TextArea
          placeholder="Message"
          value={formData.question}
          name={"question"}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        <SubmitBtn onClick={handleSubmit}>
          {isSubmitting ? (
            <LoaderWrapper>
              <DualRing width="10px" height="10px" />
            </LoaderWrapper>
          ) : (
            "Send Message"
          )}
        </SubmitBtn>
      </Container>
    </HomeLayout>
  );
};

export default ContactUs;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 533px;
  margin: auto;

  ${mediaQueries.mobile} {
    margin: 60px auto 0;
  }
`;

const Title = styled.h2`
  font-size: 30px;
  color: #ff981a;
  font-weight: 800;

  ${mediaQueries.mobile} {
    font-size: 21px;
  }
`;

const Input = styled.input`
  height: 55px;
  width: 100%;
  background: #efefef;
  border: 1px solid #e2e2e2;
  padding-left: 19px;
  border-radius: 5px;
  margin-top: 18px;
  outline: none;

  &::placeholder {
    color: #a3a3a3;
    font-size: 15px;
    font-weight: 500;
  }

  ${mediaQueries.mobile} {
    height: 42px;

    &::placeholder {
      font-size: 13px;
    }
  }
`;

const TextArea = styled.textarea`
  height: 200px;
  width: 100%;
  background: #efefef;
  border: 1px solid #e2e2e2;
  padding-left: 19px;
  padding-top: 16px;
  border-radius: 5px;
  margin-top: 18px;
  outline: none;

  &::placeholder {
    color: #a3a3a3;
    font-size: 15px;
    font-weight: 500;
  }

  ${mediaQueries.mobile} {
    height: 150px;

    &::placeholder {
      font-size: 13px;
    }
  }
`;

const SubmitBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  background: transparent linear-gradient(181deg, #f8a758 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
  margin-top: 25px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
  }

  ${mediaQueries.mobile} {
    height: 45px;
  }
`;

import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../images/optimaBlack.png";
import { ReactComponent as BackgroundIcon } from "../../../images/login-background.svg";
import { LoginPayload } from "../../api/auth/types";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { emailIsValid } from "../../../utils/regex";
import { signIn } from "../../redux/auth/actions";
import { useAppDispatch, useAppSelector } from "../../redux";
import useAuth from "../../../hooks/useAuth";
import { getAuth } from "../../redux/auth/authSlice";
import DualRing from "../../components/loader";
import { motion } from "framer-motion";
import { pageFadeVariant } from "../../components/animate/variants";
import AuthPasswordInput from "./components/passwordInput";
import mediaQueries from "../../../utils/mediaQueries";

const Login = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAuth();
  let navigate = useNavigate();
  let { state }: any = useLocation();
  let from = state?.path || "/dashboard";

  const {
    accessToken,
    login: { error, pending, response },
  } = useAppSelector(getAuth);

  useEffect(() => {
    isLoggedIn && navigate(from);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (!pending && !error && response && accessToken) {
      navigate(from, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending, error, accessToken, response]);

  const validate = (values: LoginPayload) => {
    const errors: any = {};

    if (!values.emailAddress) {
      errors.emailAddress = "Email is required";
    } else if (!emailIsValid(values.emailAddress)) {
      errors.emailAddress = "Email is not valid";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const form = useFormik<LoginPayload>({
    initialValues: {
      emailAddress: "",
      password: "",
    },
    onSubmit: (values: LoginPayload) => {
      dispatch(signIn(values));
    },
    validate,
    validateOnChange: false,
  });

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  const emailError: any = form.errors.emailAddress;
  const passwordError: any = form.errors.password;
  const year = new Date().getFullYear();

  return (
    <Container
      variants={pageFadeVariant}
      initial={"initial"}
      exit={"exit"}
      animate={"animate"}
    >
      <BackgroundIcon />
      <ContentWrapper>
        <TopWrapper>
          <img src={logo} alt="" onClick={() => navigate("/")} />
          <p>Admin Dashboard</p>
        </TopWrapper>
        <Form onSubmit={form.handleSubmit}>
          <InputWrapper>
            <p>Email Address</p>
            <input
              type="text"
              onChange={form.handleChange}
              id="emailAddress"
              value={form.values.emailAddress}
            />
            {emailError && (
              <span>
                {emailError instanceof Array
                  ? emailError.join(",")
                  : emailError}
              </span>
            )}
          </InputWrapper>
          <AuthPasswordInput
            label={"Password"}
            value={form.values.password}
            onChange={form.handleChange}
            error={passwordError}
            id={"password"}
          />
          <ButtonWrapper>
            <label>
              <input type="checkbox" />
              Stay signed in
            </label>
            <SignInButton type="submit" disabled={pending}>
              {pending ? <DualRing width="10px" height="10px" /> : "Sign In"}
            </SignInButton>
          </ButtonWrapper>
        </Form>
        <ResetWrapper>
          <ResetButton to={"/reset"}>Reset Password</ResetButton>
        </ResetWrapper>
      </ContentWrapper>
      <RightsWrapper>
        <p>© Optima Technology Solutions {year}</p>
        <p>All rights reserved.</p>
      </RightsWrapper>
    </Container>
  );
};

export default Login;

export const Container = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: transparent linear-gradient(348deg, #041630 0%, #2b5a9d 100%) 0%
    0% no-repeat padding-box;

  & > svg {
    position: absolute;
    top: 0;
  }

  ${mediaQueries.mobile} {
    & > svg {
      width: 100%;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  padding: 50px 45px 0px;
  background: #ffffff;
  border: 1px solid #707070;
  z-index: 2;

  ${mediaQueries.mobile} {
    width: 85%;
    padding: 40px 20px 0;
    margin-top: 20px;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  & > img {
    width: 190px;
    height: 43px;
    cursor: pointer;
  }

  & > p {
    font-size: 18px;
    letter-spacing: -0.18px;
    color: #faa84f;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 40px;
  padding-bottom: 25px;
  border-bottom: 2px solid #faa84f;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  & > p {
    font-size: 14px;
    letter-spacing: -0.1px;
    color: #727272;
  }

  & > input {
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    padding: 0 10px;
    background: #f7f7f7;
  }

  & > span {
    text-align: left;
    font-size: 10px;
    color: #d90b2c;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  & > label {
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 10px;
    cursor: pointer;

    & > input {
      display: grid;
      place-content: center;
      width: 17px;
      height: 17px;
      border: 1px solid #707070;
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 0;
      transform: translateY(-0.075em);

      ::before {
        content: "";
        width: 0.75em;
        height: 0.75em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #faa8af;
      }

      :checked::before {
        transform: scale(1);
      }
    }
  }
`;

const SignInButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border: none;
  outline: none;
  width: 139px;
  height: 40px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.9;
  }

  & > div {
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

const ResetWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 100%;

  & > p {
  }
`;

const ResetButton = styled(Link)`
  letter-spacing: -0.11px;
  color: #000000;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export const RightsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  margin-top: 50px;

  & > p {
    font-size: 12px;
    color: #bce8f8;
  }
`;

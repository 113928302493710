import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import { changePasswordPayload } from "../../../api/auth/types";
import { componentSlide } from "../../../components/animate/variants";
import CancelModal from "../../../components/cancelModal";
import DualRing from "../../../components/loader";
import { useAppSelector } from "../../../redux";
import { getAuth } from "../../../redux/auth/authSlice";
import { LoaderWrapper } from "../../Preferences/components/preferences";
import { Container, EntryTitle, EntryWrapper } from "./basicInfo";
import StyledPasswordInput from "./passwordInput";

const Security = () => {
  const [formData, setformData] = useState<changePasswordPayload>({
    newPassword: "",
    oldPassword: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isShowingError, setIsShowingError] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    profile: { response },
  } = useAppSelector(getAuth);

  const navigate = useNavigate();

  useEffect(() => {
    if (confirmPassword && confirmPassword !== formData.newPassword) {
      setIsShowingError(true);
    } else {
      setIsShowingError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPassword, formData]);

  const isValid = () => {
    return (
      Object.values(formData).every((x) => x !== "") &&
      confirmPassword === formData.newPassword
    );
  };

  const handleChange = (name: string, value: string) => {
    if (name === "confirmPassword") {
      setConfirmPassword(value);
      return;
    }
    setformData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await api.authService.changePassword({
        ...formData,
        emailAddress: response?.emailAddress,
      });
      res.data.data && toast.success("Password updated successfully");
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <Container
      variants={componentSlide}
      exit={"exit"}
      animate={"animate"}
      initial={"initial"}
      transition={{ duration: 0.5 }}
    >
      <EntryWrapper>
        <EntryTitle>Current Password</EntryTitle>
        <StyledPasswordInput
          onChange={handleChange}
          name="oldPassword"
          value={formData?.oldPassword}
        />
      </EntryWrapper>
      <EntryWrapper>
        <EntryTitle>New Password</EntryTitle>
        <StyledPasswordInput
          onChange={handleChange}
          name="newPassword"
          value={formData?.newPassword}
        />
      </EntryWrapper>
      <EntryWrapper>
        <EntryTitle>Confirm Password</EntryTitle>
        <StyledPasswordInput
          onChange={handleChange}
          name="confirmPassword"
          value={confirmPassword}
        />
      </EntryWrapper>
      {isShowingError && <ErrorMessage>Password does not match</ErrorMessage>}
      <BottomWrapper>
        <ButtonWrapper>
          <CancelButton onClick={() => setIsCancelOpen(true)}>
            Cancel
          </CancelButton>
          <SaveButton onClick={() => handleSubmit()} disabled={!isValid()}>
            {isLoading ? (
              <LoaderWrapper>
                <DualRing width="10px" height="10px" />
              </LoaderWrapper>
            ) : (
              "Save Changes"
            )}
          </SaveButton>
        </ButtonWrapper>
      </BottomWrapper>
      <CancelModal
        handleClose={() => setIsCancelOpen(false)}
        isOpen={isCancelOpen}
        handleSubmit={() => navigate("/dashboard", { replace: true })}
      />
    </Container>
  );
};

export default Security;

export const BottomWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  gap: 50px;
`;

export const CancelButton = styled.div`
  color: #727272;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.18s ease-in;

  &:hover {
    font-weight: 600;
  }
`;

export const SaveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 212px;
  height: 40px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:disabled {
    background: #eaeaea;
    color: #000;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #ff4c16;
  margin-left: 160px;
  margin-top: -10px;
`;

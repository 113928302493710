import styled from "styled-components";
import { ReactComponent as LeftIcon } from "../../../images/left-arrow.svg";
import { ReactComponent as GreenTick } from "../../../images/circle-tick.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelModal from "../../components/cancelModal";
import { cardsData } from "./components/data";
import mediaQueries from "../../../utils/mediaQueries";

const SelectType = () => {
  const [selectedCard, setSelectedCard] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/dashboard/cards", { replace: true });
  };

  return (
    <Container>
      <h1>Manage Cards</h1>
      <ContentWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        <NewCardWrapper>
          <h1>Add New Card</h1>
          <p>Select Card Category</p>
          <CardBoxWrapper>
            {cardsData.map((card, index) => (
              <CardBox
                selected={selectedCard === card.id}
                key={index}
                onClick={() => setSelectedCard(card.id)}
              >
                <GreenTick />
                <div>
                  <p>{card.title}</p>
                  {card.examples && <span>{card.examples}</span>}
                </div>
              </CardBox>
            ))}
          </CardBoxWrapper>
          <ButtonWrapper>
            <CancelButton onClick={() => setIsOpen(true)}>Cancel</CancelButton>
            <ContinueButton
              disabled={!!!selectedCard}
              onClick={() => navigate(`/dashboard/cards/add/${selectedCard}`)}
            >
              Continue
            </ContinueButton>
          </ButtonWrapper>
        </NewCardWrapper>
      </ContentWrapper>
      <CancelModal
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
        handleSubmit={() => handleNavigate()}
      />
    </Container>
  );
};

export default SelectType;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 35px 20px;
  width: 100%;
  max-width: 1300px;
  margin-top: 90px;

  & > h1 {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }

  ${mediaQueries.mobileAndTablet} {
    padding: 0;
  }
`;

export const ContentWrapper = styled.div`
  width: fit-content;
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 35px;

  ${mediaQueries.mobile} {
    background: transparent;
    padding: 0;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 25px 30px 15px;
  border-bottom: 1px solid #e6e6e6;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 11px;
    }

    & > p {
      letter-spacing: -0.13px;
      color: #000000;
      font-size: 13px;
      font-weight: medium;
    }
  }

  ${mediaQueries.tablet} {
    padding: 10px;
  }

  ${mediaQueries.mobile} {
    padding: 10px 0;
  }
`;

export const NewCardWrapper = styled.div`
  padding: 0 50px 0;
  margin-top: 20px;

  & > h1 {
    letter-spacing: -0.24px;
    color: #faa84f;
    font-weight: 800;
    font-size: 20px;
    margin: 50px 0 15px;
  }

  & > p {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
  }

  ${mediaQueries.tablet} {
    padding: 0 10px;

    & > h1 {
      margin-top: 30px;
    }
  }

  ${mediaQueries.mobile} {
    padding: 0;

    & > h1 {
      font-size: 18px;
      margin: 20px 0 12px;
    }

    & > p {
      font-size: 15px;
    }
  }
`;

const CardBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 50px;

  ${mediaQueries.tablet} {
    justify-content: center;
  }

  ${mediaQueries.mobile} {
    margin-top: 35px;
  }
`;

interface cardBoxProps {
  selected: boolean;
}

const CardBox = styled.div<cardBoxProps>`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 40px 30px;
  width: 300px;
  height: 205px;
  background: transparent linear-gradient(350deg, #041630 0%, #2b5a9d 100%) 0%
    0% no-repeat padding-box;
  transform: ${(props) => props.selected && "scale(0.95)"};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  & > svg {
    display: ${(props) => (props.selected ? "inline-block" : "none")};
    position: absolute;
    top: -20px;
    right: -25px;
    transition: all 0.2s ease-in-out;
  }

  & > div {
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 100%;

    & > p {
      align-self: center;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.16px;
      color: #ffffff;
      margin-bottom: 35px;
    }

    & > span {
      width: 70%;
      margin: 0 auto;
      letter-spacing: -0.1px;
      color: #bce8f8;
      opacity: 0.7;
      font-size: 10px;
      text-align: center;
    }
  }

  &:hover {
    opacity: 1;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
  margin-top: 40px;

  ${mediaQueries.tablet} {
    margin-top: 30px;
  }

  ${mediaQueries.mobile} {
    margin-top: 30px;
  }
`;

export const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 139px;
  height: 40px;
  letter-spacing: -0.13px;
  color: #727272;
  font-size: 13px;
  font-weight: bold;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 5px;
  cursor: pointer;
`;

export const ContinueButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 139px;
  height: 40px;
  letter-spacing: -0.13px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

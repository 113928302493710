import styled from "styled-components";
import { Container, ContentWrapper, TopWrapper } from "../selectType";
import { ReactComponent as LeftIcon } from "../../../../images/left-arrow.svg";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { BaseCardType } from "../../../../types/common";
import api from "../../../api";
import { CardsResponse } from "../../../api/cards/types";
import { toast } from "react-toastify";
import DualRing from "../../../components/loader";
import VisaCardType from "./visaCardType";
import NormalCardType from "./normalCardType";
import ReceiptCardType from "./receiptCardType";
import mediaQueries from "../../../../utils/mediaQueries";

const ManageCards = () => {
  const [cardData, setCardData] = useState<CardsResponse>();
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    async function getCard(id: string) {
      setIsLoading(true);
      try {
        const res = await api.cardService.getCard(id);
        setCardData(res.data.data);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsLoading(false);
    }

    params.id && getCard(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigate = () => {
    navigate("/dashboard/cards", { replace: true });
  };

  return (
    <Container>
      <h1>Manage Cards</h1>
      <ManagaCardsWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        <Title>Configure Card</Title>
        <>
          {isLoading ? (
            <LoaderWrapper>
              <DualRing width="40px" height="40px" color="#727272" />
            </LoaderWrapper>
          ) : cardData?.baseCardType === BaseCardType.REGULAR ? (
            <NormalCardType cardData={cardData} />
          ) : cardData?.baseCardType === BaseCardType.AMAZON ? (
            <ReceiptCardType cardData={cardData} />
          ) : (
            cardData?.baseCardType === BaseCardType.SPECIAL && (
              <VisaCardType cardData={cardData} />
            )
          )}
        </>
      </ManagaCardsWrapper>
    </Container>
  );
};

export default ManageCards;

const ManagaCardsWrapper = styled(ContentWrapper)`
  width: 100%;
`;

const Title = styled.p`
  font-weight: 800;
  font-size: 20px;
  letter-spacing: -0.2px;
  color: #faa84f;
  margin: 20px 50px 35px;

  ${mediaQueries.tablet} {
    margin: 20px 10px 30px;
  }

  ${mediaQueries.mobile} {
    font-size: 16px;
    margin: 10px 0 20px;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

import styled from "styled-components";
import { formatPrice } from "../../utils/formatters";
import mediaQueries from "../../utils/mediaQueries";

export interface cardType {
  value: number;
  percentage: number;
  icon?: any;
}

const DashboardCard: React.FC<cardType> = ({
  value,
  percentage,
  icon: Icon,
}) => {
  return (
    <Container>
      <p>Total Inflow</p>
      <BottomWrapper>
        <BottomLeftWrapper positive={percentage >= 0}>
          <p>{formatPrice(value)}</p>
          <span>{percentage >= 0 ? `+${percentage}%` : `-${percentage}%`}</span>
        </BottomLeftWrapper>
        <BottomRightWrapper>
          <Icon />
        </BottomRightWrapper>
      </BottomWrapper>
    </Container>
  );
};

export default DashboardCard;

const Container = styled.div`
  width: 100%;
  height: 130px;
  background: #ffffff;
  border-radius: 10px;
  padding: 25px;

  & > p {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.14px;
    color: #727272;
    margin: 0 0 7px 0;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface BottomLeftWrapperProps {
  positive: boolean;
}

const BottomLeftWrapper = styled.div<BottomLeftWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > p {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -1.9px;
    color: #000000;
  }

  & > span {
    font-size: 11px;
    letter-spacing: -0.11px;
    color: ${(props) => (props.positive ? "#34B53A" : "#ED3838")};
  }

  ${mediaQueries.smallDesktop} {
    & > p {
      font-size: 24px;
    }
  }
`;

const BottomRightWrapper = styled.div`
  & > svg {
    width: 60px;
    height: 40px;
  }
`;

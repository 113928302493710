import styled from "styled-components";
import { ReactComponent as PenIcon } from "../../../../images/pen.svg";
import { ReactComponent as TrashIcon } from "../../../../images/trash-can.svg";
import noImage from "../../../../images/no-image.png";
import { BottomWrapper } from "../../Profile/components/security";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { componentSlide } from "../../../components/animate/variants";
import CountryModal from "./countryModal";
import api from "../../../api";
import {
  CountriesResponse,
  EditCountryObj,
} from "../../../api/countries/types";
import DeleteModal from "../../../components/deleteModal";
import { toast } from "react-toastify";
import EditCountryModal from "./editCountryModal";
import DualRing from "../../../components/loader";
import Denominations, { MainLoaderWrapper } from "./denominations";
import mediaQueries from "../../../../utils/mediaQueries";

const SystemPreferences = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [itemId, setItemId] = useState<string>();
  const [selectedEditCountryIndex, setSelectedEditCountryIndex] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [isCountryEditModalOpen, setIsCountryEditModalOpen] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [countryData, setCountryData] = useState<EditCountryObj>();
  const [countries, setCountries] = useState<CountriesResponse[]>();
  const [shouldRefresh, setShouldRefresh] = useState(false);

  async function getCountries() {
    setIsLoading(true);
    try {
      const res = await api.countriesService.getCountries();
      setCountries(res.data.data);
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    shouldRefresh &&
      setTimeout(() => {
        getCountries();
        setShouldRefresh(false);
      }, 1000);
  }, [shouldRefresh]);

  useEffect(() => {
    async function getCountryData(id: string) {
      setSelectedEditCountryIndex(id);
      try {
        const res = await api.countriesService.getSingleCountry(id);
        setCountryData({
          name: res.data.data.name,
          logo: res.data.data.countryLogo,
        });
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setSelectedEditCountryIndex("");
    }

    countryId && getCountryData(countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const handleOpenDeleteModal = (id: string) => {
    setItemId(id);
    setIsDeleteOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setItemId("");
    setIsDeleteOpen(false);
  };

  const handleOpenCountryEditModal = (id: string) => {
    setCountryId(id);
  };

  const handleCloseCountryEditModal = () => {
    setCountryId("");
    setIsCountryEditModalOpen(false);
    setCountryData({ name: "", logo: undefined });
  };

  useEffect(() => {
    countryData?.name && setIsCountryEditModalOpen(true);
  }, [countryData]);

  const handleDelete = async () => {
    if (itemId) {
      setIsDeleting(true);
      try {
        const res = await api.countriesService.deleteCountry(itemId);
        res.data.data && toast.success("Country deleted successfully");
        setShouldRefresh(true);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsDeleting(false);
    }

    handleCloseDeleteModal();
  };

  return (
    <Container
      variants={componentSlide}
      exit={"exit"}
      animate={"animate"}
      initial={"initial"}
      transition={{ duration: 0.5 }}
    >
      <TopWrapper>
        <SectionWrapper>
          <h2>Countries</h2>
          <SectionBox>
            {isLoading ? (
              <MainLoaderWrapper>
                <DualRing width="30px" height="30px" color="#727272" />
              </MainLoaderWrapper>
            ) : (
              <CountriesContent>
                {countries?.map((x, i) => (
                  <CountryEntry key={i}>
                    <LeftEntry>
                      <img src={x.countryLogo || noImage} alt="" />
                      <p>{x.name}</p>
                    </LeftEntry>
                    <RightEntry>
                      <EditButton
                        onClick={() => handleOpenCountryEditModal(x.id)}
                      >
                        {selectedEditCountryIndex === x.id ? (
                          <LoaderWrapper>
                            <DualRing width="10px" height="10px" />
                          </LoaderWrapper>
                        ) : (
                          <>
                            <PenIcon />
                            <p>Edit</p>
                          </>
                        )}
                      </EditButton>
                      <DeleteWrapper
                        onClick={() => handleOpenDeleteModal(x.id)}
                      >
                        <TrashIcon />
                      </DeleteWrapper>
                    </RightEntry>
                  </CountryEntry>
                ))}
              </CountriesContent>
            )}
            <BottomWrapper>
              <AddButton onClick={() => setIsCountryModalOpen(true)}>
                + Add Country
              </AddButton>
            </BottomWrapper>
          </SectionBox>
        </SectionWrapper>
        <Denominations />
      </TopWrapper>
      <DeleteModal
        handleClose={() => handleCloseDeleteModal()}
        isOpen={isDeleteOpen}
        handleSubmit={() => handleDelete()}
        isDeleting={isDeleting}
      />
      <CountryModal
        handleClose={() => setIsCountryModalOpen(false)}
        isOpen={isCountryModalOpen}
        setShouldRefresh={setShouldRefresh}
      />
      <EditCountryModal
        handleClose={() => handleCloseCountryEditModal()}
        isOpen={isCountryEditModalOpen}
        id={countryId}
        countryData={countryData}
        setShouldRefresh={setShouldRefresh}
      />
    </Container>
  );
};

export default SystemPreferences;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 25px;
    margin-bottom: 0;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%;

  & > h2 {
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 13px;
    font-weight: bold;
  }

  ${mediaQueries.smallDesktop} {
    width: 48%;
  }

  ${mediaQueries.tablet} {
    width: 47%;
  }

  ${mediaQueries.mobile} {
    width: 100%;

    & > h2 {
      font-size: 15px;
      font-weight: 600;
    }
  }
`;

export const SectionBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 5px;
`;

const CountriesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding-bottom: 5px;
  height: 335px;
  overflow: scroll;
`;

export const AddSectionWrapper = styled.div`
  position: absolute;
  bottom: 2px;
  left: 0;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 15px;
  background: #f8f8f8;
  z-index: 2;

  & > input {
    width: 121px;
    height: 40px;
    outline: none;
    padding-left: 10px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 5px;
  }

  ${mediaQueries.smallDesktop} {
    & > input {
      width: 90px;
    }
  }
`;

export const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  height: 40px;
  background: transparent linear-gradient(170deg, #2b5a9d 0%, #041630 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  cursor: pointer;
`;

const CountryEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 37px;
`;

const LeftEntry = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & > img {
    width: 40px;
    height: 35px;
  }

  & > p {
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 13px;
    font-weight: bold;
  }

  ${mediaQueries.smallDesktop} {
    gap: 8px;

    & > img {
      width: 35px;
      height: 30px;
    }
  }
`;

const RightEntry = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 78px;
  height: 30px;
  background: #727272;
  border-radius: 3px;
  cursor: pointer;

  & svg {
    width: 11px;
    height: 11px;
  }

  & p {
    letter-spacing: -0.11px;
    color: #ffffff;
    font-size: 11px;
    font-weight: bold;
  }

  ${mediaQueries.smallDesktop} {
    width: 62px;
    height: 28px;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 10px 10px 0;
`;

const DeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #ed383826;
  border: 0.5px solid #ea3a3e;
  border-radius: 3px;
  cursor: pointer;

  & > svg {
    width: 15px;
    height: 18px;
  }

  ${mediaQueries.smallDesktop} {
    height: 28px;
    width: 28px;
  }
`;

import React, { useEffect, useRef, useState } from "react";
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import {
  gridCellStyle,
  GridWrapper,
} from "../../Dashboard/components/gridComponents/styles";
import { StatusRenderer } from "../../Dashboard/components/gridComponents/statusRenderer";
import { AgGridReact } from "ag-grid-react";
import { formatPrice } from "../../../../utils/formatters";
import DualRing from "../../../components/loader";
import { RecentActivitiesObj } from "../../../api/dashboard/types";
import { MainLoaderWrapper } from "../../Preferences/components/denominations";
import styled from "styled-components";

type ActivityField = keyof RecentActivitiesObj | "action" | undefined;

interface GridDef extends ColDef {
  field: ActivityField;
}

interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: RecentActivitiesObj[];
}

interface props {
  data: RecentActivitiesObj[];
  isLoading: boolean;
}

const Grid: React.FC<props> = ({ data, isLoading }) => {
  const [gridData, setGridData] = useState<RecentActivitiesObj[]>([]);
  const gridApi = useRef<GridApi | null>(null);
  const GridData: RecentActivitiesObj[] = gridData;

  async function getGridData() {
    setGridData(data);
  }

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "Date",
      field: "createdOn",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1.2,
      valueFormatter: (params) => {
        const { data }: { data: RecentActivitiesObj } = params;
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
          new Date(data.createdOn)
        );
      },
    },
    {
      headerName: "Type",
      field: "transactionType",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Value",
      field: "amount",
      flex: 0.8,
      valueFormatter: (params) => {
        const { data }: { data: RecentActivitiesObj } = params;
        return formatPrice(Number(data.amount));
      },
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Status",
      sortable: false,
      field: "status",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: StatusRenderer,
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <>
      {isLoading ? (
        <MainLoaderWrapper>
          <DualRing width="30px" height="30px" color="#727272" />
        </MainLoaderWrapper>
      ) : gridData.length > 0 ? (
        <GridWrapper id="OpenOrdersGridWrapper" className={`ag-theme-balham`}>
          <AgGridReact
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={45}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            defaultColDef={{
              suppressMenu: true,
              sortable: true,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            getRowNodeId={(data: RecentActivitiesObj) => {
              return data.id.toString();
            }}
            // noRowsOverlayComponentFramework={GridEmptyState}
            // gridOptions={{
            //     onCellClicked: handleCellClicked,
            // }}
          />
        </GridWrapper>
      ) : (
        <EmptyTxt>You have no recent activities</EmptyTxt>
      )}
    </>
  );
};

export default Grid;

const EmptyTxt = styled.p`
  margin-top: 70px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

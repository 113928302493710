import { contactPageInterface } from "../../../types/common";

export const tabData = [
  {
    name: "System Preferences",
  },
  {
    name: "App Homescreen",
  },
  {
    name: "Terms and Conditions",
  },
  {
    name: "Contact",
  },
  {
    name: "FAQs",
  },
  {
    name: "Push Notification",
  },
];

export const contactLabelData: contactPageInterface[] = [
  {
    label: "Phone Number",
    name: "phone",
    url: "",
  },
  {
    label: "Email",
    name: "email",
    url: "",
  },
  {
    label: "Instagram",
    name: "instagram",
    url: "",
  },
];

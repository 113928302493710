import { TransactionStatusEnum } from "../../../types/common";
import { ApiResponse } from "../../redux/types";
import request from "../request";
import { TransactionActionPayload } from "../transactions/types";
import { cryptoSaleResponse, cryptoSaleTableObj } from "./types";

const getCryptoSale = async (
  pageIndex: number,
  transactionStatus: TransactionStatusEnum,
  keyword?: string
) => {
  return await request.get<ApiResponse<cryptoSaleTableObj[]>>(
    keyword
      ? `/CryptoSale?transactionStatus=${transactionStatus}&Filter=customername&Keyword=${keyword}`
      : `/CryptoSale?transactionStatus=${transactionStatus}&pageSize=10&pageIndex=${pageIndex}`
  );
};
const getApprovedCryptoSale = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<cryptoSaleTableObj[]>>(
    keyword
      ? `/CryptoSale/Approved?Filter=customername&Keyword=${keyword}`
      : `/CryptoSale/Approved?pageSize=10&pageIndex=${pageIndex}`
  );
};

const getSingleCryptoSale = async (id: string) => {
  return await request.get<ApiResponse<cryptoSaleResponse>>(
    `/CryptoSale/${id}`
  );
};

const cryptoSaleAction = async (
  id: string,
  payload: TransactionActionPayload
) => {
  const { amount, comment, transactionStatus } = payload;

  return await request.put<ApiResponse<boolean>>(
    `/CryptoSale/${id}`,
    {},
    {
      headers: {
        amount: amount as string,
        comment: comment as string,
        transactionStatus: String(transactionStatus),
      },
    }
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCryptoSale,
  getSingleCryptoSale,
  cryptoSaleAction,
  getApprovedCryptoSale,
};

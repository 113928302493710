import styled from "styled-components";
import { Container, ContentWrapper, TopWrapper } from "../selectType";
import { ReactComponent as LeftIcon } from "../../../../images/left-arrow.svg";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UploadBox } from "../add";
import { CardResponseEdit } from "../../../api/cards/types";
import api from "../../../api";
import { LoaderWrapper } from "../configure";
import DualRing from "../../../components/loader";
import { BaseCardType } from "../../../../types/common";
import EditVisaCardType from "./editVisaCardType";
import EditReceiptCardType from "./editReceiptCardType";
import EditNormalCardType from "./editNormalCardType";
import mediaQueries from "../../../../utils/mediaQueries";

const EditCard = () => {
  const [cardData, setCardData] = useState<CardResponseEdit>();
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  let navigate = useNavigate();

  async function getCard(id: string) {
    setIsLoading(true);
    try {
      const res = await api.cardService.getCardForEdit(id);
      setCardData(res.data.data);
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    params.id && getCard(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigate = () => {
    navigate("/dashboard/cards", { replace: true });
  };

  return (
    <Container>
      <h1>Edit Cards</h1>
      <ManagaCardsWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        <BottomWrapper>
          {isLoading ? (
            <LoaderWrapper>
              <DualRing width="40px" height="40px" color="#727272" />
            </LoaderWrapper>
          ) : cardData?.baseCardType === BaseCardType.REGULAR ? (
            <EditNormalCardType cardData={cardData} />
          ) : cardData?.baseCardType === BaseCardType.AMAZON ? (
            <EditReceiptCardType cardData={cardData} />
          ) : (
            cardData?.baseCardType === BaseCardType.SPECIAL && (
              <EditVisaCardType cardData={cardData} />
            )
          )}
        </BottomWrapper>
      </ManagaCardsWrapper>
    </Container>
  );
};

export default EditCard;

const ManagaCardsWrapper = styled(ContentWrapper)`
  width: 100%;

  ${mediaQueries.mobile} {
    background: transparent;
    padding-bottom: 0;
  }
`;

const BottomWrapper = styled.div`
  max-height: 560px;
  overflow-x: scroll;
  padding-top: 20px;

  ${mediaQueries.mobileAndTablet} {
    max-height: unset;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 60px;
  padding: 0 50px 0;
  margin-bottom: 70px;

  ${mediaQueries.tablet} {
    padding: 0 10px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column-reverse;
    padding: 0;
    gap: 20px;
    margin-bottom: 30px;
  }
`;

export const CardLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > p {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #000000;
  }

  & > input {
    width: 293px;
    height: 45px;
    background: #ffffff;
    border: 1px solid #b9b9b9;
    border-radius: 5px;
    padding-left: 12px;
    outline: none;
    font-weight: bold;
  }
`;

export const EditImageBox = styled(UploadBox)`
  height: 140px;
  width: 246px;

  ${mediaQueries.tablet} {
    width: 300px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    height: 210px;
  }
`;

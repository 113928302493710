import { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { ReactComponent as LeftIcon } from "../../../images/left-arrow.svg";
import mediaQueries from "../../../utils/mediaQueries";
import BasicInfo from "./components/basicInfo";
import Security from "./components/security";
import { tabData } from "./data";

const Profile = () => {
  const [activeTab, setActiveTab] = useState(1);
  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/dashboard", { replace: true });
  };

  return (
    <Container>
      <TitleWrapper>
        <h1>Admin Profile</h1>
      </TitleWrapper>
      <ContentWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        <BottomWrapper>
          <TabsWrapper>
            {tabData.map((x, i) => (
              <Tab
                key={i}
                onClick={() => setActiveTab(i + 1)}
                isActive={activeTab === i + 1}
              >
                {x.name}
              </Tab>
            ))}
          </TabsWrapper>
          <RightContentWrapper>
            {activeTab === 1 && <BasicInfo />}
            {activeTab === 2 && <Security />}
          </RightContentWrapper>
        </BottomWrapper>
      </ContentWrapper>
    </Container>
  );
};
export default Profile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 35px 20px;
  width: 100%;
  margin-top: 90px;

  ${mediaQueries.mobileAndTablet} {
    padding: 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > h1 {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }
`;

const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding: 20px;

  ${mediaQueries.tablet} {
    padding: 0;
  }

  ${mediaQueries.mobile} {
    padding: 0;
    background: transparent;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  padding: 25px 15px 15px;
  border-bottom: 1px solid #e6e6e6;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 11px;
    }

    & > p {
      letter-spacing: -0.13px;
      color: #000000;
      font-size: 13px;
      font-weight: bold;
    }
  }

  ${mediaQueries.tablet} {
    padding: 10px;
  }

  ${mediaQueries.mobile} {
    padding: 10px 0;
    margin-bottom: 10px;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  width: 100%;

  ${mediaQueries.mobile} {
    flex-direction: column;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 50px 20px 30px;
  height: 60vh;
  border-right: 1px solid #e6e6e6;

  ${mediaQueries.tablet} {
    padding: 20px;
    height: auto;
  }

  ${mediaQueries.mobile} {
    flex-direction: row;
    padding: 10px 0;
    gap: 20px;
    border-right: none;
    height: auto;
  }
`;

interface tabProps {
  isActive: boolean;
}

const Tab = styled.div<tabProps>`
  display: flex;
  align-items: center;
  padding: 7px 12px;
  width: 156px;
  height: 35px;
  font-weight: bold;
  font-size: 13px;
  color: ${(props) => (props.isActive ? "#FFFFFF" : "#727272")};
  background: ${(props) => props.isActive && "#faa84f"};
  border-radius: 5px;
  transition: all 0.2s ease-in;
  cursor: pointer;
`;

const RightContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 50px;
  max-height: 530px;
  overflow: scroll;

  ${mediaQueries.tablet} {
    padding: 20px;
  }

  ${mediaQueries.mobile} {
    padding: 0;
    max-height: calc(100vh - 265px);
  }
`;

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";
import { toast } from "react-toastify";
import api from "../../api";
import { LoaderWrapper } from "../Preferences/components/preferences";
import DualRing from "../../components/loader";
import LoginContent from "./loginContent";
import { LoginPayload } from "../../api/auth/types";
import { signIn } from "../../redux/auth/actions";
import { getAuth } from "../../redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  isOpen: boolean;
  handleClose: () => void;
}

const DeleteModal: React.FC<props> = ({ isOpen, handleClose }) => {
  const [userId, setUserId] = React.useState(null);
  const dispatch = useAppDispatch();

  const {
    login: { error, pending, response },
  } = useAppSelector(getAuth);

  React.useEffect(() => {
    error && toast.error(error);
  }, [error]);

  const handleLogin = (values: LoginPayload) => {
    dispatch(signIn(values));
  };

  React.useEffect(() => {
    response && !userId && setUserId(response?.userId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const onClose = () => {
    setUserId(null);
    handleClose();
  };

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = async () => {
    if (userId) {
      setIsSubmitting(true);
      try {
        const res = await api.authService.deleteUser("htjhdada");
        if (res.data) {
          toast.success("Account deleted successfully");
        }
      } catch (error) {
        const errorMessage = error as any;
        toast.error(errorMessage?.toString() || "Something went wrong");
      }
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      fullWidth
      maxWidth={"xs"}
      sx={{ borderRadius: 2 }}
      aria-describedby="alert-dialog-slide-description"
    >
      {!userId ? (
        <LoginContent pending={pending} handleSubmit={handleLogin} />
      ) : (
        <>
          <DialogTitle
            sx={{
              fontSize: 21,
              paddingTop: 5,
              paddingLeft: 4,
              paddingRight: 4,
              paddingBottom: 1,
              fontWeight: 500,
            }}
          >
            Sure you want to delete your account?
          </DialogTitle>
          <DialogContent sx={{ paddingLeft: 4, paddingRight: 4 }}>
            <DialogContentText id="alert-dialog-slide-description">
              This action is irreversible. Continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonWrapper>
              <CancelButton onClick={onClose}>No, Go back</CancelButton>
              <ConfirmButton onClick={handleSubmit}>
                {isSubmitting ? (
                  <LoaderWrapper>
                    <DualRing width="10px" height="10px" />
                  </LoaderWrapper>
                ) : (
                  "Yes, Delete"
                )}
              </ConfirmButton>
            </ButtonWrapper>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DeleteModal;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  width: 100%;
  padding: 20px 15px 15px;
  border-top: 1px solid #c3c3c3;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  letter-spacing: -0.2px;
  font-weight: bold;
  font-size: 12px;
  color: #727272;
  border-radius: 3px;
  cursor: pointer;
`;

interface confirmProps {
  isDisabled?: boolean;
}

const ConfirmButton = styled.div<confirmProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 134px;
  height: 33px;
  background: #ed3838;
  letter-spacing: -0.22px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
`;

import { HeaderDropdown } from "../../../types/common";
import { logout } from "../../redux/auth/actions";

export const dropdownOptions: HeaderDropdown[] = [
  {
    name: "Profile Settings",
    link: "/dashboard/profile",
  },
  {
    name: "Manage Admins",
    link: "/dashboard/admins",
  },
  {
    name: "Logout",
    action: logout,
  },
];

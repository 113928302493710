import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import logo from "../../../images/optimaBlack.png";
import {
  Container,
  ContentWrapper,
  RightsWrapper,
  TopWrapper,
} from "../login/login";
import { ReactComponent as BackgroundIcon } from "../../../images/login-background.svg";
import { ReactComponent as GreenCheckIcon } from "../../../images/circle-green-tick.svg";
import { MouseEvent, useState } from "react";
import { pageFadeVariant } from "../../components/animate/variants";
import api from "../../api";
import { resetPasswordPayload } from "../../api/auth/types";
import { toast } from "react-toastify";
import PasswordStrength from "./passwordStrength";
import { LoaderWrapper } from "../Preferences/components/preferences";
import DualRing from "../../components/loader";
import AuthPasswordInput from "../login/components/passwordInput";

const ResetPassword = () => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<resetPasswordPayload>({
    password: "",
    confirmPassword: "",
  });
  const [numeric, setNumeric] = useState(false);
  const [moreThanEightChar, setMoreThanEightChar] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const { email, token } = useParams<{ email: string; token: string }>();

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/login");
  };

  const handlePasswordChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
    passwordCheck(value);
  };

  const handleConfirmPasswordChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();
    if (token && email && formData) {
      setIsLoading(true);
      try {
        const res = await api.authService.resetPassword({
          ...formData,
          token: token,
          email: email,
        });
        res.data && setIsSuccessful(true);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsLoading(false);
    }
  };

  const passwordCheck = (y: string) => {
    const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    specialChars.test(y) ? setSpecialChar(true) : setSpecialChar(false);
    y.length < 8 ? setMoreThanEightChar(false) : setMoreThanEightChar(true);
    y.search(/[A-Z]/) < 0 ? setUpperCase(false) : setUpperCase(true);
    y.search(/[0-9]/) < 0 ? setNumeric(false) : setNumeric(true);
  };

  const isValid =
    upperCase &&
    numeric &&
    specialChar &&
    moreThanEightChar &&
    formData.password === formData.confirmPassword;

  return (
    <Container
      variants={pageFadeVariant}
      initial={"initial"}
      exit={"exit"}
      animate={"animate"}
    >
      <BackgroundIcon />
      <ContentWrapper>
        <TopWrapper>
          <img src={logo} alt="" onClick={() => handleGoBack()} />
          <p>Admin Dashboard</p>
        </TopWrapper>
        {!isSuccessful ? (
          <Form>
            <p>Reset Password</p>
            <AuthPasswordInput
              label={"New Password"}
              value={formData?.password}
              onChange={handlePasswordChange}
              name={"password"}
            />
            <AuthPasswordInput
              label={"Confirm Password"}
              value={formData?.confirmPassword}
              onChange={handleConfirmPasswordChange}
              name={"confirmPassword"}
            />
            <ResetButton
              type={"button"}
              onClick={(e) => handleSubmit(e)}
              disabled={!isValid}
            >
              {isLoading ? (
                <LoaderWrapper>
                  <DualRing width="10px" height="10px" />
                </LoaderWrapper>
              ) : (
                "Reset Password"
              )}
            </ResetButton>
            <PasswordStrengthWrapper>
              <PasswordStrength
                label="At least one uppercase character"
                isActive={upperCase}
              />
              <PasswordStrength
                label="At least one numeric character"
                isActive={numeric}
              />
              <PasswordStrength
                label="At least one special character"
                isActive={specialChar}
              />
              <PasswordStrength
                label="Password should be 8 characters or more"
                isActive={moreThanEightChar}
              />
            </PasswordStrengthWrapper>
          </Form>
        ) : (
          <SuccessWrapper>
            <GreenCheckIcon />
            <p>Password reset successful</p>
            <SignInButton onClick={() => handleGoBack()}>
              Back to sign in
            </SignInButton>
          </SuccessWrapper>
        )}
      </ContentWrapper>
      <RightsWrapper>
        <p>© Optima Technology Solutions {new Date().getFullYear()}</p>
        <p>All rights reserved.</p>
      </RightsWrapper>
    </Container>
  );
};

export default ResetPassword;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 30px 0 60px;

  & > p {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.14px;
    color: #000000;
  }
`;

const ResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  margin-top: 10px;
  letter-spacing: -0.12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  transition: all 0.2s ease-in;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const PasswordStrengthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-self: flex-start;
`;

const SuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  padding-bottom: 40px;

  & > p {
    letter-spacing: -0.11px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }
`;

const SignInButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  margin-top: 15px;
  background: transparent linear-gradient(180deg, #2b5a9d 0%, #041630 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  letter-spacing: -0.12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
`;

import { createSlice } from "@reduxjs/toolkit";
import storage from "../../../utils/storage";
import { AuthState } from "./types";
import {
  signIn,
  logout,
  setToken,
  refreshToken,
  getUserProfile,
} from "./actions";
import { RootState } from "..";

const cachedTokens = storage.get("optimaUser");

const initialState: AuthState = {
  ...cachedTokens,
  login: {
    error: null,
    pending: false,
  },
  profile: {
    error: null,
    pending: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: () => {
      return {
        ...initialState,
        accessToken: undefined,
        refreshToken: undefined,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(setToken.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    });
    builder.addCase(signIn.pending, (state) => {
      return {
        ...state,
        login: {
          ...state.login,
          pending: true,
          error: null,
          response: undefined,
        },
      };
    });
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      return {
        ...state,
        login: {
          ...state.login,
          pending: false,
          error: null,
          response: payload,
        },
      };
    });
    builder.addCase(signIn.rejected, (state, action) => {
      return {
        ...state,
        login: {
          ...state.login,
          pending: false,
          error: action.payload,
          response: null,
        },
      };
    });
    builder.addCase(getUserProfile.pending, (state) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          pending: true,
          error: null,
          response: undefined,
        },
      };
    });
    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          pending: false,
          error: null,
          response: payload,
        },
      };
    });
    builder.addCase(getUserProfile.rejected, (state, action) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          pending: false,
          error: action.payload,
          response: null,
        },
      };
    });
    builder.addCase(logout.fulfilled, (state) => {
      return {
        ...state,
        login: {
          ...state.login,
          pending: false,
          error: null,
          response: null,
        },
        accessToken: undefined,
      };
    });
    builder.addCase(
      refreshToken.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.accessToken = payload;
      }
    );
  },
});

export const { resetAuth } = authSlice.actions;

export const getAuth = (state: RootState) => state.auth;

export default authSlice.reducer;

import auth from "./auth";
import cards from "./cards";
import users from "./users";
import transactions from "./transactions";
import countries from "./countries";
import denomination from "./denomination";
import dashboard from "./dashboard";
import terms from "./terms";
import faq from "./faq";
import ad from "./ad";
import notifications from "./notifications";
import contact from "./contact";
import pushNotifications from "./pushNotifications";
import crypto from "./crypto";
import cryptoSale from "./cryptoSale";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  authService: auth,
  cardService: cards,
  userService: users,
  transactionService: transactions,
  countriesService: countries,
  denominationsService: denomination,
  dashboardService: dashboard,
  termsService: terms,
  faqService: faq,
  adService: ad,
  notificationService: notifications,
  contactService: contact,
  pushNotificationService: pushNotifications,
  cryptoService: crypto,
  cryptoSaleService: cryptoSale,
};

import React from "react";
import styled from "styled-components";
import ProfileAvatar from "../../../components/avatar";
import moment from "moment";
import mediaQueries from "../../../../utils/mediaQueries";

interface Props {
  name: string;
  role: string;
  lastSeen: Date | string;
}

const AdminCard: React.FC<Props> = ({ name, role, lastSeen }) => {
  const firstName = name.split(" ")[0];
  return (
    <Container>
      <RightContent>
        <ProfileAvatar name={name} />
        <div>
          <p>{firstName}</p>
          <span>{role}</span>
        </div>
      </RightContent>
      <span>{moment(lastSeen).fromNow()}</span>
    </Container>
  );
};

export default AdminCard;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 10px;
  }
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & > img {
    width: 51px;
    height: 51px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 2px;

    & > p {
      letter-spacing: -0.18px;
      color: #000000;
      font-size: 18px;
      margin: 0;
    }

    & > span {
      letter-spacing: -0.1px;
      color: #faa84f;
      font-size: 10px;
    }
  }

  ${mediaQueries.mobile} {
    & > div {
      & > p {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;

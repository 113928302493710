import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { HeaderDropdown } from "../../../types/common";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getAuth } from "../../redux/auth/authSlice";
import { dropdownOptions } from "./data";

interface props {
  onClose: () => void;
}

const AdminDropdown: React.FC<props> = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    profile: { response },
  } = useAppSelector(getAuth);

  const handleClick = (option: HeaderDropdown) => {
    option.link && navigate(option.link);
    if (option.action) {
      dispatch(option.action());
      navigate("/login", { replace: true });
    }
    onClose();
  };

  const handleShowAdmin = () => {
    return response?.roles &&
      response?.roles.length > 0 &&
      response?.roles[0].toLowerCase() === "superadmin"
      ? dropdownOptions
      : dropdownOptions.filter((x) => x.name.toLowerCase() !== "manage admins");
  };

  return (
    <Container>
      {handleShowAdmin().map((x, i) => (
        <Option onClick={() => handleClick(x)} key={i} isLogout={!!x.action}>
          {x.name}
        </Option>
      ))}
    </Container>
  );
};

export default AdminDropdown;

const Container = styled(motion.div)`
  position: absolute;
  top: 65px;
  right: 25px;
  flex-direction: column;
  padding: 15px 20px;
  width: 215px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 5px 12px #00000017;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  z-index: 2;
`;

interface OptionProps {
  isLogout: boolean;
}

const Option = styled.p<OptionProps>`
  color: #727272;
  font-size: 14px;
  padding: ${(props) => (props.isLogout ? "12px 0" : "10px 0")};
  border-top: ${(props) => props.isLogout && "1px solid #B1B1B1"};
  margin-top: ${(props) => props.isLogout && "10px"};
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    font-weight: 600;
  }
`;

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

interface props {
  total: number;
  currentPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const Paginator: React.FC<props> = ({ total, currentPage, handleChange }) => {
  return (
    <Stack>
      <Pagination
        count={total}
        defaultPage={1}
        page={currentPage}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
      />
    </Stack>
  );
};

export default Paginator;

import styled from "styled-components";
import { ReactComponent as ArrowDownIcon } from "../../../images/arrow-circle-down.svg";
import ProfileAvatar from "../avatar";
import AdminDropdown from "./dropdown";
import { useState } from "react";
import { ClickAwayListener } from "@mui/base";
import Fade from "../animate/Fade";
import { useAppSelector } from "../../redux";
import { getAuth } from "../../redux/auth/authSlice";
import Notification from "../notification";
import { useMobileCheck } from "../../../hooks/useMobileCheck";
import MobileNavOverlay from "../mobileNavOverlay";
import Hamburger from "../hamburger";
import mediaQueries from "../../../utils/mediaQueries";
import optimaLogo from "../../../images/optimaLogo.png";
import { useNavigate } from "react-router";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const navigate = useNavigate();

  const {
    profile: { response },
  } = useAppSelector(getAuth);

  const { isDesktop } = useMobileCheck();

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const handleNavBarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <Container>
      {!isDesktop && (
        <Logo src={optimaLogo} onClick={() => navigate("/dashboard")} />
      )}
      <ContentWrapper>
        <Notification />
        <Divider />
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <ProfileDetailsWrapper onClick={handleClick}>
              {response?.name ? (
                <ProfileAvatar name={response.name} />
              ) : (
                <DummyAvatarCircle />
              )}
              <ProfileDetailsContent>
                <div>
                  <p>{response?.name.split(" ")[0] || ""}</p>
                  <StatusWrapper>
                    <CustomCircle />
                    Online
                  </StatusWrapper>
                </div>
                <p>{response?.roles[0] || "Admin"}</p>
              </ProfileDetailsContent>
              <ArrowDownIcon />
            </ProfileDetailsWrapper>
            <Fade isOpen={isOpen}>
              <AdminDropdown onClose={handleClickAway} />
            </Fade>
          </div>
        </ClickAwayListener>
        {!isDesktop && (
          <Hamburger handleClick={handleNavBarToggle} isOpen={isNavbarOpen} />
        )}
      </ContentWrapper>
      <MobileNavOverlay
        isOpen={isNavbarOpen}
        setIsNavbarOpen={setIsNavbarOpen}
      />
    </Container>
  );
};

export default Header;

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 84%;
  height: 90px;
  padding: 15px 90px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  z-index: 999;

  ${mediaQueries.tablet} {
    width: 100%;
    padding: 15px;
    justify-content: space-between;
    height: 70px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    padding: 15px;
    justify-content: space-between;
    height: 60px;
  }
`;

const Logo = styled.img`
  width: 116px;
  height: 25px;
  flex-shrink: 0;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;

  ${mediaQueries.mobile} {
    gap: 12px;
  }
`;

const Divider = styled.div`
  width: 0px;
  height: 35px;
  border-left: 2px solid #dddddd;
`;

const ProfileDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  & > img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  &:hover {
    background: #f6f7f8;

    svg {
      & path:first-of-type {
        fill: #727272;
      }
      & path:nth-of-type(2) {
        fill: #f5f7f8;
      }
    }
  }

  & svg {
    margin-left: 15px;
    cursor: pointer;
    & path:first-of-type {
      transition: all 0.2s ease-in;
    }
    & path:nth-of-type(2) {
      transition: all 0.2s ease-in;
    }
  }

  ${mediaQueries.mobile} {
    gap: 7px;

    & svg {
      margin-left: 0;
    }
  }
`;

const DummyAvatarCircle = styled.div`
  width: 52px;
  height: 52px;
  background: #faa84f1a;
  border-radius: 50%;
  border: 1px solid #f8a758;

  ${mediaQueries.mobile} {
    width: 38px;
    height: 38px;
  }
`;

const ProfileDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;

  & > div {
    display: flex;
    align-items: center;
    gap: 15px;

    p {
      margin: 0;
      font-size: 19px;
      font-weight: bold;
    }
  }

  & > p {
    color: #9d9d9d;
    font-size: 12px;
  }

  ${mediaQueries.mobile} {
    display: none;
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 52px;
  height: 18px;
  background: #20c353;
  border: 1px solid #20c353;
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
`;

interface circleProps {
  color?: string;
}

const CustomCircle = styled.div<circleProps>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : "#fff")};
`;

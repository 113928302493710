import styled, { css } from "styled-components";
import { ButtonWrapper, CancelButton, ContinueButton } from "../selectType";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import {
  cardRate,
  CardTypeEnum,
  dropDownType,
  genericdropDownType,
} from "../../../../types/common";
import Select, { ActionMeta } from "react-select";
import api from "../../../api";
import {
  CardsResponse,
  ConfigureNormalObj,
  ConfigureNormalPayload,
} from "../../../api/cards/types";
import _ from "lodash";
import { toast } from "react-toastify";
import CancelModal from "../../../components/cancelModal";
import { DenominationResponse } from "../../../api/denomination/types";
import { LoaderWrapper } from "../../Preferences/components/preferences";
import DualRing from "../../../components/loader";
import mediaQueries from "../../../../utils/mediaQueries";

const initialCardRate: cardRate = {
  denominationId: "",
  rate: "",
  amount: "",
};

interface props {
  cardData: CardsResponse;
}

const NormalCardType: React.FC<props> = ({ cardData }) => {
  const [selectedCountryId, setSelectedCountryId] = useState<string>("");
  const [physicalRate, setPhysicalRate] = useState<cardRate>(initialCardRate);
  const [eCodeRate, setECodeRate] = useState<cardRate>(initialCardRate);
  const [denominations, setDenominations] = useState<DenominationResponse[]>(
    []
  );
  const [formData, setFormData] = useState<ConfigureNormalObj[]>([]);
  const [physicalCardTypeId, setPhysicalCardTypeId] = useState<string>("");
  const [eCodeCardTypeId, setECordCardTypeId] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  //parse country to dropdown obj
  const parseCardCountry = (obj: CardsResponse | undefined) => {
    const arr: dropDownType[] = [];
    obj &&
      obj.cardTypeDTOs.forEach((x) => {
        arr.push({
          label: x.countryDTO.name,
          value: x.countryDTO.id,
        });
      });
    //get unique country object
    return _.uniqBy(arr, "label");
  };

  useEffect(() => {
    async function getDominations() {
      const res = await api.denominationsService.getDenominations();
      setDenominations(res.data.data);
    }

    getDominations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate("/dashboard/cards", { replace: true });
  };

  const handleCountryChange = (id: string) => {
    setSelectedCountryId(id);
    let physicalId = cardData?.cardTypeDTOs.find(
      (x) => x.cardType === CardTypeEnum.Physical && x.countryDTO.id === id
    )?.id;
    physicalId && setPhysicalCardTypeId(physicalId);
    let ecodeId = cardData?.cardTypeDTOs.find(
      (x) => x.cardType === CardTypeEnum.ECode && x.countryDTO.id === id
    )?.id;
    ecodeId && setECordCardTypeId(ecodeId);
  };

  const handlePhysicalRateDelete = (index: number) => {
    // get unique card rate
    const res = formData.find(
      (x) =>
        x.countryId === selectedCountryId && x.cardTypeId === physicalCardTypeId
    );
    if (res?.cardRates.length === 1) {
      formData.splice(formData.indexOf(res), 1);
    } else {
      res?.cardRates.splice(index, 1);
    }

    setFormData([...formData]);
  };

  const handlePhysicalRateAdd = () => {
    if (
      physicalRate.rate &&
      physicalRate.denominationId !== "" &&
      physicalCardTypeId
    ) {
      //find previous country data, if not found, create a new one
      const oldCountryData = formData.find(
        (x) => x.countryId === selectedCountryId
      );
      if (!oldCountryData) {
        let newEntry: ConfigureNormalObj = {
          countryId: selectedCountryId,
          cardTypeId: physicalCardTypeId,
          cardRates: [{ ...physicalRate, rate: Number(physicalRate.rate) }],
        };
        setFormData([...formData, newEntry]);
      } else {
        // find and update existing card configuration, else create new one
        let oldEntry = formData.find(
          (x) =>
            x.countryId === selectedCountryId &&
            x.cardTypeId === physicalCardTypeId
        );
        if (!oldEntry) {
          let newEntry: ConfigureNormalObj = {
            countryId: selectedCountryId,
            cardTypeId: physicalCardTypeId,
            cardRates: [{ ...physicalRate, rate: Number(physicalRate.rate) }],
          };
          setFormData([...formData, newEntry]);
        } else {
          let oldRate = oldEntry.cardRates.find(
            (x) => x.denominationId === physicalRate.denominationId
          );
          if (oldRate) {
            toast.error("Denomination already exist");
            return;
          }
          let oldEntryIndex = formData.findIndex(
            (x) =>
              x.countryId === selectedCountryId &&
              x.cardTypeId === physicalCardTypeId
          );
          let updatedObj = {
            ...oldEntry,
            cardRates: [
              ...oldEntry.cardRates,
              { ...physicalRate, rate: Number(physicalRate.rate) },
            ],
          };
          //update the object
          formData.splice(oldEntryIndex, 1, updatedObj);
          setFormData([...formData]);
        }
      }
      setPhysicalRate(initialCardRate);
    }
  };

  const handleECodeRateDelete = (index: number) => {
    const res = formData.find(
      (x) =>
        x.countryId === selectedCountryId && x.cardTypeId === eCodeCardTypeId
    );

    if (res?.cardRates.length === 1) {
      formData.splice(formData.indexOf(res), 1);
    } else {
      res?.cardRates.splice(index, 1);
    }

    setFormData([...formData]);
  };

  const handleECodeRateAdd = () => {
    // reference handlePhysicalRateAdd function above
    if (eCodeRate.rate && eCodeRate.denominationId !== "" && eCodeCardTypeId) {
      const oldCountryData = formData.find(
        (x) => x.countryId === selectedCountryId
      );
      if (!oldCountryData) {
        let newEntry: ConfigureNormalObj = {
          countryId: selectedCountryId,
          cardTypeId: eCodeCardTypeId,
          cardRates: [{ ...eCodeRate, rate: Number(eCodeRate.rate) }],
        };
        setFormData([...formData, newEntry]);
      } else {
        let oldEntry = formData.find(
          (x) =>
            x.countryId === selectedCountryId &&
            x.cardTypeId === eCodeCardTypeId
        );
        if (!oldEntry) {
          let newEntry: ConfigureNormalObj = {
            countryId: selectedCountryId,
            cardTypeId: eCodeCardTypeId,
            cardRates: [{ ...eCodeRate, rate: Number(eCodeRate.rate) }],
          };
          setFormData([...formData, newEntry]);
        } else {
          let oldRate = oldEntry.cardRates.find(
            (x) => x.denominationId === eCodeRate.denominationId
          );
          if (oldRate) {
            toast.error("Denomination already exist");
            return;
          }
          let oldEntryIndex = formData.findIndex(
            (x) =>
              x.countryId === selectedCountryId &&
              x.cardTypeId === eCodeCardTypeId
          );
          let updatedObj = {
            ...oldEntry,
            cardRates: [
              ...oldEntry.cardRates,
              { ...eCodeRate, rate: Number(eCodeRate.rate) },
            ],
          };
          formData.splice(oldEntryIndex, 1, updatedObj);
          setFormData([...formData]);
        }
      }
      setECodeRate(initialCardRate);
    }
  };

  const dropDownOptions = () => {
    const arr: genericdropDownType<number, string>[] = [];
    denominations.forEach((x) => {
      arr.push({ value: x?.id, label: x?.amount });
    });

    return arr;
  };

  const onPhysicalTypeChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    setPhysicalRate({
      ...physicalRate,
      denominationId: newValue.value,
      amount: newValue.label,
    });
  };

  const onECodeTypeChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    setECodeRate({
      ...eCodeRate,
      denominationId: newValue.value,
      amount: newValue.label,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let dataToSend: ConfigureNormalPayload = {
        normalCardConfigDTO: formData,
      };
      if (params?.id) {
        const res = await api.cardService.configureNormal(
          dataToSend,
          params.id
        );

        res.data.data && toast.success("Card configured successfully");
        handleBack();
      }
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsSubmitting(false);
  };

  const isValid = !!selectedCountryId && formData.length !== 0;

  return (
    <>
      <BottomWrapper>
        <BottomLeft>
          <BottomLeftBox>
            <p>Card Country</p>
            <BottomLeftContent>
              {parseCardCountry(cardData).map((x, i) => (
                <SelectEntry
                  key={i}
                  onClick={() => handleCountryChange(x.value)}
                  active={selectedCountryId === x.value}
                >
                  {x.label}
                </SelectEntry>
              ))}
            </BottomLeftContent>
          </BottomLeftBox>
        </BottomLeft>
        <BottomRight>
          <BottomRightContent>
            <p>Physical Card Rate</p>
            <BottomContentBox>
              <BottomContentHeader>
                <p>Denomination</p>
                <p>Rate per $</p>
              </BottomContentHeader>
              <RateEntryWrapper>
                {formData
                  .find(
                    (x) =>
                      x.cardTypeId === physicalCardTypeId &&
                      x.countryId === selectedCountryId
                  )
                  ?.cardRates?.map((x, i) => (
                    <EntryWrapper key={i}>
                      <p>{x.amount}</p>
                      <p>{x.rate}</p>
                      <span onClick={() => handlePhysicalRateDelete(i)}>x</span>
                    </EntryWrapper>
                  ))}
              </RateEntryWrapper>
            </BottomContentBox>
            <RateWrapper>
              <Select
                options={dropDownOptions()}
                onChange={onPhysicalTypeChange}
                menuPlacement="auto"
                value={dropDownOptions().filter(
                  (x) => x.value === physicalRate.denominationId
                )}
                isDisabled={!!!selectedCountryId}
                maxMenuHeight={130}
                classNamePrefix={"rate"}
              />
              <div>
                <span>=</span>
                N
                <input
                  type="text"
                  value={physicalRate.rate}
                  disabled={!!!selectedCountryId}
                  onChange={(e) =>
                    setPhysicalRate({
                      ...physicalRate,
                      rate: e.target.value,
                    })
                  }
                />
              </div>
              <AddButton onClick={handlePhysicalRateAdd}>Add</AddButton>
            </RateWrapper>
          </BottomRightContent>
          <BottomRightContent>
            <p>E Code Rate</p>
            <BottomContentBox>
              <BottomContentHeader>
                <p>Denomination</p>
                <p>Rate per $</p>
              </BottomContentHeader>
              <RateEntryWrapper>
                {formData
                  .find(
                    (x) =>
                      x.cardTypeId === eCodeCardTypeId &&
                      x.countryId === selectedCountryId
                  )
                  ?.cardRates?.map((x, i) => (
                    <EntryWrapper key={i}>
                      <p>{x.amount}</p>
                      <p>{x.rate}</p>
                      <span onClick={() => handleECodeRateDelete(i)}>x</span>
                    </EntryWrapper>
                  ))}
              </RateEntryWrapper>
            </BottomContentBox>
            <RateWrapper>
              <Select
                options={dropDownOptions()}
                onChange={onECodeTypeChange}
                menuPlacement="auto"
                value={dropDownOptions().filter(
                  (x) => x.value === eCodeRate.denominationId
                )}
                isDisabled={!!!selectedCountryId}
                maxMenuHeight={130}
                classNamePrefix={"rate"}
              />
              <div>
                <span>=</span>
                N
                <input
                  type="text"
                  value={eCodeRate.rate}
                  onChange={(e) =>
                    setECodeRate({
                      ...eCodeRate,
                      rate: e.target.value,
                    })
                  }
                  disabled={!!!selectedCountryId}
                />
              </div>
              <AddButton onClick={handleECodeRateAdd}>Add</AddButton>
            </RateWrapper>
          </BottomRightContent>
        </BottomRight>
      </BottomWrapper>
      <ButtonWrapper>
        <CancelButton onClick={() => setIsOpen(true)}>Cancel</CancelButton>
        <ContinueButton disabled={!isValid} onClick={handleSubmit}>
          {isSubmitting ? (
            <LoaderWrapper>
              <DualRing width="10px" height="10px" />
            </LoaderWrapper>
          ) : (
            "Save and Activate"
          )}
        </ContinueButton>
      </ButtonWrapper>
      <CancelModal
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
        handleSubmit={() => navigate("/dashboard/cards", { replace: true })}
      />
    </>
  );
};

export default NormalCardType;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 50px 0;

  ${mediaQueries.tablet} {
    padding: 0 10px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 25px;
    padding: 0;
  }
`;

const BottomLeft = styled.div`
  display: flex;
  gap: 60px;
  width: 40%;

  ${mediaQueries.tablet} {
    width: 25%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const BottomLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  & > p {
    letter-spacing: -0.11px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  ${mediaQueries.tablet} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    gap: 15px;
    width: 100%;

    & > p {
      font-size: 14px;
    }
  }
`;

const BottomLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .css-1s2u09g-control,
  .css-1insrsq-control,
  .css-1pahdxg-control,
  .css-1hb7zxy-Indicator {
    cursor: pointer;
    min-height: 34px;
    height: 34px;
    box-shadow: unset;
  }

  .css-319lph-ValueContainer {
    padding: 0 8px;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 3px 4px;
  }
`;

interface countryProps {
  active?: boolean;
}

const SelectEntry = styled.p<countryProps>`
  letter-spacing: -0.14px;
  color: #c9c9c9;
  font-size: 14px;
  min-width: 127px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.25ms ease-in-out;

  ${(props) =>
    props.active &&
    css`
      height: 100%;
      width: 100%;
      color: #ffffff;
      font-weight: bold;
      background: #faa84f;
      border-radius: 3px;
    `}
`;

const BottomRight = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 55%;

  ${mediaQueries.tablet} {
    width: 70%;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }
`;

const BottomRightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;

  & > p {
    letter-spacing: -0.11px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  ${mediaQueries.mobile} {
    width: 100%;

    & > p {
      font-size: 14px;
    }
  }
`;

const BottomContentBox = styled.div`
  padding: 15px;
  height: 215px;
  background: #ffffff;
  border: 1px solid #8e8e8e;
  border-radius: 5px;
`;

const BottomContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & > p {
    width: 45%;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: #727272;
  }
`;

const RateEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 155px;
  overflow-x: scroll;
`;

const EntryWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 4px;
  border-bottom: 1px solid #c3c3c3;

  & > p {
    width: 45%;
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 13px;
    font-weight: 600;
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #fdd5d5;
    color: #ef3338;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 4px;
    margin-left: 4px;
    cursor: pointer;
  }
`;

const RateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > div:first-of-type {
    border: none;
    outline: none;
    height: 30px;
    background: #f7f7f7;
    border-radius: 3px;
    flex-basis: 120px;
    flex-grow: 1;
  }

  & > div:nth-of-type(2) {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    letter-spacing: -0.14px;
    color: #727272;
    font-size: 14px;
    font-weight: bold;
    width: clamp(27%, 55px, 30%);

    & > span {
      position: absolute;
      top: 0px;
      left: -1px;
      color: #727272;
      font-weight: bold;
      font-size: 20px;
    }

    & > input {
      height: 30px;
      width: 100%;
      background: #f7f7f7;
      border: 1px solid #b9b9b945;
      outline: none;
      border-radius: 3px;
      padding: 0 5px;
      font-weight: bold;
      font-size: 11px;
    }
  }

  .rate__menu-list {
    font-size: 12px;

    & > div {
      cursor: pointer;
    }
  }

  .rate__value-container {
    font-size: 12px;
  }

  .rate__single-value {
    font-size: 12px !important;
  }

  .css-1s2u09g-control,
  .css-1pahdxg-control,
  .css-1insrsq-control,
  .css-1hb7zxy-Indicator {
    min-height: 30px;
    height: 30px;
    cursor: pointer;
    box-shadow: unset;
  }

  .css-319lph-ValueContainer {
    padding: 0 8px;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 3px 4px;
  }

  .css-qc6sy-singleValue {
    font-size: 14px;
  }
`;

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 30px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 3px;
  letter-spacing: -0.1px;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
`;

import styled from "styled-components";
import { ReactComponent as LeftIcon } from "../../../../images/left-arrow.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api";
import { transactionDetailsResponse } from "../../../api/transactions/types";
import TransactionDetails from "../components/transactionDetails";
import DeclineModal from "../components/actionModals/decline";
import ActionDropdown from "../components/actionDropdown";
import Fade from "../../../components/animate/Fade";
import { ClickAwayListener } from "@mui/base";
import { formatPrice } from "../../../../utils/formatters";
import { TransactionStatusEnum } from "../../../../types/common";
import { toast } from "react-toastify";
import DualRing from "../../../components/loader";
import ImageModal from "../components/imageModal";
import mediaQueries from "../../../../utils/mediaQueries";

const TransactionDetail = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [isDeclineOpen, setIsDeclineOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenImageModal, setIsOpenImageModal] = useState(false);
  const [previewImageIndex, setPreviewImageIndex] = useState<number>(0);
  const [transactionDetails, setTransactionDetails] =
    useState<transactionDetailsResponse>();
  let navigate = useNavigate();
  const params = useParams();

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleImageOpen = (index: number) => {
    setPreviewImageIndex(index);

    if (previewImageIndex || previewImageIndex === 0) {
      setIsOpenImageModal(true);
    }
  };

  const handleImageClose = () => {
    setIsOpenImageModal(false);
  };

  const handleNavigate = () => {
    navigate("/dashboard/transactions/sales", { replace: true });
  };

  const handleGetTransactionData = async () => {
    setIsLoading(true);
    if (params?.id) {
      try {
        const res = await api.transactionService.getTransactionDetails(
          params?.id
        );
        setTransactionDetails(res?.data?.data);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetTransactionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <Container>
      <ContentWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <DualRing width="30px" height="30px" color="#727272" />
          </LoaderWrapper>
        ) : (
          <BottomWrapper>
            <p>Transaction Information</p>
            <TopContent>
              <TopContentLeft>
                <Row>
                  <TransactionEntry>
                    <p>Transaction Date</p>
                    <span>
                      {transactionDetails?.createdOn &&
                        new Intl.DateTimeFormat("en-US", options).format(
                          new Date(transactionDetails?.createdOn)
                        )}
                    </span>
                  </TransactionEntry>
                  <TransactionEntry>
                    <p>Transaction ID</p>
                    <span>{transactionDetails?.transactionRefId}</span>
                  </TransactionEntry>
                </Row>
                <Row>
                  <TransactionEntry>
                    <p>Customer Name</p>
                    <span>{transactionDetails?.customerDetails.fullName}</span>
                  </TransactionEntry>
                  <TransactionEntry>
                    <p>Phone Number</p>
                    <div>
                      <span>
                        {transactionDetails?.customerDetails.phoneNumber}
                      </span>
                      <CopyToClipboard
                        text={
                          transactionDetails?.customerDetails.phoneNumber || ""
                        }
                      >
                        <p onClick={() => handleCopy()}>
                          {isCopied ? "Copied" : "Copy"}
                        </p>
                      </CopyToClipboard>
                    </div>
                  </TransactionEntry>
                </Row>
              </TopContentLeft>
              <TopContentRight>
                <p>Estimated Payout</p>
                <h1>
                  {transactionDetails?.totalExpectedAmount
                    ? formatPrice(transactionDetails?.totalExpectedAmount)
                    : ""}
                </h1>
              </TopContentRight>
            </TopContent>
            {transactionDetails?.actionByUser.userId && (
              <MiddleContent>
                <ActionEntry>
                  <StatusButton status={transactionDetails?.status}>
                    {transactionDetails?.status &&
                      TransactionStatusEnum[transactionDetails?.status]}
                  </StatusButton>
                  <span>By: {transactionDetails?.actionByUser.fullName}</span>
                </ActionEntry>
                <ActionEntry>
                  <p>Total Payed</p>
                  <h2>{transactionDetails?.amountPaid}</h2>
                </ActionEntry>
                <ActionEntry width="40%">
                  <p>Narration:</p>
                  <h4>{transactionDetails?.actionByUser?.comment || ""}</h4>
                </ActionEntry>
              </MiddleContent>
            )}
            <BottomContent>
              <BottomContentLeft>
                <GiftCardWrapper>
                  <GiftCardEntry>
                    <p>Gift Card Name</p>
                    <h3>{transactionDetails?.cardDetails.cardName}</h3>
                  </GiftCardEntry>
                  <GiftCardEntry>
                    <p>Gift Card Country</p>
                    <h3>{transactionDetails?.cardDetails.country}</h3>
                  </GiftCardEntry>
                  <GiftCardEntry>
                    <p>Gift Card Type</p>
                    <h3>{transactionDetails?.cardDetails.type}</h3>
                  </GiftCardEntry>
                </GiftCardWrapper>
                <TransactionDetails data={transactionDetails?.cardSold} />
              </BottomContentLeft>
              <BottomContentRight>
                <GiftCardEntry>
                  <p>Card Images</p>
                  <h3>{`${transactionDetails?.cardTransactionImages?.length} Attachments`}</h3>
                </GiftCardEntry>
                <ImageWrapper>
                  {transactionDetails?.cardTransactionImages.map((x, i) => (
                    <img
                      src={x.logoUrl}
                      alt=""
                      onClick={() => handleImageOpen(i)}
                    />
                  ))}
                </ImageWrapper>
                <ImageModal
                  open={isOpenImageModal}
                  previewImageIndex={previewImageIndex}
                  handleClose={handleImageClose}
                  collection={
                    transactionDetails?.cardTransactionImages.map(
                      (x) => x.logoUrl
                    ) || []
                  }
                />
              </BottomContentRight>
            </BottomContent>
            {!transactionDetails?.actionByUser.userId && (
              <ButtonWrapper>
                <DeclineButton onClick={() => setIsDeclineOpen(true)}>
                  Decline
                </DeclineButton>
                <ClickAwayListener onClickAway={() => setIsDropDownOpen(false)}>
                  <ApproveButtonContainer>
                    <ApproveButton
                      onClick={() => setIsDropDownOpen((prev) => !prev)}
                    >
                      Approve Transaction
                    </ApproveButton>
                    <Fade isOpen={isDropDownOpen}>
                      <ActionDropdown
                        onClose={() => setIsDropDownOpen(false)}
                        amount={transactionDetails?.totalExpectedAmount}
                        id={transactionDetails?.id}
                      />
                    </Fade>
                  </ApproveButtonContainer>
                </ClickAwayListener>
              </ButtonWrapper>
            )}
          </BottomWrapper>
        )}
      </ContentWrapper>
      <DeclineModal
        handleClose={() => setIsDeclineOpen(false)}
        isOpen={isDeclineOpen}
        id={transactionDetails?.id}
      />
    </Container>
  );
};

export default TransactionDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 35px;
  width: 100%;
  margin-top: 90px;

  ${mediaQueries.tablet} {
    padding: 0;
  }

  ${mediaQueries.mobile} {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 35px;

  ${mediaQueries.tablet} {
    padding-bottom: 10px;
  }

  ${mediaQueries.mobile} {
    padding-bottom: 0px;
    background: transparent;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 25px 30px 15px;
  border-bottom: 1px solid #e6e6e6;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 11px;
    }

    & > p {
      letter-spacing: -0.13px;
      color: #000000;
      font-size: 13px;
      font-weight: medium;
    }
  }

  ${mediaQueries.tablet} {
    padding: 10px;
  }

  ${mediaQueries.mobile} {
    padding: 10px 0;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 25px;
  max-height: 560px;
  overflow-x: scroll;

  & > p {
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-left: 50px;
  }

  ${mediaQueries.tablet} {
    padding: 15px 10px 10px;
    max-height: unset;

    & > p {
      padding-left: 0;
    }
  }

  ${mediaQueries.mobile} {
    padding: 15px 0 10px;
    max-height: unset;

    & > p {
      padding-left: 0;
    }
  }
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding: 0 50px;

  ${mediaQueries.tablet} {
    gap: 25px;
    padding: 0;
    margin-top: 15px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column-reverse;
    gap: 25px;
    padding: 0;
    margin-top: 15px;
  }
`;

const TopContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 55%;

  ${mediaQueries.tablet} {
    width: 62%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TransactionEntry = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 45%;

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
  }

  & span {
    display: flex;
    align-items: center;
    width: 100%;
    height: 37px;
    background: #eaeaea;
    letter-spacing: -0.12px;
    color: #727272;
    font-size: 13px;
    border-radius: 5px;
    padding-left: 15px;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 15px;

    & > p {
      letter-spacing: -0.11px;
      color: #faa84f;
      font-size: 11px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  ${mediaQueries.tablet} {
    & span {
      font-size: 12px;
      padding-left: 10px;
    }
  }

  ${mediaQueries.mobile} {
    & span {
      font-size: 12px;
      padding-left: 10px;
    }
  }
`;

const TopContentRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  background: #faa84f0d;
  border: 0.5px solid #f8a758;
  border-radius: 10px;
  width: 379px;

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  & > h1 {
    letter-spacing: -1.38px;
    color: #faa84f;
    font-size: 55px;
    font-weight: 600;
  }

  ${mediaQueries.tablet} {
    width: 280px;

    & > h1 {
      font-size: 36px;
    }
  }

  ${mediaQueries.mobile} {
    width: 100%;
    padding: 15px 0;

    & > h1 {
      font-size: 42px;
    }
  }
`;

const MiddleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 50px;
  background: #faa84f0d;
  width: 100%;
  margin-top: 40px;

  ${mediaQueries.tablet} {
    flex-wrap: wrap;
    gap: 15px;
    padding: 20px;
  }

  ${mediaQueries.mobile} {
    flex-wrap: wrap;
    gap: 15px;
    padding: 20px;
  }
`;

interface entryProps {
  width?: string;
}

const ActionEntry = styled.div<entryProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${(props) => props.width && props.width};

  & > span {
    letter-spacing: -0.11px;
    color: #727272;
    font-size: 13px;
  }

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  & > h2 {
    letter-spacing: -0.75px;
    color: #000000;
    font-size: 30px;
  }

  & > h4 {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 13px;
  }
`;

interface statusProps {
  status?: TransactionStatusEnum;
}

export const StatusButton = styled.div<statusProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  font-size: 13px;
  font-weight: bold;
  border: ${(props) =>
    props.status === TransactionStatusEnum.Approved
      ? "2px solid #1ABA27"
      : props.status === TransactionStatusEnum.Partial
      ? "2px solid #f8a758"
      : "2px solid #ED3838"};
  border-radius: 26px;
  color: ${(props) =>
    props.status === TransactionStatusEnum.Approved
      ? "#1ABA27"
      : props.status === TransactionStatusEnum.Partial
      ? "#f8a758"
      : "#ED3838"};

  ${mediaQueries.mobile} {
    font-size: 12px;
    padding: 7px 10px;
    width: 100px;
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding: 0 50px;

  ${mediaQueries.tablet} {
    padding: 0;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 25px;
    padding: 0;
  }
`;

const BottomContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 55%;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const BottomContentRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 40%;

  ${mediaQueries.mobile} {
    width: 100%;
    gap: 15px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  height: 210px;
  overflow: scroll;
  padding: 20px;
  background: #fdfdfd;
  border: 0.5px solid #707070;
  border-radius: 5px;

  & > img {
    width: 180px;
    height: 80px;
    border-radius: 5px;
    cursor: pointer;
  }

  ${mediaQueries.mobile} {
    padding: 12px;

    & > img {
      width: 47%;
      height: 90px;
    }
  }
`;

const GiftCardWrapper = styled.div`
  display: flex;
  gap: 70px;

  ${mediaQueries.tablet} {
    gap: 0;
    justify-content: space-between;
  }

  ${mediaQueries.mobile} {
    gap: 0;
    justify-content: space-between;
  }
`;

const GiftCardEntry = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  & > h3 {
    letter-spacing: -0.14px;
    color: #727272;
    font-size: 14px;
    font-weight: bold;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 40px;
  margin-top: 50px;
  padding: 0 50px;

  ${mediaQueries.mobile} {
    margin-top: 30px;
    padding: 0;
  }
`;

const DeclineButton = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #ed3838;
  cursor: pointer;
`;

const ApproveButtonContainer = styled.div`
  position: relative;
`;

const ApproveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  color: #ffffff;
  font-size: 13px;
  cursor: pointer;
`;

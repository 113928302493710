import styled from "styled-components";
import { CardStatusEnum } from "../../../../../types/common";
import { CardsResponse } from "../../../../api/cards/types";

export const StatusRenderer = ({ data }: { data: CardsResponse }) => {
  return (
    <StatusWrapper status={data.cardStatus}>
      <p>{CardStatusEnum[data.cardStatus]}</p>
    </StatusWrapper>
  );
};

interface statusProps {
  status: CardStatusEnum;
}

const StatusWrapper = styled.div<statusProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 13px;
  border-radius: 18px;
  border: ${(props) =>
    props.status === CardStatusEnum.Blocked
      ? "2px solid #EA3A3E"
      : props.status === CardStatusEnum.Active
      ? "2px solid #1ABA27"
      : "2px solid #FAA84F"};

  & > p {
    margin: 0;
    color: ${(props) =>
      props.status === CardStatusEnum.Blocked
        ? "#EA3A3E"
        : props.status === CardStatusEnum.Active
        ? "#1ABA27"
        : "#FAA84F"};
    font-size: 9px;
    font-weight: bold;
  }
`;

import { useEffect, useState } from "react";
import { Container, ContentWrapper } from "../Cards/selectType";
import styled from "styled-components";
import Select, { StylesConfig } from "react-select";
import { genericdropDownType } from "../../../types/common";
import { DenominationResponse } from "../../api/denomination/types";
import {
  CreateCryptoPayload,
  cryptoRatesObj,
  cryptoWalletAddressObj,
} from "../../api/crypto/types";
import { LoaderWrapper } from "../Preferences/components/preferences";
import DualRing from "../../components/loader";
import api from "../../api";
import mediaQueries from "../../../utils/mediaQueries";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { cryptoStore } from "../../redux/crypto/cryptoStore";
import { truncateTxt } from "../../../utils/data";

const ConfigureCrypto = () => {
  let navigate = useNavigate();
  const { cryptoId } = useSnapshot(cryptoStore);
  if (!cryptoId) navigate("/dashboard/crypto/add");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cryptoRatesArr, setCryptoRatesArr] = useState<cryptoRatesObj[]>([]);
  const [cryptoRate, setCryptoRate] = useState<cryptoRatesObj>();
  const [cryptoWalletAddressArr, setCryptoWalletAddressArr] = useState<
    cryptoWalletAddressObj[]
  >([]);
  const [cryptoWalletAddress, setCryptoWalletAddress] =
    useState<cryptoWalletAddressObj>();
  const [denominations, setDenominations] = useState<DenominationResponse[]>(
    []
  );

  useEffect(() => {
    async function getDominations() {
      const res = await api.denominationsService.getDenominations();
      setDenominations(res.data.data);
    }
    getDominations();
  }, []);

  const dropDownOptions = () => {
    const arr: genericdropDownType<number, string>[] = [];
    denominations.forEach((x) => {
      arr.push({ value: x?.id, label: x?.amount });
    });

    return arr;
  };

  const handleRatesAdd = () => {
    const rateToAdd: cryptoRatesObj = {
      ...cryptoRate,
      rate: Number(cryptoRate?.rate),
    };
    if (cryptoRate) setCryptoRatesArr([...cryptoRatesArr, rateToAdd]);
    setCryptoRate({ rate: "" });
  };

  const handleRateDelete = (id: number) => {
    cryptoRatesArr.splice(id, 1);
    setCryptoRatesArr([...cryptoRatesArr]);
  };

  const handleWalletAdd = () => {
    if (cryptoWalletAddress)
      setCryptoWalletAddressArr([
        ...cryptoWalletAddressArr,
        cryptoWalletAddress,
      ]);
    setCryptoWalletAddress({ address: "", network: "", tag: "" });
  };

  const handleWalletDelete = (id: number) => {
    cryptoWalletAddressArr.splice(id, 1);
    setCryptoWalletAddressArr([...cryptoWalletAddressArr]);
  };

  const isValid = true;

  const selectStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      boxShadow: "none",
      borderRadius: "0",
      paddingLeft: "10px",
      height: "45px",
      width: "150px",
      fontSize: "14px",
      cursor: "pointer",
    }),
    option: (base, { isFocused, isSelected }) => {
      return {
        ...base,
        color: "#1A2027",
        backgroundColor: isFocused || isSelected ? "#E8E8E8" : "transparent",
        "&:hover": {
          transition: "all ease-out 0.3s",
          backgroundColor: "#E8E8E8",
        },
        ":active": {
          color: "#1A2027",
        },
        cursor: "pointer",
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      paddingLeft: 0,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "400",
      paddingBottom: "10px",
    }),
    menuList: (styles) => ({
      ...styles,
      minHeight: "200px",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#000",
    }),
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const payload: CreateCryptoPayload = {
        rates: cryptoRatesArr,
        walletAddresses: cryptoWalletAddressArr,
      };
      const res = await api.cryptoService.updateCrypto(cryptoId, payload);
      if (res.data.data.id) {
        toast.success("Crypto created successfully");
        navigate("/dashboard/crypto");
      }
    } catch (error) {
      const errorMessage = error as any;
      toast.error(errorMessage?.toString() || "Crypto creation failed");
    }
    setIsSubmitting(false);
  };

  return (
    <Container>
      <h1>Create Crypto</h1>
      <AddContentWrapper>
        <NewCardWrapper>
          <h1>Add New Crypto</h1>
          <p>Configure Crypto</p>
          <BottomWrapper>
            <BottomRightContent>
              <WalletWrapper>
                <TextWrapper $grow={true}>
                  <p>Enter Wallet ID</p>
                  <input
                    type="text"
                    value={cryptoWalletAddress?.address}
                    onChange={(e) =>
                      setCryptoWalletAddress({
                        ...cryptoWalletAddress,
                        address: e.target.value,
                      })
                    }
                  />
                </TextWrapper>
                <TextWrapper>
                  <p>Enter Wallet Network</p>
                  <input
                    type="text"
                    value={cryptoWalletAddress?.network}
                    onChange={(e) =>
                      setCryptoWalletAddress({
                        ...cryptoWalletAddress,
                        network: e.target.value,
                      })
                    }
                  />
                </TextWrapper>
                <TextWrapper>
                  <p>Enter Wallet Tag</p>
                  <input
                    type="text"
                    value={cryptoWalletAddress?.tag}
                    onChange={(e) =>
                      setCryptoWalletAddress({
                        ...cryptoWalletAddress,
                        tag: e.target.value,
                      })
                    }
                  />
                </TextWrapper>
                <AddButton onClick={() => handleWalletAdd()}>Add</AddButton>
              </WalletWrapper>
              <BottomContentBox>
                <WalletContentHeader>
                  <p>Wallet ID</p>
                  <p>Wallet Network</p>
                  <p>Wallet Tag</p>
                  <span></span>
                </WalletContentHeader>
                <RateEntryWrapper>
                  {cryptoWalletAddressArr.map((x, idx) => (
                    <WalletEntry key={idx}>
                      <p>{truncateTxt(x.address, 26)}</p>
                      <p>{truncateTxt(x.network, 10)}</p>
                      <p>{truncateTxt(x.tag, 8)}</p>
                      <span onClick={() => handleWalletDelete(idx)}>x</span>
                    </WalletEntry>
                  ))}
                </RateEntryWrapper>
              </BottomContentBox>
            </BottomRightContent>
            <BottomRightContent>
              <WalletWrapper>
                <TextWrapper>
                  <p>Select Denomination</p>
                  <Select
                    options={dropDownOptions()}
                    onChange={(newValue: any) => {
                      setCryptoRate({
                        ...cryptoRate,
                        denominationId: newValue.value,
                        denominationAmount: newValue.label,
                      });
                    }}
                    styles={selectStyles}
                    menuPlacement="auto"
                    value={dropDownOptions().filter(
                      (x) => x.value === cryptoRate?.denominationId
                    )}
                    maxMenuHeight={130}
                    classNamePrefix={"rate"}
                  />
                </TextWrapper>
                <TextWrapper $grow={true}>
                  <p>Enter Rate</p>
                  <input
                    type="number"
                    value={cryptoRate?.rate}
                    onChange={(e) =>
                      setCryptoRate({
                        ...cryptoRate,
                        rate: e.target.value,
                      })
                    }
                  />
                </TextWrapper>
                <AddButton onClick={() => handleRatesAdd()}>Add</AddButton>
              </WalletWrapper>
              <BottomContentBox>
                <BottomContentHeader>
                  <p>Denomination</p>
                  <p>Rate per $</p>
                </BottomContentHeader>
                <RateEntryWrapper>
                  {cryptoRatesArr.map((x, idx) => (
                    <Entry key={idx}>
                      <p>{x.denominationAmount}</p>
                      <p>{x.rate}</p>
                      <span onClick={() => handleRateDelete(idx)}>x</span>
                    </Entry>
                  ))}
                </RateEntryWrapper>
              </BottomContentBox>
            </BottomRightContent>
          </BottomWrapper>
          <ButtonWrapper>
            <ContinueButton onClick={handleSubmit} disabled={!isValid}>
              {isSubmitting ? (
                <LoaderWrapper>
                  <DualRing width="10px" height="10px" />
                </LoaderWrapper>
              ) : (
                "Save"
              )}
            </ContinueButton>
          </ButtonWrapper>
        </NewCardWrapper>
      </AddContentWrapper>
    </Container>
  );
};

export default ConfigureCrypto;

const AddContentWrapper = styled(ContentWrapper)`
  width: 100%;
`;

export const NewCardWrapper = styled.div`
  padding: 0 50px 0;
  margin-top: 20px;

  & > h1 {
    letter-spacing: -0.24px;
    color: #faa84f;
    font-weight: 800;
    font-size: 20px;
    margin: 50px 0 15px;
  }

  & > p {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
  }

  ${mediaQueries.tablet} {
    padding: 0 10px;

    & > h1 {
      margin-top: 30px;
    }
  }

  ${mediaQueries.mobile} {
    padding: 0;

    & > h1 {
      font-size: 18px;
      margin: 20px 0 12px;
    }

    & > p {
      font-size: 15px;
    }
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 40px;

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 25px;
    margin-top: 30px;
  }
`;

const BottomRightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;

  & > p {
    letter-spacing: -0.11px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  ${mediaQueries.mobile} {
    width: 100%;

    & > p {
      font-size: 14px;
    }
  }
`;

const BottomContentBox = styled.div`
  padding: 15px;
  height: 215px;
  background: #ffffff;
  border: 1px solid #8e8e8e;
  border-radius: 5px;
`;

const BottomContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & > p {
    width: 45%;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: #727272;
  }
`;

const WalletContentHeader = styled(BottomContentHeader)`
  & > p:first-of-type {
    width: 51%;
  }

  & > p:nth-of-type(2) {
    width: 25%;
  }

  & > p:nth-of-type(3) {
    width: 19%;
  }

  & > span {
    width: 17px;
    margin-left: 4px;
  }
`;

const RateEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 155px;
  overflow-x: scroll;
`;

const Entry = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 0px;
  border-bottom: 1px solid #c3c3c3;

  & > p {
    width: 45%;
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 13px;
    font-weight: 600;
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #fdd5d5;
    color: #ef3338;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 4px;
    margin-left: 4px;
    cursor: pointer;
  }
`;

const WalletEntry = styled(Entry)`
  & > p:first-of-type {
    width: 51%;
  }

  & > p:nth-of-type(2) {
    width: 25%;
  }

  & > p:nth-of-type(3) {
    width: 19%;
  }
`;

const WalletWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const TextWrapper = styled.div<{ $grow?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 9px;
  flex-grow: ${({ $grow }) => $grow && "1"};

  & > p {
    letter-spacing: -0.11px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  & > input {
    width: 100%;
    max-width: 430px;
    height: 45px;
    background: #f7f7f7;
    padding: 0 8px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #b9b9b9;
  }
`;

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 59px;
  height: 45px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 3px;
  letter-spacing: -0.1px;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
  margin-top: 40px;

  ${mediaQueries.tablet} {
    margin-top: 30px;
  }

  ${mediaQueries.mobile} {
    margin-top: 30px;
  }
`;

export const ContinueButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 139px;
  height: 40px;
  letter-spacing: -0.13px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

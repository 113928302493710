export interface dropDownType {
  label: string;
  value: string;
}

export interface genericdropDownType<T, V> {
  label: T;
  value: V;
}

export enum ModalType {
  Confirm = 1,
  Disable,
  Cancel,
}

export interface cardRate {
  denominationId: string;
  amount?: string;
  rate?: string;
  cardRateId?: string;
}

export enum BaseCardType {
  REGULAR = 1,
  AMAZON = 2,
  SPECIAL = 3,
}

export enum CardStatusEnum {
  Pending = 1,
  Active,
  Blocked,
}

export interface HeaderDropdown {
  name: string;
  link?: string;
  action?: any;
}

export enum CardTypeEnum {
  Physical = 1,
  ECode,
}

export enum TransactionStatusEnum {
  Pending = 1,
  Declined,
  Partial,
  Approved,
}

export enum UserTransactionTypeEnum {
  Deposit = 1,
  Withdrawal,
}

export enum PerformanceEnum {
  UNKNOWN = 1,
  Today,
  Week,
  Month,
  Quarter,
  BiAnnual,
  Annual,
}

export interface AccordionObj {
  title: string;
  link: string;
}

export interface SideBarObj {
  link?: string;
  title: string;
  icon: any;
  value: string;
  accordionData?: AccordionObj[];
}

export enum WithdrawActionEnum {
  Approve = 1,
  Decline,
}

export interface chartType {
  options: {
    dataLabels: {
      enabled: boolean;
    };
    stroke: {
      curve: string;
    };
    noData: {
      text: string;
    };
    xaxis: {
      categories: string[];
    };
  };
  series: {
    name: string;
    data: number[];
  }[];
}

export interface getAndSearchObj {
  filter?: string;
  currentPage: number;
}

export interface contactPageInterface {
  label: string;
  name: string;
  url?: string;
}

export enum NotificationDisplayType {
  CARD_SALE = 1,
  WITHDRAWALS,
}

import { ReactComponent as BackgroundIcon } from "../../images/login-background.svg";
import styled from "styled-components";
import waveHand from "../../images/wave-hand.png";
import { pageFadeVariant } from "../components/animate/variants";
import { Container } from "./login/login";
import { useParams } from "react-router";
import { useEffect } from "react";
import api from "../api";
import { toast } from "react-toastify";
import mediaQueries from "../../utils/mediaQueries";

const VerifyEmail = () => {
  // const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);
  // const [isSuccessful, setIsSuccessful] = useState(false);
  const { email, token } = useParams<{ email: string; token: string }>();

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    if (token && email) {
      try {
        await api.authService.confirmEmail({
          token: token,
          email: email,
        });
        // res.data.data && setIsSuccessful(true);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
  };

  return (
    <Container
      variants={pageFadeVariant}
      initial={"initial"}
      exit={"exit"}
      animate={"animate"}
    >
      <BackgroundIcon />
      <ContentCard>
        <TopContent>
          <img src={waveHand} alt="" />
          <h1>Welcome Aboard!</h1>
          <p>Your email has been verified.</p>
          <p>You can now sign in with your new account.</p>
          {/* <LoginButton onClick={() => navigate("/login")}>
            Back to Login
          </LoginButton> */}
        </TopContent>
        <FooterContent>
          <p>Optima App - Sell Your Gift Cards</p>
          <span>Our Customer Service line: +234 801 445 6675</span>
        </FooterContent>
      </ContentCard>
    </Container>
  );
};

export default VerifyEmail;

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px 30px;
  width: 425px;
  height: 520px;
  background: #ffffff;
  z-index: 2;

  ${mediaQueries.mobile} {
    width: 85%;
    height: 450px;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 140px;
    height: 140px;
  }

  & > h1 {
    color: #faa84f;
    font-size: 18px;
    margin: 8px 0 15px;
  }

  & > p {
    color: #727272;
    font-size: 15px;
    text-align: center;
  }
`;

// const LoginButton = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #ffffff;
//   font-size: 11px;
//   font-weight: 600;
//   width: 149px;
//   height: 40px;
//   background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
//     0% no-repeat padding-box;
//   border-radius: 3px;
//   margin-top: 35px;
//   cursor: pointer;
// `;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  & > p {
    color: #000000;
    font-size: 11px;
    font-weight: 600;
  }

  & > span {
    color: #727272;
    font-size: 12px;
  }
`;

import { ColDef } from "ag-grid-community";
import { UserTransactionTypeEnum } from "../../../../../types/common";
import { formatPrice } from "../../../../../utils/formatters";
import { UserTransactionObj } from "../../../../api/users/types";
import { StatusRenderer } from "../../../Dashboard/components/gridComponents/statusRenderer";

type TransactionField = keyof UserTransactionObj | "action" | undefined;

export interface GridDef extends ColDef {
  field: TransactionField;
}

export const columnDefsDesktop = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Date",
      field: "createdOn",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1,
      valueFormatter: (params) => {
        const { data }: { data: UserTransactionObj } = params;
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
          new Date(data.createdOn)
        );
      },
    },
    {
      headerName: "Transaction ID",
      field: "transactionRef",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Transaction Type",
      field: "transactionType",
      flex: 1,
      valueFormatter: (params) => {
        const { data }: { data: UserTransactionObj } = params;
        return UserTransactionTypeEnum[data.transactionType];
      },
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Value",
      field: "amount",
      flex: 0.8,
      valueFormatter: (params) => {
        const { data }: { data: UserTransactionObj } = params;
        return formatPrice(Number(data.amount));
      },
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Status",
      sortable: false,
      field: "status",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: StatusRenderer,
    },
  ];

  return columnDefs;
};

export const columnDefsMobile = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Date",
      field: "createdOn",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 0.9,
      valueFormatter: (params) => {
        const { data }: { data: UserTransactionObj } = params;
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
          new Date(data.createdOn)
        );
      },
    },
    {
      headerName: "Type",
      field: "transactionType",
      flex: 0.8,
      valueFormatter: (params) => {
        const { data }: { data: UserTransactionObj } = params;
        return UserTransactionTypeEnum[data.transactionType];
      },
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Value",
      field: "amount",
      flex: 0.9,
      valueFormatter: (params) => {
        const { data }: { data: UserTransactionObj } = params;
        return formatPrice(Number(data.amount));
      },
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Status",
      sortable: false,
      field: "status",
      flex: 0.9,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: StatusRenderer,
    },
  ];

  return columnDefs;
};

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

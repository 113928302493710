import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";
import { toast } from "react-toastify";
import api from "../../../api";
import DualRing from "../../../components/loader";
import { splitArrayIntoChunks } from "../../../../utils/formatters";
import PermissionCheck from "./permission";
import { AdminPermissions } from "../../../../utils/data";
import { CreateAdminPayload } from "../../../api/auth/types";
import { useAppDispatch } from "../../../redux";
import { LoaderWrapper } from "../../Preferences/components/preferences";
import { dropDownType } from "../../../../types/common";
import { refreshUsers } from "../../../redux/users/userSlice";
import mediaQueries from "../../../../utils/mediaQueries";
import { useMobileCheck } from "../../../../hooks/useMobileCheck";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  isOpen: boolean;
  handleClose: () => void;
}

const initialAdminObj: CreateAdminPayload = {
  emailAddress: "",
  fullName: "",
  phoneNumber: "",
  permissions: [],
};

const CreateAdminModal: React.FC<props> = ({ isOpen, handleClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] =
    React.useState<CreateAdminPayload>(initialAdminObj);
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileCheck();

  const permissionSplitNo = isMobile ? 2 : 3;

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleCloseModal = () => {
    handleClose();
    setFormData(initialAdminObj);
  };

  const handleChangePermissions = (entry: dropDownType) => {
    const permIndex = formData.permissions.findIndex(
      (x) => x === entry.value.toUpperCase()
    );
    if (permIndex > -1) {
      formData.permissions.splice(permIndex, 1);
      setFormData({ ...formData });
    } else {
      setFormData({
        ...formData,
        permissions: [...formData.permissions, entry.value.toUpperCase()],
      });
    }
  };

  const isValid = Object.values(formData).every((x) => x !== "");

  const handleSubmit = async () => {
    if (!isValid) {
      toast.error("Please fill a fields");
      return;
    }
    if (!formData.fullName.split(" ")[1]) {
      toast.error("Please enter a last name");
      return;
    }
    setIsLoading(true);
    if (formData) {
      try {
        const res = await api.authService.createAdmin(formData);
        res?.data.data && toast.success("Admin created successfully");
        dispatch(refreshUsers(true));
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    handleCloseModal();
    setIsLoading(false);
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseModal}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="alert-dialog-slide-description"
    >
      <Title
        sx={{
          fontSize: 22,
          paddingTop: 5,
          paddingBottom: 3,
          paddingLeft: 5,
          fontFamily: "Plus Jakarta Sans",
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        Create New Admin
      </Title>
      <Content
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <DialogContentText id="alert-dialog-slide-description">
          <ContentWrapper>
            <p>Personal Information</p>
            <TopWrapper>
              <Row>
                <Entry halfWidth>
                  <p>Full Name</p>
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Entry>
                <Entry halfWidth>
                  <p>Phone Number</p>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Entry>
              </Row>
              <Entry>
                <p>Email Address</p>
                <input
                  type="text"
                  name="emailAddress"
                  value={formData.emailAddress}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Entry>
            </TopWrapper>
            <BottomWrapper>
              <h2>Admin Permissions</h2>
              <PermissionsWrapper>
                {splitArrayIntoChunks(AdminPermissions, permissionSplitNo).map(
                  (row, index) => (
                    <InnerPermissionsWrapper key={index}>
                      {row.map((x, i) => (
                        <PermissionCheck
                          entry={x}
                          key={i}
                          isChecked={formData?.permissions.includes(
                            x?.value.toUpperCase()
                          )}
                          handleClick={handleChangePermissions}
                        />
                      ))}
                    </InnerPermissionsWrapper>
                  )
                )}
              </PermissionsWrapper>
            </BottomWrapper>
          </ContentWrapper>
        </DialogContentText>
      </Content>
      <Actions
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <ButtonWrapper>
          <CancelButton onClick={() => handleCloseModal()}>Cancel</CancelButton>
          <ConfirmButton onClick={() => handleSubmit()}>
            {isLoading ? (
              <LoaderWrapper>
                <DualRing width="10px" height="10px" />
              </LoaderWrapper>
            ) : (
              "Create Admin"
            )}
          </ConfirmButton>
        </ButtonWrapper>
      </Actions>
    </Dialog>
  );
};

export default CreateAdminModal;

const Title = styled(DialogTitle)`
  ${mediaQueries.mobile} {
    padding: 20px 15px 10px !important;
  }
`;

const Content = styled(DialogContent)`
  ${mediaQueries.mobile} {
    padding: 0 20px !important;
  }
`;

const Actions = styled(DialogActions)`
  ${mediaQueries.mobile} {
    padding: 0 20px !important;
  }
`;

const PermissionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const InnerPermissionsWrapper = styled.div`
  display: flex;

  & div {
    font-size: 12px;
    color: #000000;
    width: 46%;
    padding-top: 4px;
  }

  ${mediaQueries.mobile} {
    flex-wrap: wrap;
    gap: 30px 18px;

    & div {
      font-size: 13px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding: 20px 0px 15px;
  border-top: 1px solid #c3c3c3;

  ${mediaQueries.mobile} {
    border-top: none;
    padding-top: 50px;
  }
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  letter-spacing: -0.2px;
  font-weight: bold;
  font-size: 12px;
  color: #727272;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    color: #4d4b4b;
  }
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  background: #1aba27;
  letter-spacing: -0.22px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;

  & > div {
    margin-bottom: 4px;
    margin-right: 4px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > p {
    letter-spacing: -0.1px;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
  }

  ${mediaQueries.mobile} {
    & > p {
      font-size: 14px;
    }
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 20px;
  }
`;

interface entryProps {
  halfWidth?: boolean;
}

const Entry = styled.div<entryProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: ${(props) => (props.halfWidth ? "45%" : "100%")};

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    width: 100px;
    flex-shrink: 0;
  }

  & > input {
    padding-left: 10px;
    height: 35px;
    background: #ffffff;
    border: 0.5px solid #707070;
    border-radius: 3px;
    width: 100%;
    outline: none;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 15px;
  padding-top: 30px;
  border-top: 0.25px solid #c7c7c7;

  & > h2 {
    letter-spacing: -0.13px;
    color: #727272;
    font-size: 12px;
    font-weight: 600;
  }

  ${mediaQueries.mobile} {
    margin-top: 0;
    padding-top: 20px;
    gap: 20px;

    & > h2 {
      font-size: 14px;
    }
  }
`;

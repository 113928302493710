import { createAsyncThunk } from "@reduxjs/toolkit";
import storage from "../../../utils/storage";
import api from "../../api";
import { LoginPayload, RefreshTokenPayload } from "../../api/auth/types";
import { resetCards } from "../cards/cardSlice";
import { resetNotifications } from "../notifications/notificationSlice";
import { resetUsers } from "../users/userSlice";
import { resetAuth } from "./authSlice";
import { TokenState } from "./types";

export const setToken = createAsyncThunk(
  "auth/setToken",
  async (payload: TokenState) => {
    storage.set("optimaUser", { ...payload });
    return payload;
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const response = await api.authService.login(payload);
      const { token, permissions, roles, refreshToken } = response.data.data;
      thunkAPI.dispatch(
        setToken({
          accessToken: token,
          permissions,
          roles,
          refreshToken,
        })
      );
      return response.data.data;
    } catch (error) {
      let errorMessage = error as any;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "auth/refreshtoken",
  async (payload: RefreshTokenPayload, thunkAPI) => {
    setTimeout(() => {
      storage.set("isRefreshTokenCalled", false);
    }, 2000);
    try {
      const response = await api.authService.refreshToken(payload);
      const { refreshToken, token } = response.data.data;
      const userData = storage.get("optimaUser");
      thunkAPI.dispatch(
        setToken({
          accessToken: token,
          refreshToken,
          permissions: userData?.permissions,
          roles: userData?.roles,
        })
      );
      return token;
    } catch (error) {
      thunkAPI.dispatch(logout());

      let errorMessage = error as any;
      errorMessage =
        errorMessage?.responseMessage ||
        errorMessage?.errors[0] ||
        errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "auth/getUserProfile",
  async (payload, thunkAPI) => {
    try {
      const response = await api.authService.getProfile();
      return response.data.data;
    } catch (error) {
      let errorMessage = error as any;
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (payload, thunkAPI) => {
    storage.remove("optimaUser");
    thunkAPI.dispatch(resetAuth());
    thunkAPI.dispatch(resetCards());
    thunkAPI.dispatch(resetUsers());
    thunkAPI.dispatch(resetNotifications());
    return true;
  }
);

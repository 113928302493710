import { ColDef } from "ag-grid-community";
import { ProfileResponse } from "../../../../api/auth/types";
import ActionRenderer from "./actionRenderer";
import NameRenderer from "./nameRenderer";
import { StatusRenderer } from "./statusRenderer";

type AdminsField = keyof ProfileResponse | "action" | "sn" | undefined;

export interface AdminGridDef extends ColDef {
  field: AdminsField;
}

export const columnDefsDesktop = () => {
  const columnDefs: AdminGridDef[] = [
    {
      headerName: "Sn",
      field: "sn",
      sortable: false,
      valueGetter: "node.rowIndex + 1",
      flex: 0.3,
    },
    {
      headerName: "Date Created",
      field: "dateCreated",
      sortable: false,
      flex: 1.2,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      valueFormatter: (params) => {
        const { data }: { data: ProfileResponse } = params;
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
          new Date(data.dateCreated)
        );
      },
    },
    {
      headerName: "Full Name",
      field: "name",
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1.2,
      cellRendererFramework: NameRenderer,
    },
    {
      headerName: "Email Address",
      field: "emailAddress",
      flex: 1.2,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Status",
      sortable: false,
      field: "verified",
      flex: 0.6,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Action",
      sortable: false,
      field: "active",
      flex: 1.4,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

export const columnDefsTablet = () => {
  const columnDefs: AdminGridDef[] = [
    {
      headerName: "Sn",
      field: "sn",
      sortable: false,
      valueGetter: "node.rowIndex + 1",
      flex: 0.3,
    },
    {
      headerName: "Date Created",
      field: "dateCreated",
      sortable: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      valueFormatter: (params) => {
        const { data }: { data: ProfileResponse } = params;
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
          new Date(data.dateCreated)
        );
      },
    },
    {
      headerName: "Full Name",
      field: "name",
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1.2,
      cellRendererFramework: NameRenderer,
    },
    {
      headerName: "Email Address",
      field: "emailAddress",
      flex: 1.4,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Action",
      sortable: false,
      field: "active",
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

export const columnDefsMobile = () => {
  const columnDefs: AdminGridDef[] = [
    {
      headerName: "Sn",
      field: "sn",
      sortable: false,
      valueGetter: "node.rowIndex + 1",
      flex: 0.3,
    },
    {
      headerName: "Full Name",
      field: "name",
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1.2,
      cellRendererFramework: NameRenderer,
    },
    {
      headerName: "Action",
      sortable: false,
      field: "active",
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

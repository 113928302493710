import { useEffect, useState } from "react";
import styled from "styled-components";
import { dropDownType } from "../../../../types/common";
import mediaQueries from "../../../../utils/mediaQueries";
import ApexChart from "../../../components/apexChart";
import { CustomDropdown } from "../../../components/CustomDropdown";

const CardSalesGraph = () => {
  const [dropDownValue, setDropDownValue] = useState<string | null>();

  const getDropdownOptions = () => {
    const currentYear = new Date().getFullYear();
    const arrof3 = [];
    for (let i = 0; i < 3; i++) {
      arrof3.push((Number(currentYear) - i).toString());
    }
    return arrof3.map((x) => ({
      label: x,
      value: x,
    })) as dropDownType[];
  };

  const dropDownOptions = getDropdownOptions();

  useEffect(() => {
    !dropDownValue && setDropDownValue(dropDownOptions[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownValue]);

  const handleChange = (value: string | null) => {
    setDropDownValue(value);
  };

  return (
    <Container>
      <TopWrapper>
        <p>Cards Sales</p>
        <CustomDropdown options={dropDownOptions} onChange={handleChange} />
      </TopWrapper>
      <BottomWrapper>
        <ApexChart dropDownValue={dropDownValue} />
      </BottomWrapper>
    </Container>
  );
};

export default CardSalesGraph;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;

  & > p {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.18px;
    color: #000000;
  }

  & > span {
    font-size: 16px;
    letter-spacing: -0.16px;
    color: #727272;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: #000000;
    }
  }
`;

export const BottomWrapper = styled.div`
  width: 100%;
  height: 360px;
  padding: 10px;
  background: #ffffff;
  overflow: hidden;

  ${mediaQueries.mobile} {
    padding: 0;
  }
`;

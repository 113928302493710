import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CryptosState } from "./types";
import { getActiveCryptos, getDisabledCryptos } from "./actions";
import { RootState } from "..";

const initialState: CryptosState = {
  activeCryptos: {
    error: null,
    pending: false,
  },
  disabledCryptos: {
    error: null,
    pending: false,
  },
  refreshNeeded: false,
};

const cryptosSlice = createSlice({
  name: "cryptos",
  initialState,
  reducers: {
    refreshNeeded: (state, action: PayloadAction<boolean>) => {
      state.refreshNeeded = action.payload;
    },
    resetCrypos: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getActiveCryptos.pending, (state) => {
        return {
          ...state,
          activeCryptos: {
            ...state.activeCryptos,
            pending: true,
            error: null,
            response: undefined,
          },
        };
      })
      .addCase(getActiveCryptos.fulfilled, (state, { payload }) => {
        return {
          ...state,
          activeCryptos: {
            ...state.activeCryptos,
            pending: false,
            error: null,
            response: payload,
          },
        };
      })
      .addCase(getActiveCryptos.rejected, (state, action) => {
        return {
          ...state,
          activeCryptos: {
            ...state.activeCryptos,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
    builder
      .addCase(getDisabledCryptos.pending, (state) => {
        return {
          ...state,
          disabledCryptos: {
            ...state.disabledCryptos,
            pending: true,
            error: null,
            response: undefined,
          },
        };
      })
      .addCase(getDisabledCryptos.fulfilled, (state, { payload }) => {
        return {
          ...state,
          disabledCryptos: {
            ...state.disabledCryptos,
            pending: false,
            error: null,
            response: payload,
          },
        };
      })
      .addCase(getDisabledCryptos.rejected, (state, action) => {
        return {
          ...state,
          disabledCryptos: {
            ...state.disabledCryptos,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
  },
});

export const { refreshNeeded, resetCrypos: resetCards } = cryptosSlice.actions;

export const activeCryptoselector = (state: RootState) =>
  state.crypto.activeCryptos;
export const disabledCryptoselector = (state: RootState) =>
  state.crypto.disabledCryptos;
export const refreshCryptoSelector = (state: RootState) =>
  state.crypto.refreshNeeded;

export default cryptosSlice.reducer;

import { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as NotificationIcon } from "../../../images/Notification.svg";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getAuth } from "../../redux/auth/authSlice";
import * as signalR from "@microsoft/signalr";
import CustomizedSnackbars from "./popUp";
import DropDown from "./dropDown";
import Fade from "../animate/Fade";
import { ClickAwayListener } from "@mui/base";
import { getNotifications } from "../../redux/notifications/actions";
import mediaQueries from "../../../utils/mediaQueries";

const Notification = () => {
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isShowingRedBall, setIsShowingRedBall] = useState(false);
  const { accessToken } = useAppSelector(getAuth);
  const dispatch = useAppDispatch();

  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (accessToken) {
      let cardSaleConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${baseURL}/Notification`, {
          accessTokenFactory: () => accessToken,
        })
        .build();

      cardSaleConnection.on("SendCardSaleNotification", (data) => {
        handleNewRTC();
      });
      let cardWithdrawalConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${baseURL}/Notification`, {
          accessTokenFactory: () => accessToken,
        })
        .build();

      cardWithdrawalConnection.on(
        "SendWithdrawalRequestNotification",
        (data) => {
          handleNewRTC();
        }
      );

      cardSaleConnection
        .start()
        .then(() => console.log("card sale connection started"));
      cardWithdrawalConnection
        .start()
        .then(() => console.log("card withdrawal connection started"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    dispatch(getNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewRTC = () => {
    dispatch(getNotifications());
    setIsShowingRedBall(true);
    setIsOpenSnackbar(true);
  };

  const handleToggleDropdown = () => {
    setIsOpenDropDown((prev) => !prev);
    setIsShowingRedBall(false);
  };

  const handleClickAway = () => {
    setIsOpenDropDown(false);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpenSnackbar(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <IconWrapper>
        <IconBackground onClick={() => handleToggleDropdown()}>
          <NotificationIcon />
          {isShowingRedBall && <RedBall />}
        </IconBackground>
        <Fade isOpen={isOpenDropDown}>
          <DropDown onClose={handleClickAway} />
        </Fade>
        <CustomizedSnackbars handleClose={handleClose} open={isOpenSnackbar} />
      </IconWrapper>
    </ClickAwayListener>
  );
};

export default Notification;

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const IconBackground = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f5f7f8;
  border: 0.5px solid #d1d1d1;
  cursor: pointer;

  & > svg {
    width: 17px;
    height: 18px;
  }

  ${mediaQueries.mobile} {
    width: 30px;
    height: 30px;

    & > svg {
      width: 14px;
      height: 15px;
    }
  }
`;

const RedBall = styled.span`
  position: absolute;
  top: 4px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ed3838;
`;

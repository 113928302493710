import { AxiosError } from "axios";

export const parseError = (err: AxiosError | unknown) => {
  let errorMessage;
  const error = err as unknown as AxiosError;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorMessage = error.response.data;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // errorMessage = error.request;
    errorMessage = "An unexpected error occured. Please, try again.";
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
  }
  if (error.response?.status === 500) {
    errorMessage = "An unexpected error occured. Please, try again.";
  }
  return errorMessage;
};

export const parseErrorAsObject = (error: AxiosError) => {
  const parsedError = parseError(error);

  let response: any = {};

  if (parsedError.errors) {
    response.errors = parsedError.errors;
    response.message = "Validation not successful";

    return response;
  }

  response.message = parsedError.message || parsedError.error || parsedError;

  return response;
};

export const getErrorMessage = (error: any): string => {
  let parsedError = parseError(error);

  if (parsedError.errors && parsedError.errors.length > 0) {
    return parsedError.errors.join(", ");
  }

  return parsedError.message || parsedError.error || parsedError;
};

export default parseError;

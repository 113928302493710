import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../../images/Search.svg";
import mediaQueries from "../../../utils/mediaQueries";
import { UsersResponse } from "../../api/users/types";
import DualRing from "../../components/loader";
import Paginator from "../../components/Paginator";
import { useAppDispatch, useAppSelector } from "../../redux";
import {
  getActiveUsers,
  getDisabledUsers,
  getInactiveUsers,
} from "../../redux/users/actions";
import {
  activeUserSelector,
  disabledUserSelector,
  inactiveUserSelector,
  refreshUsers,
  refreshUsersSelector,
} from "../../redux/users/userSlice";
import { MainLoaderWrapper } from "../Preferences/components/denominations";
import ActiveGrid from "./components/activeGrid/grid";
import DisabledGrid from "./components/disabledGrid/grid";
import PendingGrid from "./components/pendingGrid/grid";
import { utilityStore } from "../../redux/utilityStore";
import { useSnapshot } from "valtio";

const Users = () => {
  const utilitySnap = useSnapshot(utilityStore);
  const [tabValue, setTabValue] = useState<number>(1);
  const [searchValue, setsearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(utilitySnap.currentPage);
  const [totalCount, setTotalCount] = useState(1);
  const [gridData, setGridData] = useState<UsersResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const shouldRefresh = useAppSelector(refreshUsersSelector);

  const {
    error: activeError,
    pending: activePending,
    response: activeUser,
  } = useAppSelector(activeUserSelector);
  const {
    error: inactiveError,
    pending: inactivePending,
    response: inactiveUser,
  } = useAppSelector(inactiveUserSelector);
  const {
    error: disabledError,
    pending: disabledPending,
    response: disableduser,
  } = useAppSelector(disabledUserSelector);

  const getUsers = (currentPage: number, filter?: string) => {
    switch (tabValue) {
      case 1:
        dispatch(getActiveUsers({ currentPage, filter }));
        break;
      case 2:
        dispatch(getInactiveUsers({ currentPage, filter }));
        break;
      case 3:
        dispatch(getDisabledUsers({ currentPage, filter }));
        break;

      default:
        break;
    }
    dispatch(refreshUsers(false));
  };

  useEffect(() => {
    getUsers(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, currentPage]);

  useEffect(() => {
    let dataToDisplay;
    switch (tabValue) {
      case 1:
        dataToDisplay = activeUser;
        break;
      case 2:
        dataToDisplay = inactiveUser;
        break;
      case 3:
        dataToDisplay = disableduser;
        break;

      default:
        break;
    }

    setGridData(dataToDisplay?.data);
    let count = dataToDisplay?.totalCount / 10;
    count =
      Math.floor(count) < count ? Math.floor(count) + 1 : Math.floor(count);
    setTotalCount(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser, inactiveUser, disableduser]);

  useEffect(() => {
    setTimeout(() => {
      shouldRefresh && (currentPage === 1 ? getUsers(1) : setCurrentPage(1));
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh]);

  useEffect(() => {
    setIsLoading(activePending || inactivePending || disabledPending);
  }, [activePending, inactivePending, disabledPending]);

  useEffect(() => {
    toast.error(activeError || inactiveError || disabledError);
  }, [activeError, inactiveError, disabledError]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    utilityStore.currentPage = value;
  };

  const handleChangeIndex = (index: number) => {
    setCurrentPage(1);
    setTabValue(index);
    setsearchValue("");
  };

  const handleSearch = () => {
    getUsers(currentPage, searchValue);
    setsearchValue("");
  };

  return (
    <Container>
      <h1>Manage Users</h1>
      <ContentWrapper>
        <TopWrapper>
          <TabWrapper>
            <Tab onClick={() => handleChangeIndex(1)} active={tabValue === 1}>
              Active
            </Tab>
            <Tab onClick={() => handleChangeIndex(2)} active={tabValue === 2}>
              Pending
            </Tab>
            <Tab onClick={() => handleChangeIndex(3)} active={tabValue === 3}>
              Disabled
            </Tab>
          </TabWrapper>
          <SearchWrapper>
            <input
              type="text"
              placeholder="Search Users"
              value={searchValue}
              onChange={(e) => setsearchValue(e.target.value)}
            />
            <SearchIcon />
            <SearchButton onClick={() => handleSearch()}>Search</SearchButton>
          </SearchWrapper>
        </TopWrapper>
        {isLoading ? (
          <MainLoaderWrapper height="545px">
            <DualRing width="30px" height="30px" color="#727272" />
          </MainLoaderWrapper>
        ) : (
          <BottomWrapper>
            {tabValue === 1 && <ActiveGrid data={gridData} />}
            {tabValue === 2 && <PendingGrid data={gridData} />}
            {tabValue === 3 && <DisabledGrid data={gridData} />}
            {gridData?.length > 0 && (
              <Paginator
                total={totalCount}
                currentPage={currentPage}
                handleChange={handleChange}
              />
            )}
          </BottomWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default Users;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 35px 20px;
  width: 100%;
  margin-top: 90px;

  & > h1 {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }

  ${mediaQueries.mobileAndTablet} {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding: 20px;

  ${mediaQueries.mobile} {
    padding: 0 0 10px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;

  ${mediaQueries.mobile} {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

interface tabProps {
  active: boolean;
}

const Tab = styled.div<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? "#FAA84F" : "#727272")};
  font-size: 12px;
  font-weight: bold;
  padding: ${(props) =>
    props.active ? "15px 20px 10px 20px" : "15px 20px 15px 20px"};
  border-bottom: ${(props) => (props.active ? "5px solid #FAA84F" : "none")};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 379px;
  height: 38px;

  & > input {
    width: 100%;
    height: 100%;
    padding-left: 45px;
    background: #f5f7f8;
    border-radius: 20px;
    border: none;
    outline: none;

    &::placeholder {
      color: #9a9a9a;
      font-weight: 600;
    }
  }

  & > svg {
    position: absolute;
    top: 14px;
    left: 18px;
    width: 12px;
    height: 13px;
  }

  ${mediaQueries.tablet} {
    width: 330px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    padding: 10px;
    height: auto;

    & > svg {
      top: 22px;
    }

    & > input {
      height: 30px;
    }
  }
`;

export const SearchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 35px;
  color: #ffffff;
  font-size: 11px;
  background: transparent linear-gradient(169deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  cursor: pointer;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 530px;
  margin-top: 15px;
`;

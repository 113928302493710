import React, { useState } from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";

interface props {
  onChange: (name: string, value: string) => void;
  name: string;
  value: string;
}

const StyledPasswordInput: React.FC<props> = ({ name, onChange, value }) => {
  const [isShowing, setIsShowing] = useState(false);

  const handleShowToggle = () => {
    setIsShowing((prev) => !prev);
  };
  return (
    <PasswordInputWrapper>
      <PasswordInput
        type={isShowing ? "text" : "password"}
        value={value}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        name={name}
      />
      <ShowButton onClick={() => handleShowToggle()}>
        {isShowing ? "Hide" : "Show"}
      </ShowButton>
    </PasswordInputWrapper>
  );
};

export default StyledPasswordInput;

const PasswordInputWrapper = styled.div`
  position: relative;

  ${mediaQueries.mobile} {
    flex-grow: 1;
  }
`;

export const PasswordInput = styled.input`
  width: 346px;
  height: 37px;
  padding-left: 12px;
  color: #000000;
  font-size: 12px;
  background: #ffffff;
  border: 0.5px solid #dbdbdb;
  border-radius: 5px;
  outline: none;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const ShowButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 16px;
  color: #faa84f;
  font-weight: bold;
  font-size: 10px;
  background: #faa84f1a;
  border: 0.5px solid #faa84f;
  border-radius: 2px;
  cursor: pointer;
`;

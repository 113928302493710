import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { toast } from "react-toastify";
import styled from "styled-components";
import { chartType } from "../../types/common";
import api from "../api";
import { useAppSelector } from "../redux";
import { getAuth } from "../redux/auth/authSlice";
import { MainLoaderWrapper } from "../views/Preferences/components/denominations";
import DualRing from "./loader";

const initialChartData: chartType = {
  options: {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    noData: {
      text: "Loading...",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  },
  series: [
    {
      name: "sales",
      data: [],
    },
  ],
};

interface props {
  dropDownValue?: string | null;
}

const ApexChart: React.FC<props> = ({ dropDownValue }) => {
  const [series, setSeries] = useState(initialChartData.series);
  const [isLoading, setIsLoading] = useState(false);

  const {
    profile: { response },
  } = useAppSelector(getAuth);

  const getGraphData = async () => {
    if (!isNaN(Number(dropDownValue))) {
      setIsLoading(true);
      try {
        const res = await api.dashboardService.getDashboardGraphData(
          Number(dropDownValue)
        );
        const tempArr = [
          {
            name: "sales",
            data: Object.values(res.data.data),
          },
        ];
        setSeries(tempArr);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      response?.permissions.includes("DASHBOARD") ||
      (response?.roles &&
        response?.roles.length > 0 &&
        response?.roles[0].toLowerCase() === "superadmin")
    ) {
      getGraphData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownValue, response]);

  return (
    <Container>
      {isLoading ? (
        <MainLoaderWrapper>
          <DualRing width="30px" height="30px" color="#727272" />
        </MainLoaderWrapper>
      ) : (
        <Chart
          series={series}
          height={340}
          options={initialChartData.options}
          type={"area"}
        />
      )}
    </Container>
  );
};

export default ApexChart;

const Container = styled.div`
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

import React from "react";
import styled from "styled-components";
import { dropDownType } from "../../../../types/common";
import mediaQueries from "../../../../utils/mediaQueries";

interface props {
  handleClick: (data: dropDownType) => void;
  entry: dropDownType;
  isChecked?: boolean;
}

const PermissionCheck: React.FC<props> = ({
  entry,
  isChecked,
  handleClick,
}) => {
  return (
    <Container onClick={() => handleClick(entry)}>
      <input type="checkbox" checked={isChecked} />
      <span></span>
      {entry?.label}
    </Container>
  );
};

export default PermissionCheck;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 45px;
  width: 25%;
  letter-spacing: -0.18px;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    background-color: #eee;
  }

  &:hover input ~ span {
    background-color: #ccc;
  }

  & input:checked ~ span {
    background-color: #faa84f;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ span:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & span:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  ${mediaQueries.mobile} {
    width: 47%;
    font-size: 15px;
    padding-left: 36px;

    & > span {
      top: -1px;
      width: 23px;
      height: 23px;
    }

    & span:after {
      left: 8px;
      top: 3px;
      width: 4px;
      height: 10px;
    }
  }
`;

export const formatPrice = (price: number | string = "0") => {
  return price === "0"
    ? `***`
    : `₦${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const splitArrayIntoChunks = (arr: any[], size: number) => {
  const newArr = [...arr];
  const resultArr: any[][] = [];
  while (newArr.length) {
    const tempObj = newArr.splice(0, size);
    resultArr.push(tempObj);
  }
  return resultArr;
};

export const handleGetInitials = (name: string) => {
  return name
    .split(" ")
    .map((x) => x.substring(0, 1).toUpperCase())
    .join("");
};

import { TransactionStatusEnum } from "../../../types/common";
import { ApiResponse } from "../../redux/types";
import request from "../request";
import {
  TransactionActionPayload,
  transactionDetailsResponse,
  transactionResponse,
  WithdrawalActionPayload,
  WithdrawalDetailsResponse,
  WithdrawalResponse,
} from "./types";

const getApprovedTransactions = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<transactionResponse[]>>(
    keyword
      ? `/CardSale/Approved?Filter=customername&Keyword=${keyword}`
      : `/CardSale/Approved?pageSize=10&pageIndex=${pageIndex}`
  );
};
const getPendingTransactions = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<transactionResponse[]>>(
    keyword
      ? `/CardSale/Pending?Filter=customername&Keyword=${keyword}`
      : `/CardSale/Pending?pageSize=10&pageIndex=${pageIndex}`
  );
};
const getDeclinedTransactions = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<transactionResponse[]>>(
    keyword
      ? `/CardSale/Declined?Filter=customername&Keyword=${keyword}`
      : `/CardSale/Declined?pageSize=10&pageIndex=${pageIndex}`
  );
};
const getTransactionDetails = async (id: string) => {
  return await request.get<ApiResponse<transactionDetailsResponse>>(
    `/CardSale/Get/${id}`
  );
};
const getWithdrawals = async (
  status: TransactionStatusEnum,
  pageIndex: number,
  keyword?: string
) => {
  return await request.get<ApiResponse<WithdrawalResponse[]>>(
    keyword
      ? `/Transaction/Withdrawals?status=${status}&Filter=customername&Keyword=${keyword}`
      : `/Transaction/Withdrawals?status=${status}&pageSize=10&pageIndex=${pageIndex}`
  );
};

const getWithdrawalDetails = async (id: string) => {
  return await request.get<ApiResponse<WithdrawalDetailsResponse>>(
    `/Transaction/WithdrawalRequest?id=${id}`
  );
};

const withdrawalAction = async (
  id: string,
  payload: WithdrawalActionPayload
) => {
  return await request.put<ApiResponse<boolean>>(
    `/Transaction/Action/${id}`,
    payload
  );
};

const action = async (id: string, payload: TransactionActionPayload) => {
  const { amount, comment, transactionStatus } = payload;

  return await request.put<ApiResponse<boolean>>(
    `/CardSale/Action/${id}`,
    {},
    {
      headers: {
        amount: amount as string,
        comment: comment as string,
        transactionStatus: String(transactionStatus),
      },
    }
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getApprovedTransactions,
  getPendingTransactions,
  getDeclinedTransactions,
  getTransactionDetails,
  getWithdrawals,
  getWithdrawalDetails,
  withdrawalAction,
  action,
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as RightArrow } from "../../../images/right-arrow.svg";
import CardSalesGraph from "./components/cardSalesGraph";
import ActivityCard from "./components/activityCard";
import AdminCard from "./components/adminCard";
import PerformanceCard from "./components/performanceCard";
import { toast } from "react-toastify";
import api from "../../api";
import {
  AvailableToPayRes,
  DashboardResponse,
} from "../../api/dashboard/types";
import { useNavigate } from "react-router";
import { formatPrice } from "../../../utils/formatters";
import mediaQueries from "../../../utils/mediaQueries";
import { useAppSelector } from "../../redux";
import { getAuth } from "../../redux/auth/authSlice";

interface Props {}

const Dashboard: React.FC<Props> = () => {
  const [formData, setformData] = useState<DashboardResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardFinancials, setDashboardFinancials] =
    useState<AvailableToPayRes>();
  const navigate = useNavigate();

  const {
    profile: { response },
  } = useAppSelector(getAuth);

  const getDashboardData = async () => {
    setIsLoading(true);
    try {
      const res = await api.dashboardService.getDashboardData();
      setformData(res.data.data);
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  };

  const getDashboardFinancials = async () => {
    try {
      const res = await api.dashboardService.getAvailableToPay();
      setDashboardFinancials(res.data.data);
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (
      response?.permissions.includes("DASHBOARD") ||
      (response?.roles &&
        response?.roles.length > 0 &&
        response?.roles[0].toLowerCase() === "superadmin")
    ) {
      getDashboardData();
      getDashboardFinancials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <Container>
      <LeftContent>
        <TopLeftWrapper>
          <CardWrapper>
            <PerformanceCard />
            <RightCardWrapper>
              <CardInnerColumn>
                <p>Available to payout</p>
                <div>
                  <p>{formatPrice(dashboardFinancials?.availableToPayOut)}</p>
                  <span>Just sitting there…</span>
                </div>
              </CardInnerColumn>
              <CardInnerColumn>
                <p>Pending Transactions</p>
                <div>
                  <ViewAllWrapper>
                    <p>{dashboardFinancials?.pendingTransaction || "***"}</p>
                    <div
                      onClick={() => navigate("/dashboard/transactions/sales")}
                    >
                      <p>View All</p>
                      <RightArrow />
                    </div>
                  </ViewAllWrapper>{" "}
                  <span>Transactions need your attention.</span>
                </div>
              </CardInnerColumn>
            </RightCardWrapper>
          </CardWrapper>
        </TopLeftWrapper>
        <BottomLeftWrapper>
          <BottomCardsWrapper>
            <CardSalesGraph />
          </BottomCardsWrapper>
          <BottomCardsWrapper>
            <ActivityCard
              data={formData?.recentActivities || []}
              isLoading={isLoading}
            />
          </BottomCardsWrapper>
        </BottomLeftWrapper>
      </LeftContent>
      <RightContent>
        <TopRightWrapper>
          <TopHeadContent>
            <p>Total Users</p>
          </TopHeadContent>
          <UserCountBox>
            <p>{formData?.totalUserCount || 0}</p>
            <div onClick={() => navigate("/dashboard/users")}>View All</div>
          </UserCountBox>
        </TopRightWrapper>
        <BottomRightWrapper>
          <TopHeadContent>
            <p>Your Admins</p>
            <p onClick={() => navigate("/dashboard/admins")}>See All</p>
          </TopHeadContent>
          <AdminCardWrapper>
            {formData?.adminUserDTOs && formData.adminUserDTOs.length > 0 ? (
              formData?.adminUserDTOs.map((x, i) => (
                <AdminCard
                  name={x.fullName}
                  role={x.userType}
                  lastSeen={x.lastLoginDate}
                  key={i}
                />
              ))
            ) : (
              <EmptyTxt>You have no admins</EmptyTxt>
            )}
          </AdminCardWrapper>
        </BottomRightWrapper>
      </RightContent>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 90px;

  ${mediaQueries.tablet} {
    flex-direction: column;
    margin-top: 90px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    margin-top: 70px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  padding: 40px 35px;

  ${mediaQueries.tablet} {
    width: 100%;
    padding: 0;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    padding: 0;
  }
`;

const TopLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BottomLeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;

  ${mediaQueries.mobile} {
    flex-direction: column;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 20px;
  }
`;

const RightCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  height: 130px;
  width: 55%;
  background: #faa84f1a;
  border-radius: 10px;
  align-self: flex-end;

  ${mediaQueries.mobile} {
    width: 100%;
    padding: 0;
    box-shadow: 0 0 0 100vmax #faa84f1a;
    clip-path: inset(0 -100vmax);
  }
`;

const CardInnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > p {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.14px;
    color: #727272;
    margin: 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    & > p {
      font-size: 28px;
      font-weight: 600;
      letter-spacing: -1.9px;
      color: #000000;
    }

    & > span {
      color: #000000;
      font-size: 10px;
    }
  }

  ${mediaQueries.smallDesktop} {
    & > div {
      height: 45px;
      & > p {
        font-size: 24px;
      }
    }
  }

  ${mediaQueries.tablet} {
    & > p {
      font-size: 14px;
    }

    & > div {
      & > p {
        font-size: 24px;
      }
    }
  }

  ${mediaQueries.mobile} {
    & > p {
      font-size: 14px;
    }

    & > div {
      & > p {
        font-size: 21px;
      }
    }
  }
`;

const ViewAllWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -1.9px;
    color: #000000;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    height: 31px;
    border: 1.5px solid var(--unnamed-color-727272);
    border: 2px solid #727272;
    border-radius: 5px;
    cursor: pointer;

    & > p {
      color: #727272;
      font-size: 10px;
      font-weight: 600;
    }

    & > svg {
      width: 10px;
      height: 9px;
      margin-top: 1px;
    }
  }

  ${mediaQueries.smallDesktop} {
    & > p {
      font-size: 24px;
    }
  }

  ${mediaQueries.tablet} {
    & > p {
      font-size: 24px;
    }

    & > div {
      height: 27px;
      padding: 2px 8px;
    }
  }

  ${mediaQueries.mobile} {
    & > p {
      font-size: 20px;
    }

    & > div {
      height: 27px;
      padding: 2px 8px;
    }
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 65px;
  padding: 35px 30px;
  min-height: calc(100vh - 90px);
  width: 25%;
  background: #ffffff;
  border: 1px solid #e3e3e3;

  ${mediaQueries.tablet} {
    gap: 50px;
    width: 100%;
    padding: 10px 10px 20px;
    border: none;
    min-height: unset;
    margin-top: 30px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    padding: 10px 10px 20px;
    border: none;
    min-height: unset;
  }
`;

const BottomCardsWrapper = styled.div`
  width: 48%;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const TopRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TopHeadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p:first-of-type {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }

  & > p:last-of-type {
    letter-spacing: -0.16px;
    color: #faa84f;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: #ff8400;
    }
  }
`;

const UserCountBox = styled.div`
  height: 219px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 30px;
  padding: 30px 20px 20px;

  & > p {
    letter-spacing: -5px;
    color: #ffffff;
    font-size: 100px;
    font-weight: 400;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    letter-spacing: -0.12px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    width: 90px;
    height: 34px;
    border: 1.5px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
  }

  ${mediaQueries.mobile} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 130px;
    padding: 15px 20px;

    & > p {
      font-size: 60px;
    }
  }
`;

const BottomRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const AdminCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 320px;
  overflow-y: scroll;
`;

const EmptyTxt = styled.p`
  margin-top: 70px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

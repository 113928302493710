import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyle } from "../styles/global-styles";
import Routes from "./routes";
import ScrollToTop from "./routes/scrollToTop";

const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <ToastContainer
        newestOnTop={true}
        closeButton={false}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnHover={true}
        limit={1}
        rtl={false}
        position="bottom-right"
      />
      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;

import styled from "styled-components";
import { UsersResponse } from "../../../../../api/users/types";

export const StatusRenderer = ({ data }: { data: UsersResponse }) => {
  return (
    <StatusWrapper>
      <p>Disabled</p>
    </StatusWrapper>
  );
};

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 13px;
  border-radius: 18px;
  border: 2px solid #ed3838;

  & > p {
    margin: 0;
    color: #ed3838;
    font-size: 9px;
    font-weight: bold;
  }
`;

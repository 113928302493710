import _ from "lodash";
import React, { MouseEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Select, { ActionMeta } from "react-select";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";
import { CardLeft, CardWrapper } from ".";
import {
  cardRate,
  CardTypeEnum,
  dropDownType,
  genericdropDownType,
} from "../../../../types/common";
import { defaultGuid } from "../../../../utils/data";
import mediaQueries from "../../../../utils/mediaQueries";
import api from "../../../api";
import {
  CardRateDenominationObj,
  CardRateDenominationPayload,
  CardResponseEdit,
  EditReceiptTypeObj,
  EditReceiptTypePayload,
  ReceiptResponse,
} from "../../../api/cards/types";
import { CountriesResponse } from "../../../api/countries/types";
import { DenominationResponse } from "../../../api/denomination/types";
import CancelModal from "../../../components/cancelModal";
import DualRing from "../../../components/loader";
import { LoaderWrapper } from "../../Preferences/components/preferences";
import Passport from "../components/passport";
import { ButtonWrapper, CancelButton, ContinueButton } from "../selectType";

const initialCardRate: cardRate = {
  denominationId: "",
  rate: "",
  amount: "",
};

interface props {
  cardData: CardResponseEdit;
}

const EditReceiptCardType: React.FC<props> = ({ cardData }) => {
  const [selectedCountryId, setSelectedCountryId] = useState<string>("");
  const [selectedReceiptId, setSelectedReceiptId] = useState<string>("");
  const [physicalRate, setPhysicalRate] = useState<cardRate>(initialCardRate);
  const [eCodeRate, setECodeRate] = useState<cardRate>(initialCardRate);
  const [receiptType, setReceiptType] = useState<ReceiptResponse[]>([]);
  const [denominations, setDenominations] = useState<DenominationResponse[]>(
    []
  );
  const [country, setCountry] = useState<string>();
  const [apiCountries, setApiCountries] = useState<CountriesResponse[]>([]);
  const [formData, setFormData] = useState<EditReceiptTypeObj[]>([]);
  const [cardName, setCardName] = useState("");
  const [physicalCardTypeId, setPhysicalCardTypeId] = useState<string>("");
  const [eCodeCardTypeId, setECordCardTypeId] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [documentToDisplay, setDocumentToDisplay] = useState<string>("");
  const [isAddingCountry, setIsAddingCountry] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [edited, setEdited] = useState(false);
  const [isNameChanged, setIsNameChanged] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setFormData(parseEditCardData(cardData));
    setCardName(cardData.name);
    setDocumentToDisplay(cardData.logo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  //parse country to dropdown obj
  const parseCardCountry = (obj: CardResponseEdit | undefined) => {
    const arr: dropDownType[] = [];
    obj &&
      obj.mainCardTypeDTOs.forEach((x) => {
        arr.push({
          label: x.countryName,
          value: x.countryId,
        });
      });
    //get unique country object
    return _.uniqBy(arr, "label");
  };

  const parseCardRateDenomination = (arr: CardRateDenominationObj[]) => {
    let tempArr: CardRateDenominationPayload[] = [];
    arr.forEach((x) => {
      tempArr.push({
        cardRateId: x.id,
        denominationId: x.denominationDTO.id,
        rate: x.rate,
        amount: x.denominationDTO.amount,
      });
    });

    return tempArr;
  };

  const parseEditCardData = (data: CardResponseEdit) => {
    let arr: EditReceiptTypeObj[] = [];
    data.mainCardTypeDTOs.forEach((x) => {
      x.cardTypesDTO.forEach((card) => {
        card.receipt.forEach((receipt) => {
          arr.push({
            countryId: x.countryId,
            cardTypeId: card.id,
            receiptTypeId: receipt.receiptId,
            updateCardRateDenominationConfigDTO: parseCardRateDenomination(
              receipt.mainCardTypeDenominationDTO
            ),
          });
        });
      });
    });
    return arr;
  };

  useEffect(() => {
    async function getDominations() {
      const res = await api.denominationsService.getDenominations();
      setDenominations(res.data.data);
    }

    async function getCountries() {
      const res = await api.countriesService.getCountries();
      setApiCountries(res.data.data);
    }

    Promise.all([getDominations(), getCountries()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = (name: string) => {
    setCardName(name);
    setIsNameChanged(true);
  };

  const handleCountryChange = (id: string) => {
    setSelectedCountryId(id);
    setSelectedReceiptId("");
    let physicalId = cardData?.mainCardTypeDTOs
      .find((x) => x.countryId === id)
      ?.cardTypesDTO.find((x) => x.cardType === CardTypeEnum.Physical)?.id;
    physicalId && setPhysicalCardTypeId(physicalId);
    let ecodeId = cardData?.mainCardTypeDTOs
      .find((x) => x.countryId === id)
      ?.cardTypesDTO.find((x) => x.cardType === CardTypeEnum.ECode)?.id;
    ecodeId && setECordCardTypeId(ecodeId);

    // get card receipts for selected country
    // temp receipt arr
    let receiptArr: { id: string; name: string }[] = [];
    cardData.mainCardTypeDTOs
      .find((x) => x.countryId === id)
      ?.cardTypesDTO.forEach((entry) => {
        entry.receipt.forEach((x) => {
          receiptArr.push({ id: x.receiptId, name: x.receiptType });
        });
      });
    const uniqueArr = _.uniqBy(receiptArr, "id");
    uniqueArr && setReceiptType(uniqueArr);
  };

  const handlePhysicalRateDelete = (
    e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>,
    index: number
  ) => {
    e.stopPropagation();
    // get unique card rate
    const res = formData.find(
      (x) =>
        x.countryId === selectedCountryId &&
        x.receiptTypeId === selectedReceiptId &&
        x.cardTypeId === physicalCardTypeId
    );
    if (res?.updateCardRateDenominationConfigDTO.length === 1) {
      formData.splice(formData.indexOf(res), 1);
    } else {
      res?.updateCardRateDenominationConfigDTO.splice(index, 1);
    }

    setFormData([...formData]);
    setEdited(true);
  };

  const handlePhysicalRateAdd = () => {
    if (
      physicalRate.rate &&
      physicalRate.denominationId !== "" &&
      physicalCardTypeId
    ) {
      //find previous country data, if not found, create a new one
      const oldCountryData = formData.find(
        (x) => x.countryId === selectedCountryId
      );
      if (!oldCountryData) {
        let newEntry: EditReceiptTypeObj = {
          countryId: selectedCountryId,
          receiptTypeId: selectedReceiptId,
          cardTypeId: physicalCardTypeId,
          updateCardRateDenominationConfigDTO: [
            {
              ...physicalRate,
              rate: Number(physicalRate.rate),
              amount: physicalRate.amount,
              cardRateId: defaultGuid,
            },
          ],
        };
        setFormData([...formData, newEntry]);
        setEdited(true);
      } else {
        // find and update existing card configuration, else create new one
        let oldEntry = formData.find(
          (x) =>
            x.receiptTypeId === selectedReceiptId &&
            x.countryId === selectedCountryId &&
            x.cardTypeId === physicalCardTypeId
        );
        if (!oldEntry) {
          let newEntry: EditReceiptTypeObj = {
            countryId: selectedCountryId,
            receiptTypeId: selectedReceiptId,
            cardTypeId: physicalCardTypeId,
            updateCardRateDenominationConfigDTO: [
              {
                ...physicalRate,
                rate: Number(physicalRate.rate),
                amount: physicalRate.amount,
                cardRateId: defaultGuid,
              },
            ],
          };
          setFormData([...formData, newEntry]);
          setEdited(true);
        } else {
          //find the cardType entry index for updating
          let oldEntryIndex = formData.findIndex(
            (x) =>
              x.receiptTypeId === selectedReceiptId &&
              x.countryId === selectedCountryId &&
              x.cardTypeId === physicalCardTypeId
          );

          //check if cardRate exist (if it does that means we're updating)
          let oldCardRateIndex =
            oldEntry.updateCardRateDenominationConfigDTO.findIndex(
              (x) => x.cardRateId === physicalRate.cardRateId
            );
          if (oldCardRateIndex > -1) {
            oldEntry.updateCardRateDenominationConfigDTO.splice(
              oldCardRateIndex,
              1,
              {
                ...physicalRate,
                amount: physicalRate.amount,
                rate: Number(physicalRate.rate),
              }
            );
            // update the object
            formData.splice(oldEntryIndex, 1, oldEntry);
            setFormData(formData);
            setPhysicalRate(initialCardRate);
            setEdited(true);
            return;
          }

          //check if denomination exists and throw error
          let oldDenomination =
            oldEntry.updateCardRateDenominationConfigDTO.find(
              (x) => x.denominationId === physicalRate.denominationId
            );
          if (oldDenomination) {
            toast.error("Denomination already exist");
            return;
          }

          let updatedObj = {
            ...oldEntry,
            updateCardRateDenominationConfigDTO: [
              ...oldEntry.updateCardRateDenominationConfigDTO,
              {
                ...physicalRate,
                cardRateId: defaultGuid,
                rate: Number(physicalRate.rate),
                amount: physicalRate.amount,
              },
            ],
          };
          //update the object
          formData.splice(oldEntryIndex, 1, updatedObj);
          setFormData([...formData]);
          setEdited(true);
        }
      }
      setPhysicalRate(initialCardRate);
    }
  };

  const handleBack = () => {
    navigate("/dashboard/cards", { replace: true });
  };

  const handleECodeRateDelete = (
    e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>,
    index: number
  ) => {
    e.stopPropagation();
    //get unique card rate
    const res = formData.find(
      (x) =>
        x.countryId === selectedCountryId &&
        x.receiptTypeId === selectedReceiptId &&
        x.cardTypeId === eCodeCardTypeId
    );
    if (res?.updateCardRateDenominationConfigDTO.length === 1) {
      formData.splice(formData.indexOf(res), 1);
    } else {
      res?.updateCardRateDenominationConfigDTO.splice(index, 1);
    }

    setFormData([...formData]);
    setEdited(true);
  };

  const handleECodeRateAdd = () => {
    // reference handlePhysicalRateAdd function above
    if (eCodeRate.rate && eCodeRate.denominationId !== "" && eCodeCardTypeId) {
      const oldCountryData = formData.find(
        (x) => x.countryId === selectedCountryId
      );
      if (!oldCountryData) {
        let newEntry: EditReceiptTypeObj = {
          countryId: selectedCountryId,
          receiptTypeId: selectedReceiptId,
          cardTypeId: eCodeCardTypeId,
          updateCardRateDenominationConfigDTO: [
            {
              ...eCodeRate,
              rate: Number(eCodeRate.rate),
              amount: eCodeRate.amount,
              cardRateId: defaultGuid,
            },
          ],
        };
        setFormData([...formData, newEntry]);
        setEdited(true);
      } else {
        let oldEntry = formData.find(
          (x) =>
            x.receiptTypeId === selectedReceiptId &&
            x.countryId === selectedCountryId &&
            x.cardTypeId === eCodeCardTypeId
        );
        if (!oldEntry) {
          let newEntry: EditReceiptTypeObj = {
            countryId: selectedCountryId,
            receiptTypeId: selectedReceiptId,
            cardTypeId: eCodeCardTypeId,
            updateCardRateDenominationConfigDTO: [
              {
                ...eCodeRate,
                rate: Number(eCodeRate.rate),
                amount: eCodeRate.amount,
                cardRateId: defaultGuid,
              },
            ],
          };
          setFormData([...formData, newEntry]);
          setEdited(true);
        } else {
          //find the cardType entry index for updating
          let oldEntryIndex = formData.findIndex(
            (x) =>
              x.receiptTypeId === selectedReceiptId &&
              x.countryId === selectedCountryId &&
              x.cardTypeId === eCodeCardTypeId
          );

          //check if cardRate exist (if it does that means we're updating)
          let oldCardRateIndex =
            oldEntry.updateCardRateDenominationConfigDTO.findIndex(
              (x) => x.cardRateId === eCodeRate.cardRateId
            );
          if (oldCardRateIndex > -1) {
            oldEntry.updateCardRateDenominationConfigDTO.splice(
              oldCardRateIndex,
              1,
              {
                ...eCodeRate,
                amount: eCodeRate.amount,
                rate: Number(eCodeRate.rate),
              }
            );
            // update the object
            formData.splice(oldEntryIndex, 1, oldEntry);
            setFormData(formData);
            setECodeRate(initialCardRate);
            setEdited(true);
            return;
          }

          //check if denomination exists and throw error
          let oldDenomination =
            oldEntry.updateCardRateDenominationConfigDTO.find(
              (x) => x.denominationId === eCodeRate.denominationId
            );
          if (oldDenomination) {
            toast.error("Denomination already exist");
            return;
          }

          let updatedObj = {
            ...oldEntry,
            updateCardRateDenominationConfigDTO: [
              ...oldEntry.updateCardRateDenominationConfigDTO,
              {
                ...eCodeRate,
                rate: Number(eCodeRate.rate),
                amount: eCodeRate.amount,
                cardRateId: defaultGuid,
              },
            ],
          };
          formData.splice(oldEntryIndex, 1, updatedObj);
          setFormData([...formData]);
          setEdited(true);
        }
      }
      setECodeRate(initialCardRate);
    }
  };

  const receiptDropDownOptions = () => {
    const arr: dropDownType[] = [];
    receiptType &&
      receiptType.forEach((x) => {
        arr.push({ value: x?.id, label: x?.name });
      });

    return arr;
  };

  const dropDownOptions = () => {
    const arr: genericdropDownType<number, string>[] = [];
    denominations.forEach((x) => {
      arr.push({ value: x?.id, label: x?.amount });
    });

    return arr;
  };

  const onPhysicalTypeChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    setPhysicalRate({
      ...physicalRate,
      denominationId: newValue.value,
      amount: newValue.label,
    });
  };

  const onECodeTypeChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    setECodeRate({
      ...eCodeRate,
      denominationId: newValue.value,
      amount: newValue.label,
    });
  };

  const onReceiptTypeChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    setSelectedReceiptId(newValue.value);
  };

  const countryDropDownOptions = () => {
    const arr: dropDownType[] = [];
    apiCountries.forEach((x) => {
      arr.push({ value: x?.id, label: x?.name });
    });

    return arr;
  };

  const onCountryTypeChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    setCountry(newValue.value);
  };

  const handleAdd = async () => {
    if (!country) {
      toast.info("Please select a country");
      return;
    }

    if (params?.id) {
      setIsAddingCountry(true);
      try {
        const res = await api.cardService.addCountryToCard(
          {
            countryIds: [country],
          },
          params.id
        );
        if (res.data.data) {
          toast.success("Kindly reconfigure the card");
          setTimeout(() => {
            handleBack();
          }, 1000);
        }
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsAddingCountry(false);
    }
  };

  const handleSelectPhysicalRate = (data: CardRateDenominationPayload) => {
    setPhysicalRate({
      ...data,
      amount: data.amount?.toString(),
      rate: data.rate.toString(),
    });
  };

  const handleSelectECodeRate = (data: CardRateDenominationPayload) => {
    setECodeRate({
      ...data,
      amount: data.amount?.toString(),
      rate: data.rate.toString(),
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let dataToSend: EditReceiptTypePayload = {
        cardName,
        updateReceiptTypeConfigDTO: formData,
      };
      if (params?.id) {
        const res = await api.cardService.editReceiptType(
          dataToSend,
          params.id
        );

        res.data.data && toast.success("Card updated successfully");
        handleBack();
      }
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsSubmitting(false);
  };

  const isValid = (formData.length !== 0 && edited) || isNameChanged;

  return (
    <>
      <CardWrapper>
        <CardLeft>
          <p>Card Name</p>
          <input
            type="text"
            value={cardName}
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </CardLeft>
        <Passport
          documentToDisplay={documentToDisplay}
          setDocumentToDisplay={setDocumentToDisplay}
        />
      </CardWrapper>
      <BottomWrapper>
        <BottomLeft>
          <BottomLeftBox>
            <p>Card Country</p>
            <CountryWrapper>
              {parseCardCountry(cardData).map((x, i) => (
                <SelectEntry
                  key={i}
                  onClick={() => handleCountryChange(x.value)}
                  active={selectedCountryId === x.value}
                >
                  {x.label}
                </SelectEntry>
              ))}
            </CountryWrapper>
            <AddCountryWrapper>
              <Select
                options={countryDropDownOptions()}
                onChange={onCountryTypeChange}
              />
              <AddButton onClick={() => handleAdd()}>
                {isAddingCountry ? (
                  <LoaderWrapper>
                    <DualRing width="10px" height="10px" />
                  </LoaderWrapper>
                ) : (
                  "Add"
                )}
              </AddButton>
            </AddCountryWrapper>
          </BottomLeftBox>
          <ReceiptTypeWrapper>
            <p>Receipt Type</p>
            <BottomLeftContent>
              <Select
                options={receiptDropDownOptions()}
                onChange={onReceiptTypeChange}
                value={receiptDropDownOptions().filter(
                  (x) => x.value === selectedReceiptId
                )}
                isDisabled={!!!selectedCountryId}
              />
            </BottomLeftContent>
          </ReceiptTypeWrapper>
        </BottomLeft>
        <BottomRight>
          <BottomRightContent>
            <p>Physical Card Rate</p>
            <BottomContentBox>
              <BottomContentHeader>
                <p>Denomination</p>
                <p>Rate per $</p>
              </BottomContentHeader>
              <RateEntryWrapper>
                {formData
                  .find(
                    (x) =>
                      x.cardTypeId === physicalCardTypeId &&
                      x.countryId === selectedCountryId &&
                      x.receiptTypeId === selectedReceiptId
                  )
                  ?.updateCardRateDenominationConfigDTO?.map((x, i) => (
                    <EntryWrapper
                      key={i}
                      onClick={() => handleSelectPhysicalRate(x)}
                    >
                      <p>{x.amount}</p>
                      <p>{x.rate}</p>
                      <span onClick={(e) => handlePhysicalRateDelete(e, i)}>
                        x
                      </span>
                    </EntryWrapper>
                  ))}
              </RateEntryWrapper>
            </BottomContentBox>
            <RateWrapper>
              <Select
                options={dropDownOptions()}
                onChange={onPhysicalTypeChange}
                menuPlacement="auto"
                value={dropDownOptions().filter(
                  (x) => x.value === physicalRate.denominationId
                )}
                isDisabled={!!!selectedCountryId || !!!selectedReceiptId}
              />
              <div>
                <span>=</span>
                N
                <input
                  type="text"
                  value={physicalRate.rate}
                  disabled={!!!selectedCountryId || !!!selectedReceiptId}
                  onChange={(e) =>
                    setPhysicalRate({
                      ...physicalRate,
                      rate: e.target.value,
                    })
                  }
                />
              </div>
              <AddButton onClick={handlePhysicalRateAdd}>Add</AddButton>
            </RateWrapper>
          </BottomRightContent>
          <BottomRightContent>
            <p>E Code Rate</p>
            <BottomContentBox>
              <BottomContentHeader>
                <p>Denomination</p>
                <p>Rate per $</p>
              </BottomContentHeader>
              <RateEntryWrapper>
                {formData
                  .find(
                    (x) =>
                      x.cardTypeId === eCodeCardTypeId &&
                      x.countryId === selectedCountryId &&
                      x.receiptTypeId === selectedReceiptId
                  )
                  ?.updateCardRateDenominationConfigDTO?.map((x, i) => (
                    <EntryWrapper
                      key={i}
                      onClick={() => handleSelectECodeRate(x)}
                    >
                      <p>{x.amount}</p>
                      <p>{x.rate}</p>
                      <span onClick={(e) => handleECodeRateDelete(e, i)}>
                        x
                      </span>
                    </EntryWrapper>
                  ))}
              </RateEntryWrapper>
            </BottomContentBox>
            <RateWrapper>
              <Select
                options={dropDownOptions()}
                onChange={onECodeTypeChange}
                menuPlacement="auto"
                value={dropDownOptions().filter(
                  (x) => x.value === eCodeRate.denominationId
                )}
                isDisabled={!!!selectedCountryId || !!!selectedReceiptId}
              />
              <div>
                <span>=</span>
                N
                <input
                  type="text"
                  value={eCodeRate.rate}
                  onChange={(e) =>
                    setECodeRate({
                      ...eCodeRate,
                      rate: e.target.value,
                    })
                  }
                  disabled={!!!selectedCountryId || !!!selectedReceiptId}
                />
              </div>
              <AddButton onClick={handleECodeRateAdd}>Add</AddButton>
            </RateWrapper>
          </BottomRightContent>
        </BottomRight>
      </BottomWrapper>
      <ButtonWrapper>
        <CancelButton onClick={() => setIsOpen(true)}>Cancel</CancelButton>
        <ContinueButton onClick={handleSubmit} disabled={!isValid}>
          {isSubmitting ? (
            <LoaderWrapper>
              <DualRing width="10px" height="10px" />
            </LoaderWrapper>
          ) : (
            "Update"
          )}
        </ContinueButton>
      </ButtonWrapper>
      <CancelModal
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
        handleSubmit={() => navigate("/dashboard/cards", { replace: true })}
      />
    </>
  );
};

export default EditReceiptCardType;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 50px 0;

  ${mediaQueries.tablet} {
    padding: 0 10px;
    flex-direction: column;
    gap: 25px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 25px;
    padding: 0;
  }
`;

const BottomLeft = styled.div`
  display: flex;
  gap: 60px;
  width: 40%;

  ${mediaQueries.tablet} {
    width: 100%;
    gap: 30px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    flex-direction: column;
    gap: 30px;
  }
`;

const BottomLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 180px;

  & > p {
    letter-spacing: -0.11px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  ${mediaQueries.tablet} {
    width: 50%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    gap: 20px;
  }
`;

export const AddCountryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    cursor: pointer;
    box-shadow: unset;
  }
`;

const CountryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 150px;
  overflow-y: scroll;

  ${mediaQueries.mobile} {
    height: auto;
    max-height: 150px;
  }
`;

const BottomLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .css-1s2u09g-control,
  .css-1insrsq-control,
  .css-1pahdxg-control,
  .css-1hb7zxy-Indicator {
    cursor: pointer;
    min-height: 34px;
    height: 34px;
    box-shadow: unset;
  }

  .css-319lph-ValueContainer {
    padding: 0 8px;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 3px 4px;
  }
`;

const ReceiptTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 50%;

  & > p {
    letter-spacing: -0.11px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    gap: 15px;

    & > p {
      font-size: 14px;
    }
  }
`;

interface countryProps {
  active?: boolean;
}

const SelectEntry = styled.p<countryProps>`
  letter-spacing: -0.14px;
  color: #c9c9c9;
  font-size: 14px;
  height: 34px;
  min-width: 127px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.25ms ease-in-out;

  ${(props) =>
    props.active &&
    css`
      height: 34px;
      width: 100%;
      color: #ffffff;
      font-weight: bold;
      background: #faa84f;
      border-radius: 3px;
    `}
`;

const BottomRight = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 55%;

  ${mediaQueries.tablet} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    width: 100%;
    gap: 30px;
  }
`;

const BottomRightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  & > p {
    letter-spacing: -0.11px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  ${mediaQueries.tablet} {
    width: 50%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const BottomContentBox = styled.div`
  padding: 15px;
  height: 215px;
  background: #ffffff;
  border: 1px solid #8e8e8e;
  border-radius: 5px;
`;

const BottomContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & > p {
    width: 45%;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: #727272;
  }
`;

const RateEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 155px;
  overflow-x: scroll;
`;

const EntryWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 4px;
  border-bottom: 1px solid #c3c3c3;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background: #f7f7f7;
  }

  & > p {
    width: 45%;
    letter-spacing: -0.13px;
    color: #000000;
    font-size: 13px;
    font-weight: 600;
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #fdd5d5;
    color: #ef3338;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 4px;
    margin-left: 4px;
    cursor: pointer;
  }
`;

const RateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:first-of-type {
    border: none;
    outline: none;
    height: 30px;
    background: #f7f7f7;
    border-radius: 3px;
    width: 108px;
  }

  & > div:nth-of-type(2) {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    letter-spacing: -0.14px;
    color: #727272;
    font-size: 14px;
    font-weight: bold;
    width: 32%;

    & > span {
      position: absolute;
      top: 0px;
      left: -1px;
      color: #727272;
      font-weight: bold;
      font-size: 20px;
    }

    & > input {
      height: 30px;
      width: 100%;
      background: #f7f7f7;
      border: 1px solid #b9b9b945;
      outline: none;
      border-radius: 3px;
      padding-left: 8px;
      font-weight: bold;
      font-size: 11px;
    }
  }

  .css-1s2u09g-control,
  .css-1pahdxg-control,
  .css-1insrsq-control,
  .css-1hb7zxy-Indicator {
    min-height: 30px;
    height: 30px;
    cursor: pointer;
    box-shadow: unset;
  }

  .css-1pahdxg-control:hover {
    border-color: hsl(0, 0%, 80%);
  }

  .css-319lph-ValueContainer {
    padding: 0 8px;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 3px 4px;
  }

  .css-qc6sy-singleValue {
    font-size: 14px;
  }
`;

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 30px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 3px;
  letter-spacing: -0.1px;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
`;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersState } from "./types";
import { getActiveUsers, getDisabledUsers, getInactiveUsers } from "./actions";
import { RootState } from "..";

const initialState: UsersState = {
  activeUsers: {
    error: null,
    pending: false,
  },
  disabledUsers: {
    error: null,
    pending: false,
  },
  inactiveUsers: {
    error: null,
    pending: false,
  },
  refreshNeeded: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    refreshUsers: (state, action: PayloadAction<boolean>) => {
      state.refreshNeeded = action.payload;
    },
    resetUsers: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getActiveUsers.pending, (state) => {
        return {
          ...state,
          activeUsers: {
            ...state.activeUsers,
            pending: true,
            error: null,
            response: undefined,
          },
        };
      })
      .addCase(getActiveUsers.fulfilled, (state, { payload }) => {
        return {
          ...state,
          activeUsers: {
            ...state.activeUsers,
            pending: false,
            error: null,
            response: payload,
          },
        };
      })
      .addCase(getActiveUsers.rejected, (state, action) => {
        return {
          ...state,
          activeUsers: {
            ...state.activeUsers,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
    builder
      .addCase(getInactiveUsers.pending, (state) => {
        return {
          ...state,
          inactiveUsers: {
            ...state.inactiveUsers,
            pending: true,
            error: null,
            response: undefined,
          },
        };
      })
      .addCase(getInactiveUsers.fulfilled, (state, { payload }) => {
        return {
          ...state,
          inactiveUsers: {
            ...state.inactiveUsers,
            pending: false,
            error: null,
            response: payload,
          },
        };
      })
      .addCase(getInactiveUsers.rejected, (state, action) => {
        return {
          ...state,
          inactiveUsers: {
            ...state.inactiveUsers,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
    builder
      .addCase(getDisabledUsers.pending, (state) => {
        return {
          ...state,
          disabledUsers: {
            ...state.disabledUsers,
            pending: true,
            error: null,
            response: undefined,
          },
        };
      })
      .addCase(getDisabledUsers.fulfilled, (state, { payload }) => {
        return {
          ...state,
          disabledUsers: {
            ...state.disabledUsers,
            pending: false,
            error: null,
            response: payload,
          },
        };
      })
      .addCase(getDisabledUsers.rejected, (state, action) => {
        return {
          ...state,
          disabledUsers: {
            ...state.disabledUsers,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
  },
});

export const { refreshUsers, resetUsers } = userSlice.actions;

export const activeUserSelector = (state: RootState) => state.users.activeUsers;
export const inactiveUserSelector = (state: RootState) =>
  state.users.inactiveUsers;
export const disabledUserSelector = (state: RootState) =>
  state.users.disabledUsers;
export const refreshUsersSelector = (state: RootState) =>
  state.users.refreshNeeded;

export default userSlice.reducer;

import styled from "styled-components";
import { cryptoResponse } from "../../../../api/crypto/types";

export const StatusRenderer = ({ data }: { data: cryptoResponse }) => {
  return (
    <StatusWrapper status={data.isActive}>
      <p>{data.isActive ? "Active" : "Disabled"}</p>
    </StatusWrapper>
  );
};

interface statusProps {
  status: boolean;
}

const StatusWrapper = styled.div<statusProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 13px;
  border-radius: 18px;
  border: ${(props) =>
    props.status ? "2px solid #1ABA27" : "2px solid #EA3A3E"};

  & > p {
    margin: 0;
    color: ${(props) => (props.status ? "#1ABA27" : "#EA3A3E")};
    font-size: 9px;
    font-weight: bold;
  }
`;

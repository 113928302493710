import { useNavigate } from "react-router";
import styled from "styled-components";
import logo from "../../images/optimaBlack.png";
import {
  Container,
  ContentWrapper,
  InputWrapper,
  RightsWrapper,
  TopWrapper,
} from "./login/login";
import { ReactComponent as BackgroundIcon } from "../../images/login-background.svg";
import { pageFadeVariant } from "../components/animate/variants";
import { useState } from "react";
import api from "../api";
import { toast } from "react-toastify";
import { LoaderWrapper } from "./Preferences/components/preferences";
import DualRing from "../components/loader";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await api.authService.forgotPassword(email);
      toast.success(res.data.responseMessage);
      navigate("/login");
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <Container
      variants={pageFadeVariant}
      initial={"initial"}
      exit={"exit"}
      animate={"animate"}
    >
      <BackgroundIcon />
      <ContentWrapper>
        <TopWrapper>
          <img src={logo} alt="" onClick={() => navigate("/login")} />
          <p>Admin Dashboard</p>
        </TopWrapper>
        <Form>
          <p>Reset Password</p>
          <InputWrapper>
            <p>Email Address</p>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapper>
          <ResetButton onClick={handleSubmit}>
            {isLoading ? (
              <LoaderWrapper>
                <DualRing width="10px" height="10px" />
              </LoaderWrapper>
            ) : (
              "Send password reset link"
            )}
          </ResetButton>
        </Form>
      </ContentWrapper>
      <RightsWrapper>
        <p>© Optima Technology Solutions {new Date().getFullYear()}</p>
        <p>All rights reserved.</p>
      </RightsWrapper>
    </Container>
  );
};

export default ForgotPassword;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 30px 0 120px;

  & > p {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.14px;
    color: #000000;
  }
`;

const ResetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  margin-top: 10px;
  letter-spacing: -0.12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
`;

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import {
  AddDenominationPayload,
  DenominationResponse,
} from "../../../api/denomination/types";
import DeleteModal from "../../../components/deleteModal";
import DualRing from "../../../components/loader";
import {
  AddButton,
  AddSectionWrapper,
  LoaderWrapper,
  SectionBox,
  SectionWrapper,
} from "./preferences";

const Denominations = () => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemId, setItemId] = useState<string>();
  const [denominations, setDenominations] = useState<DenominationResponse[]>();
  const [formData, setFormData] = useState<AddDenominationPayload>();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  async function getDenominations() {
    setIsLoading(true);
    try {
      const res = await api.denominationsService.getDenominations();
      setDenominations(res.data.data);
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getDenominations();
  }, []);

  useEffect(() => {
    shouldRefresh &&
      setTimeout(() => {
        getDenominations();
        setShouldRefresh(false);
      }, 1000);
  }, [shouldRefresh]);

  const handleCloseDeleteModal = () => {
    setItemId("");
    setIsDeleteOpen(false);
  };

  const handleOpenDeleteModal = (id: string) => {
    setItemId(id);
    setIsDeleteOpen(true);
  };

  const handleDelete = async () => {
    if (itemId) {
      setIsDeleting(true);
      try {
        const res = await api.denominationsService.deleteDenomination(itemId);
        res.data.data && toast.success("Denomination deleted successfully");
        setShouldRefresh(true);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsDeleting(false);
    }
    handleCloseDeleteModal();
  };

  const handleChange = (value: string) => {
    setFormData({ amount: value });
  };

  const handleAdd = async () => {
    if (formData?.amount) {
      setIsSubmitting(true);
      try {
        const res = await api.denominationsService.addDenomination(formData);
        res.data.data && toast.success("Denomination created successfully");
        setShouldRefresh(true);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsSubmitting(false);
    }
    setFormData({ amount: "" });
  };

  return (
    <SectionWrapper>
      <h2>Denominations</h2>
      <SectionBox>
        {isLoading ? (
          <MainLoaderWrapper>
            <DualRing width="30px" height="30px" color="#727272" />
          </MainLoaderWrapper>
        ) : (
          <DenominationContent>
            {denominations?.map((x, i) => (
              <DenominationEntry key={i}>
                <p>{x.amount}</p>
                <span onClick={() => handleOpenDeleteModal(x.id)}>x</span>
              </DenominationEntry>
            ))}
          </DenominationContent>
        )}
        <AddSectionWrapper>
          <input
            type="text"
            value={formData?.amount.toString()}
            onChange={(e) => handleChange(e.target.value)}
          />
          <AddButton onClick={() => handleAdd()}>
            {isSubmitting ? (
              <LoaderWrapper>
                <DualRing width="10px" height="10px" />
              </LoaderWrapper>
            ) : (
              "+ Add Denomination"
            )}
          </AddButton>
        </AddSectionWrapper>
      </SectionBox>
      <DeleteModal
        handleClose={() => handleCloseDeleteModal()}
        isOpen={isDeleteOpen}
        handleSubmit={() => handleDelete()}
        isDeleting={isDeleting}
      />
    </SectionWrapper>
  );
};

export default Denominations;

const DenominationContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow: scroll;
  gap: 30px;
  height: fit-content;
  padding: 5px 5px 60px;
`;

const DenominationEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px 20px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 3px 10px #00000012;
  border-radius: 5px;

  & > p {
    letter-spacing: -0.28px;
    color: #faa84f;
    font-size: 14px;
    font-weight: 600;
  }

  & > span {
    position: absolute;
    right: -10px;
    top: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    color: #ef3338;
    font-size: 16px;
    font-weight: bold;
    background: #fdd5d5;
    border-radius: 50%;
    padding-bottom: 5px;
    cursor: pointer;
  }
`;

interface loaderProps {
  height?: string;
}

export const MainLoaderWrapper = styled.div<loaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "300px")};
`;

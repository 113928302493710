import {
  ButtonWrapper,
  CancelButton,
  Container,
  ContentWrapper,
  ContinueButton,
  NewCardWrapper,
  TopWrapper,
} from "../Cards/selectType";
import { ReactComponent as LeftIcon } from "../../../images/left-arrow.svg";
import { ReactComponent as UploadIcon } from "../../../images/upload-card.svg";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useState } from "react";
import CancelModal from "../../components/cancelModal";
import api from "../../api";
import { toast } from "react-toastify";
import { LoaderWrapper } from "../Preferences/components/preferences";
import DualRing from "../../components/loader";
import mediaQueries from "../../../utils/mediaQueries";
import { cryptoStore } from "../../redux/crypto/cryptoStore";

const NewCrypto = () => {
  const [documentToDisplay, setDocumentToDisplay] = useState<string>("");
  const [documentToUpload, setDocumentToUpload] = useState<File>();
  const [cardName, setCardName] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/dashboard/crypto", { replace: true });
  };

  const selectdocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let file = e!.target!.files![0];
      // check if file is greater than 1mb
      if (file.size > 1e6) {
        toast.error("Please upload file less than 1mb");
        return;
      }
      if (file.type.includes("image")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          if (event.target && event.target.result) {
            setDocumentToDisplay(event.target.result.toString());
            setDocumentToUpload(file);
          }
        });
      }
    }
  };

  //used any type to target the id of the element
  const handleFilePicking = (e: any) => {
    if (e.target.id !== "delete") {
      document!.getElementById("document")!.click();
    }
  };

  const handleFileDelete = () => {
    setDocumentToDisplay("");
    setDocumentToUpload(undefined);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await api.cryptoService.createCrypto({
        logo: documentToUpload,
        name: cardName,
      });

      if (res.data.data.id) {
        cryptoStore.cryptoId = res.data.data.id;
        navigate("/dashboard/crypto/configure");
      }
    } catch (error) {
      const errorMessage = error as any;
      toast.error(
        errorMessage?.toString() || "Crypto creation failed, try again"
      );
    }
    setIsSubmitting(false);
  };

  const isValid = !!cardName && !!documentToDisplay;

  return (
    <Container>
      <h1>Create Crypto</h1>
      <AddContentWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        <NewCardWrapper>
          <h1>Add New Crypto</h1>
          <p>Input Crypto Details</p>
          <MainContentWrapper>
            <TopSectionWrapper>
              <InputEntry>
                <p>Enter Crypto Name</p>
                <input
                  type="text"
                  value={cardName}
                  onChange={(e) => setCardName(e.target.value)}
                />
              </InputEntry>
              <UploadWrapper>
                <p>Upload Crypto Image</p>
                <input
                  type="file"
                  id="document"
                  accept="image/png,image/jpg,image/jpeg"
                  onChange={selectdocument}
                />
                <UploadBox>
                  {!documentToDisplay ? (
                    <UploadImageWrapper
                      onClick={() =>
                        document!.getElementById("document")!.click()
                      }
                    >
                      <UploadIcon />
                      <p>Browse files</p>
                      <span>Supported formats: PNG, JPG, JPEG</span>
                    </UploadImageWrapper>
                  ) : (
                    <DocumentWrapper onClick={(e) => handleFilePicking(e)}>
                      <img src={documentToDisplay} alt="" />
                      <span id="delete" onClick={() => handleFileDelete()}>
                        x
                      </span>
                    </DocumentWrapper>
                  )}
                </UploadBox>
              </UploadWrapper>
            </TopSectionWrapper>
          </MainContentWrapper>
          <ButtonWrapper>
            <CancelButton onClick={() => setIsOpen(true)}>Cancel</CancelButton>
            <ContinueButton onClick={handleSubmit} disabled={!isValid}>
              {isSubmitting ? (
                <LoaderWrapper>
                  <DualRing width="10px" height="10px" />
                </LoaderWrapper>
              ) : (
                "Next"
              )}
            </ContinueButton>
          </ButtonWrapper>
        </NewCardWrapper>
      </AddContentWrapper>
      <CancelModal
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
        handleSubmit={() => navigate("/dashboard/crypto", { replace: true })}
      />
    </Container>
  );
};

export default NewCrypto;

const AddContentWrapper = styled(ContentWrapper)`
  width: 100%;
`;

const MainContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
  min-height: 280px;

  ${mediaQueries.mobile} {
    flex-direction: column;
    margin-top: 30px;
  }
`;

const InputEntry = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 430px;

  ${mediaQueries.mobile} {
    width: 100%;
  }

  & > p {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #000000;
  }

  & > input {
    width: 100%;
    max-width: 430px;
    font-size: 14px;
    height: 45px;
    background: #f7f7f7;
    padding-left: 12px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #b9b9b9;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;

    & > div {
      width: 50%;
    }
  }

  .css-1s2u09g-control {
    cursor: pointer;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;

  & > p {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #000000;
  }

  & > input {
    display: none;
  }

  ${mediaQueries.tablet} {
    width: 47%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

export const UploadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #727272;
  border-radius: 5px;
  width: 100%;
  height: 160px;
`;

export const UploadImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  cursor: pointer;

  & > p {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #faa84f;
    margin-top: 5px;
  }

  & > span {
    font-size: 8px;
    letter-spacing: -0.08px;
    color: #7c7c7c;
  }
`;

export const DocumentWrapper = styled.div`
  position: relative;
  width: 90%;
  height: 90%;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }

  & > span {
    position: absolute;
    top: 7px;
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #131519;
    color: #fff;
    cursor: pointer;
  }

  ${mediaQueries.mobileAndTablet} {
    width: 95%;
    height: 92%;
  }
`;

const TopSectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 25px;
  }
`;

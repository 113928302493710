import { ApiResponse } from "../../redux/types";
import request from "../request";
import {
  changePasswordPayload,
  confirmEmailPayload,
  CreateAdminPayload,
  LoginPayload,
  LoginResponse,
  ProfileResponse,
  RefreshTokenPayload,
  resetPasswordPayload,
  SignupPayload,
  SignupResponse,
  UpdateAdminPermissionsPayload,
} from "./types";

const login = async (payload: LoginPayload) => {
  return await request.post<ApiResponse<LoginResponse>>(
    "/dashboard/login",
    payload
  );
};

const signup = async (payload: SignupPayload) => {
  return await request.post<ApiResponse<SignupResponse>>(
    "/authentication/register",
    payload
  );
};

const refreshToken = async (payload: RefreshTokenPayload) => {
  return await request.post<ApiResponse<LoginResponse>>(
    "/authentication/refreshtoken",
    payload
  );
};

const getProfile = async () => {
  return await request.get<ApiResponse<ProfileResponse>>(
    "/authentication/currentadmindetails"
  );
};

const getAdmins = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<ProfileResponse[]>>(
    keyword
      ? `/authentication/admins?Filter=FullName&Keyword=${keyword}`
      : `/authentication/admins?pageSize=10&pageIndex=${pageIndex}`
  );
};

const getSingleAdmin = async (id: string) => {
  return await request.get<ApiResponse<ProfileResponse>>(
    `/authentication/admindetails/${id}`
  );
};

const createAdmin = async (payload: CreateAdminPayload) => {
  return await request.post<ApiResponse<boolean>>(
    "/authentication/createadmin",
    payload
  );
};

const changePassword = async (payload: changePasswordPayload) => {
  return await request.post<ApiResponse<boolean>>(
    "/authentication/changepassword",
    payload
  );
};

const forgotPassword = async (payload: string) => {
  return await request.post<ApiResponse<boolean>>(
    "/authentication/forgotpassword",
    { emailAddress: payload }
  );
};

const resetPassword = async (payload: resetPasswordPayload) => {
  return await request.post<ApiResponse<boolean>>(
    "/authentication/resetpassword",
    payload
  );
};

const confirmEmail = async (payload: confirmEmailPayload) => {
  return await request.post<ApiResponse<boolean>>(
    "/authentication/confirmEmail",
    payload
  );
};

const updateAdminPermissions = async (
  payload: UpdateAdminPermissionsPayload
) => {
  return await request.put<ApiResponse<boolean>>(
    "/authentication/adminpermmission",
    payload
  );
};

const deleteUser = async (userId: string) => {
  return await request.delete<ApiResponse<boolean>>(
    `/authentication/deleteuser/${userId}`
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  signup,
  refreshToken,
  getProfile,
  getAdmins,
  getSingleAdmin,
  createAdmin,
  changePassword,
  forgotPassword,
  resetPassword,
  confirmEmail,
  updateAdminPermissions,
  deleteUser,
};

import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useLocation } from "react-router";
import { AccordionObj } from "../../types/common";
import mediaQueries from "../../utils/mediaQueries";
import { utilityStore } from "../redux/utilityStore";

interface accordionProps {
  title: string;
  icon: any;
  accordionData: AccordionObj[];
  isActive?: boolean;
  handleCloseNav?: () => void;
}

const CustomAccordion: React.FC<accordionProps> = ({
  icon: Icon,
  title,
  accordionData,
  isActive,
  handleCloseNav,
}) => {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  const path = `/${pathArray.slice(1).join("/")}`;
  return (
    <List isactive={isActive || undefined}>
      <li>
        <input type="checkbox" defaultChecked={true} />
        <i></i>
        <div className="title">
          <Icon />
          <p>{title}</p>
        </div>
        <div className="link-wrapper">
          {accordionData.map((x, i) => (
            <AccordionLink
              to={x.link}
              isactive={x.link === path}
              key={i}
              onClick={handleCloseNav}
            >
              {x.title}
            </AccordionLink>
          ))}
        </div>
      </li>
    </List>
  );
};

interface Props {
  link?: string;
  title: string;
  icon: any;
  accordionData?: AccordionObj[];
  action?: () => void;
  handleCloseNav?: () => void;
}

const CustomLink: React.FC<Props> = ({
  icon: Icon,
  link,
  title,
  action,
  accordionData,
  handleCloseNav,
}) => {
  const { pathname } = useLocation();

  const isActive = pathname === link;

  const handleClick = () => {
    if (!accordionData && handleCloseNav) {
      handleCloseNav();
    }
    utilityStore.currentPage = 1;
  };

  return (
    <Container
      isactive={isActive || undefined}
      islogout={!!action}
      onClick={handleClick}
    >
      {!!accordionData ? (
        <CustomAccordion
          icon={Icon}
          title={title}
          accordionData={accordionData}
          isActive={isActive || undefined}
          handleCloseNav={handleCloseNav}
        />
      ) : !!link ? (
        <ContentWrapper to={link} isactive={isActive || undefined}>
          <Icon />
          <p>{title}</p>
        </ContentWrapper>
      ) : (
        <NonLinkWrapper isactive={isActive || undefined} onClick={action}>
          <Icon />
          <p>{title}</p>
        </NonLinkWrapper>
      )}
    </Container>
  );
};

export default CustomLink;

interface ContainerProps {
  isactive?: boolean;
  islogout?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  border-right: ${({ isactive }) => (isactive ? "5px solid #FAA84F" : "none")};
  transition: all 0.2s ease-in-out;

  ${({ islogout }) =>
    islogout &&
    css`
      margin-top: auto;

      ${mediaQueries.mobile} {
        margin: 10px 0 0 23px;
      }
    `}

  ${mediaQueries.tablet} {
    justify-content: flex-start;
  }

  ${mediaQueries.mobile} {
    justify-content: flex-start;
  }
`;

const AccordionLink = styled(Link)<ContainerProps>`
  letter-spacing: -0.11px;
  color: ${({ isactive }) => (isactive ? "#FAA84F" : "#727272")};
  font-size: 13px;
  font-weight: 600;
  margin-left: 45px;
  width: 131px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ isactive }) => (isactive ? "#ff9627" : "#3f3e3e")};
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const ContentWrapper = styled(Link)<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 166px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  & > svg {
    width: 18px;
    height: 18px;
    path {
      fill: ${({ isactive }) => (isactive ? "#FAA84F" : "#727272")};
      transition: all 0.2s ease-in-out;
    }
  }

  & > p {
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    color: ${({ isactive }) => (isactive ? "#FAA84F" : "#727272")};
    transition: all 0.2s ease-in-out;

    &:hover {
      color: ${({ isactive }) => (isactive ? "#ff9627" : "#3f3e3e")};
    }
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const NonLinkWrapper = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 165px;
  margin-top: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  & > svg {
    width: 18px;
    height: 18px;
    path {
      fill: ${({ isactive }) => (isactive ? "#FAA84F" : "#727272")};
      transition: all 0.2s ease-in-out;
    }
  }

  & > p {
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    color: ${({ isactive }) => (isactive ? "#FAA84F" : "#727272")};
    transition: all 0.2s ease-in-out;

    &:hover {
      color: ${({ isactive }) => (isactive ? "#ff9627" : "#3f3e3e")};
    }
  }
`;

const List = styled.ul<ContainerProps>`
  list-style: none;
  padding: 0;
  margin: 0;

  & li {
    position: relative;
    padding: 0;
    margin: 0;
  }

  & li i {
    position: absolute;
    transform: translate(-6px, 0);
    margin-top: 8px;
    right: 6px;
  }
  & li i:before,
  & li i:after {
    content: "";
    position: absolute;
    background-color: ${({ isactive }) => (isactive ? "#FAA84F" : "#727272")};
    width: 2px;
    height: 7px;
  }
  & li i:before {
    transform: translate(-2px, 0) rotate(45deg);
  }
  & li i:after {
    transform: translate(2px, 0) rotate(-45deg);
  }
  & li input[type="checkbox"] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;

    &:hover ~ .title > p,
    li i:before,
    li i:after {
      color: ${({ isactive }) => (isactive ? "#ff9627" : "#3f3e3e")};
    }
  }
  & li input[type="checkbox"]:checked ~ .link-wrapper {
    margin-top: 0;
    max-height: 0;
    opacity: 0;
    transform: translate(0, 50%);
  }
  & li input[type="checkbox"]:checked ~ i:before {
    transform: translate(2px, 0) rotate(45deg);
  }
  & li input[type="checkbox"]:checked ~ i:after {
    transform: translate(-2px, 0) rotate(-45deg);
  }
  & li i:before,
  & li i:after,
  .link-wrapper {
    transition: all 0.35s ease-in-out;
  }

  & .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 165px;
    margin-left: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    & > svg {
      width: 18px;
      height: 18px;

      path {
        fill: ${({ isactive }) => (isactive ? "#FAA84F" : "#727272")};
        transition: all 0.2s ease-in-out;
      }
    }

    & > p {
      margin: 0;
      font-size: 17px;
      font-weight: 600;
      color: ${({ isactive }) => (isactive ? "#FAA84F" : "#727272")};
      transition: all 0.2s ease-in-out;
    }
  }

  & .link-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: rgba(48, 69, 92, 0.8);
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    max-height: 800px;
    opacity: 1;
    transform: translate(0, 0);
    margin-top: 14px;
    z-index: 2;
  }

  ${mediaQueries.tablet} {
    & .title {
      margin-left: 0;
    }
  }

  ${mediaQueries.mobile} {
    & .title {
      margin-left: 0;
    }
  }
`;

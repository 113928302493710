import { ApiResponse } from "../../redux/types";
import request from "../request";
import { contactPayload, contactRes, contactUsPayload } from "./types";

const getContacts = async () => {
  return await request.get<ApiResponse<contactRes[]>>("/Setting/Contact");
};

const createContact = async (payload: contactPayload) => {
  return await request.post<ApiResponse<boolean>>(
    "/Setting/CreateContact",
    payload
  );
};

const contactUs = async (payload: contactUsPayload) => {
  return await request.post<ApiResponse<boolean>>("/Email/ContactUs", payload);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createContact,
  getContacts,
  contactUs,
};

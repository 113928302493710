import React, { useEffect, useRef, useState } from "react";
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import {
  gridCellStyle,
  GridWrapper,
} from "../activeGrid/gridComponents/styles";
import { UserTransactionObj } from "../../../../api/users/types";
import { useMobileCheck } from "../../../../../hooks/useMobileCheck";
import { columnDefsDesktop, columnDefsMobile } from "./columnDef";
import mediaQueries from "../../../../../utils/mediaQueries";

type TransactionField = keyof UserTransactionObj | "action" | undefined;

interface GridDef extends ColDef {
  field: TransactionField;
}

interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: UserTransactionObj[];
}

interface gridProps {
  data: UserTransactionObj[];
}

const TransactionGrid: React.FC<gridProps> = ({ data }) => {
  const [gridData, setGridData] = useState<UserTransactionObj[]>([]);
  const gridApi = useRef<GridApi | null>(null);
  const GridData: UserTransactionObj[] = gridData;
  const { isMobile } = useMobileCheck();

  const columnDefs: GridDef[] = isMobile
    ? columnDefsMobile()
    : columnDefsDesktop();

  useEffect(() => {
    setGridData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const gridConfig: GridConfigTypes = {
    columnDefs: columnDefs,
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <>
      {gridData.length > 0 ? (
        <GridWrapper id="OpenOrdersGridWrapper" className={`ag-theme-balham`}>
          <AgGridReact
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={45}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            defaultColDef={{
              suppressMenu: true,
              sortable: true,
              cellStyle: {
                ...gridCellStyle,
                cursor: "pointer",
              },
            }}
            immutableData
            getRowNodeId={(data: UserTransactionObj) => {
              return data.id.toString();
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>No transactions found</p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default TransactionGrid;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 16px;
    font-weight: bold;
  }

  ${mediaQueries.mobile} {
    & > p {
      font-size: 15px;
    }
  }
`;

import { useEffect, useState } from "react";
import styled from "styled-components";
import CryptoGrid from "./grid";
import { ReactComponent as SearchIcon } from "../../../../images/Search.svg";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { SearchButton } from "../../Users";
import { toast } from "react-toastify";
import api from "../../../api";
import Paginator from "../../../components/Paginator";
import DualRing from "../../../components/loader";
import { MainLoaderWrapper } from "../../Preferences/components/denominations";
import mediaQueries from "../../../../utils/mediaQueries";
import { utilityStore } from "../../../redux/utilityStore";
import { useSnapshot } from "valtio";
import {
  refreshCryptoSaleNeeded,
  refreshCryptoSaleSelector,
} from "../../../redux/cryptoSale/cryptoSaleSlice";
import { TransactionStatusEnum } from "../../../../types/common";
import { cryptoSaleTableObj } from "../../../api/cryptoSale/types";
import { ApiResponse } from "../../../redux/types";

const Cryptocurrency = () => {
  const utilitySnap = useSnapshot(utilityStore);
  const [tabValue, setTabValue] = useState<number>(
    TransactionStatusEnum.Pending
  );
  const [searchValue, setsearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(utilitySnap.currentPage);
  const [totalCount, setTotalCount] = useState(0);
  const [gridData, setGridData] = useState<cryptoSaleTableObj[]>([]);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const shouldRefresh = useAppSelector(refreshCryptoSaleSelector);

  const getTransactions = async (
    currentPage: number,
    status: TransactionStatusEnum,
    filter?: string
  ) => {
    setIsLoading(true);
    try {
      let res: ApiResponse<cryptoSaleTableObj[]>;
      if (status === TransactionStatusEnum.Approved) {
        res = (
          await api.cryptoSaleService.getApprovedCryptoSale(currentPage, filter)
        ).data;
      } else {
        res = (
          await api.cryptoSaleService.getCryptoSale(currentPage, status, filter)
        ).data;
      }
      setGridData(res.data);

      let count = res.totalCount / 10;
      count =
        Math.floor(count) < count ? Math.floor(count) + 1 : Math.floor(count);
      setTotalCount(count);
      dispatch(refreshCryptoSaleNeeded(false));
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTransactions(currentPage, tabValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, currentPage]);

  useEffect(() => {
    setTimeout(() => {
      shouldRefresh &&
        (currentPage === 1 ? getTransactions(1, tabValue) : setCurrentPage(1));
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    utilityStore.currentPage = value;
  };

  const handleChangeIndex = (index: number) => {
    setCurrentPage(1);
    setTabValue(index);
    setsearchValue("");
  };

  const handleSearch = () => {
    getTransactions(currentPage, tabValue, searchValue);
    setsearchValue("");
  };

  return (
    <Container>
      <TitleWrapper>
        <h1>Cryptocurrency</h1>
      </TitleWrapper>
      <ContentWrapper>
        <TopWrapper>
          <TabsContainer>
            <TabWrapper>
              <Tab
                onClick={() => handleChangeIndex(TransactionStatusEnum.Pending)}
                active={tabValue === TransactionStatusEnum.Pending}
              >
                Pending
              </Tab>
              <Tab
                onClick={() =>
                  handleChangeIndex(TransactionStatusEnum.Approved)
                }
                active={tabValue === TransactionStatusEnum.Approved}
              >
                Completed
              </Tab>
              <Tab
                onClick={() =>
                  handleChangeIndex(TransactionStatusEnum.Declined)
                }
                active={tabValue === TransactionStatusEnum.Declined}
              >
                Declined
              </Tab>
            </TabWrapper>
            <SearchWrapper>
              <input
                type="text"
                placeholder="Search Sales"
                value={searchValue}
                onChange={(e) => setsearchValue(e.target.value)}
              />
              <SearchIcon />
              <SearchButton onClick={handleSearch}>Search</SearchButton>
            </SearchWrapper>
          </TabsContainer>
        </TopWrapper>
        {isLoading ? (
          <MainLoaderWrapper height="545px">
            <DualRing width="30px" height="30px" color="#727272" />
          </MainLoaderWrapper>
        ) : (
          <BottomWrapper>
            <CryptoGrid data={gridData} tabValue={tabValue} />
            {gridData?.length > 0 && (
              <Paginator
                total={totalCount}
                currentPage={currentPage}
                handleChange={handleChange}
              />
            )}
          </BottomWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default Cryptocurrency;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 35px 20px;
  width: 100%;
  margin-top: 90px;

  ${mediaQueries.mobileAndTablet} {
    padding: 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > h1 {
    letter-spacing: -0.18px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }
`;

const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding: 20px;

  ${mediaQueries.mobile} {
    padding: 0 0 10px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${mediaQueries.mobile} {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

interface tabProps {
  active: boolean;
}

const Tab = styled.div<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? "#FAA84F" : "#727272")};
  font-size: 12px;
  font-weight: bold;
  padding: ${(props) =>
    props.active ? "15px 20px 10px 20px" : "15px 20px 15px 20px"};
  border-bottom: ${(props) => (props.active ? "5px solid #FAA84F" : "none")};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 379px;
  height: 38px;

  & > input {
    width: 100%;
    height: 100%;
    padding-left: 45px;
    background: #f5f7f8;
    border-radius: 20px;
    border: none;
    outline: none;

    &::placeholder {
      color: #9a9a9a;
      font-weight: 600;
    }
  }

  & > svg {
    position: absolute;
    top: 14px;
    left: 18px;
    width: 12px;
    height: 13px;
  }

  ${mediaQueries.tablet} {
    width: 330px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    padding: 10px;
    height: auto;

    & > svg {
      top: 22px;
    }

    & > input {
      height: 30px;
    }
  }
`;

export const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 40px;
  letter-spacing: -0.12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 800;
  border-radius: 5px;
  background: transparent linear-gradient(169deg, #2b5a9d 0%, #041630 100%) 0%
    0% no-repeat padding-box;
  cursor: pointer;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 530px;
  margin-top: 15px;
`;

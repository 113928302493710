import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";
import DualRing from "../../../../components/loader";
import { toast } from "react-toastify";
import api from "../../../../api";
import { WithdrawActionEnum } from "../../../../../types/common";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  isOpen: boolean;
  id?: string;
  action?: WithdrawActionEnum;
  handleClose: () => void;
}

const ActionModal: React.FC<props> = ({ isOpen, handleClose, id, action }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    if (id && action) {
      try {
        const res = await api.transactionService.withdrawalAction(id, {
          creditDebitStatus: action,
        });
        if (res?.data.data) {
          WithdrawActionEnum.Approve === action &&
            toast.success("Withdrawal has been approved");
          WithdrawActionEnum.Decline === action &&
            toast.success("Withdrawal has been declined");
        }
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    setIsLoading(false);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth={"xs"}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          fontSize: 22,
          paddingTop: 4,
          fontFamily: "Plus Jakarta Sans",
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        {WithdrawActionEnum.Approve === action
          ? "Approve this withdrawal?"
          : "Decline this withdrawal"}
      </DialogTitle>
      <DialogActions>
        <ButtonWrapper>
          <CancelButton onClick={handleClose}>No, Go back</CancelButton>
          <ConfirmButton action={action} onClick={() => handleSubmit()}>
            {isLoading ? (
              <DualRing width="10px" height="10px" />
            ) : WithdrawActionEnum.Approve === action ? (
              "Yes, Approve"
            ) : (
              "Yes, Decline"
            )}
          </ConfirmButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default ActionModal;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding: 20px 15px 15px;
  border-top: 1px solid #c3c3c3;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  letter-spacing: -0.2px;
  font-weight: bold;
  font-size: 12px;
  color: #727272;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    color: #4d4b4b;
  }
`;

interface confirmProps {
  action?: WithdrawActionEnum;
}

const ConfirmButton = styled.div<confirmProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  background: ${(props) =>
    props.action === WithdrawActionEnum.Approve ? "#1aba27" : "#ed3838"};
  letter-spacing: -0.22px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;

  & > div {
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

import { Backdrop, Box, Fade, Modal } from "@mui/material";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";

interface props {
  open: boolean;
  previewImageIndex: number;
  handleClose: () => void;
  collection: string[];
}

const ImageModal: React.FC<props> = ({
  open,
  previewImageIndex,
  handleClose,
  collection,
}) => {
  const parseCollection = (data: string[]) => {
    let arr: any[] = [];
    data.forEach((x) => {
      arr.push({
        original: x,
      });
    });
    return arr;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={BoxStyle}>
          <ImagePreviewContainer>
            <Close onClick={() => handleClose()}>x</Close>
            <ImageGallery
              items={parseCollection(collection)}
              showBullets={false}
              showIndex={false}
              showThumbnails={false}
              lazyLoad={true}
              showPlayButton={false}
              startIndex={previewImageIndex}
            />
          </ImagePreviewContainer>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ImageModal;

const ImagePreviewContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;

  & img {
    width: -webkit-fill-available;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 6;
  width: 26px;
  height: 26px;
  font-size: 20px;
  padding-bottom: 5px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
`;

const BoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "92%",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 2,
};

import { motion } from "framer-motion";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import { faqObj } from "../../../api/faq/types";
import { componentSlide } from "../../../components/animate/variants";
import DualRing from "../../../components/loader";
import { MainLoaderWrapper } from "./denominations";
import FaqEntry from "./faqEntry";

interface props {
  setFaqs: Dispatch<SetStateAction<faqObj[]>>;
  faqs: faqObj[];
}
const Faq: React.FC<props> = ({ faqs, setFaqs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  async function getFaq() {
    setIsLoading(true);
    try {
      const res = await api.faqService.getFaqs();
      setFaqs(res.data.data);
      setReloadData(false);
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getFaq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      reloadData && getFaq();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const handleRemove = (index: number) => {
    faqs.splice(index, 1);
    setFaqs([...faqs]);
  };

  return (
    <Container
      variants={componentSlide}
      exit={"exit"}
      animate={"animate"}
      initial={"initial"}
      transition={{ duration: 0.5 }}
    >
      {isLoading ? (
        <MainLoaderWrapper>
          <DualRing width="30px" height="30px" color="#727272" />
        </MainLoaderWrapper>
      ) : (
        <FaqWrapper>
          {faqs.length > 0 ? (
            faqs.map((x, i) => (
              <FaqEntry
                data={x}
                key={i}
                index={i + 1}
                handleRemove={handleRemove}
                setReloadData={setReloadData}
              />
            ))
          ) : (
            <MainLoaderWrapper>
              <NoContent>No FAQS found</NoContent>
            </MainLoaderWrapper>
          )}
        </FaqWrapper>
      )}
    </Container>
  );
};

export default Faq;

const Container = styled(motion.div)``;

const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const NoContent = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

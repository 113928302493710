import { ColDef } from "ag-grid-community";
import { formatPrice } from "../../../../../utils/formatters";
import { transactionResponse } from "../../../../api/transactions/types";
import ActionRenderer from "./actionRenderer";
import { StatusRenderer } from "./statusRenderer";

type TransactionsField = keyof transactionResponse | "action" | undefined;

interface GridDef extends ColDef {
  field: TransactionsField;
}

export const columnDefsDesktop = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Date",
      field: "createdOn",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      valueFormatter: (params) => {
        const { data }: { data: transactionResponse } = params;
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
          new Date(data.createdOn)
        );
      },
      flex: 1,
    },
    {
      headerName: "Transaction ID",
      field: "transactionRefId",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Customer Name",
      sortable: true,
      field: "userName",
      filter: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Estimated Payout",
      sortable: false,
      field: "totalAmount",
      filter: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      valueFormatter: (params) => {
        const { data }: { data: transactionResponse } = params;
        return formatPrice(data.totalAmount);
      },
    },
    {
      headerName: "Status",
      sortable: false,
      field: "status",
      filter: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Action",
      sortable: false,
      field: "status",
      filter: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

export const columnDefsTablet = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Date",
      field: "createdOn",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      valueFormatter: (params) => {
        const { data }: { data: transactionResponse } = params;
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
          new Date(data.createdOn)
        );
      },
      flex: 1,
    },
    {
      headerName: "Transaction ID",
      field: "transactionRefId",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Customer Name",
      sortable: true,
      field: "userName",
      filter: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Payout",
      sortable: false,
      field: "totalAmount",
      filter: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      valueFormatter: (params) => {
        const { data }: { data: transactionResponse } = params;
        return formatPrice(data.totalAmount);
      },
    },
    {
      headerName: "Action",
      sortable: false,
      field: "status",
      filter: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

export const columnDefsMobile = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Name",
      sortable: true,
      field: "userName",
      filter: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Amount",
      sortable: false,
      field: "totalAmount",
      filter: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      valueFormatter: (params) => {
        const { data }: { data: transactionResponse } = params;
        return formatPrice(data.totalAmount);
      },
    },
    {
      headerName: "Action",
      sortable: false,
      field: "status",
      filter: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import { componentSlide } from "../../../components/animate/variants";
import CancelModal from "../../../components/cancelModal";
import DualRing from "../../../components/loader";
import { MainLoaderWrapper } from "./denominations";
import {
  BottomWrapper,
  ButtonWrapper,
  CancelButton,
  SaveButton,
} from "../../Profile/components/security";
import { LoaderWrapper } from "./preferences";
import mediaQueries from "../../../../utils/mediaQueries";

const Terms = () => {
  const [value, setValue] = useState<string>("");
  const [isEdited, setIsEdited] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    async function getTerms() {
      setIsLoading(true);
      try {
        const res = await api.termsService.getTerms();
        setValue(res.data.data.termsAndCondition);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsLoading(false);
    }

    getTerms();
  }, []);

  const navigate = useNavigate();

  const handleChange = (value: string) => {
    setIsEdited(true);
    setValue(value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await api.termsService.updateTerms({
        termsAndCondition: value,
      });
      toast.success("Terms updated successfully");
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsSubmitting(false);
  };

  return (
    <Container
      variants={componentSlide}
      exit={"exit"}
      animate={"animate"}
      initial={"initial"}
      transition={{ duration: 0.5 }}
    >
      {isLoading ? (
        <MainLoaderWrapper height="400px">
          <DualRing width="30px" height="30px" color="#727272" />
        </MainLoaderWrapper>
      ) : (
        <ContentWrapper>
          <TermsArea
            value={value}
            onChange={(e) => handleChange(e.target.value)}
          />
          <BottomWrapper>
            <ButtonWrapper>
              <CancelButton onClick={() => setIsCancelOpen(true)}>
                Cancel
              </CancelButton>
              <SaveButton onClick={() => handleSubmit()} disabled={!isEdited}>
                {isSubmitting ? (
                  <LoaderWrapper>
                    <DualRing width="10px" height="10px" />
                  </LoaderWrapper>
                ) : (
                  "Save Changes"
                )}
              </SaveButton>
            </ButtonWrapper>
          </BottomWrapper>
        </ContentWrapper>
      )}
      <CancelModal
        handleClose={() => setIsCancelOpen(false)}
        isOpen={isCancelOpen}
        handleSubmit={() => navigate("/dashboard", { replace: true })}
      />
    </Container>
  );
};

export default Terms;

const Container = styled(motion.div)``;

const ContentWrapper = styled.div`
  width: 722px;
`;

const TermsArea = styled.textarea`
  padding: 20px;
  width: 100%;
  height: 400px;
  background: #ffffff;
  border: 1px solid #b9b9b9;
  border-radius: 5px;

  ${mediaQueries.smallDesktop} {
    width: 100%;
    outline: #faa84f;
  }
`;

import { ApiResponse } from "../../redux/types";
import request from "../request";
import {
  CreateCryptoPayload,
  cryptoResponse,
  cryptoSingleResponse,
} from "./types";

const createCrypto = async (payload: CreateCryptoPayload) => {
  const formData = new FormData();
  const { name, logo } = payload;

  logo && formData.append("logo", logo);
  name && formData.append("name", name);

  return await request.post<ApiResponse<cryptoSingleResponse>>(
    "/Crypto",
    formData
  );
};

const getSingleCrypto = async (id: string) => {
  return await request.get<ApiResponse<cryptoSingleResponse>>(`/Crypto/${id}`);
};

const updateCrypto = async (id: string, payload?: CreateCryptoPayload) => {
  return await request.put<ApiResponse<cryptoResponse>>(
    `/Crypto/${id}`,
    payload
  );
};

const updateCryptoImage = async (id: string, payload: CreateCryptoPayload) => {
  const formData = new FormData();
  const { logo, name } = payload;

  logo && formData.append("logo", logo);
  name && formData.append("name", name);

  return await request.put<ApiResponse<cryptoResponse>>(
    `/Crypto/${id}/image`,
    formData
  );
};

const getActiveCryptos = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<cryptoResponse>>(
    keyword
      ? `/Crypto?Filter=Name&Keyword=${keyword}`
      : `/Crypto?pageSize=10&pageIndex=${pageIndex}`
  );
};

const getBlockedCryptos = async (pageIndex: number, keyword?: string) => {
  return await request.get<ApiResponse<cryptoResponse>>(
    keyword
      ? `/Crypto/Blocked?Filter=Name&Keyword=${keyword}`
      : `/Crypto/Blocked?pageSize=10&pageIndex=${pageIndex}`
  );
};

const toggleCryptoStatus = async (id: string, status?: boolean) => {
  return await request.put<ApiResponse<boolean>>(
    `/Crypto/${id}/status?status=${status}`
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getActiveCryptos,
  getBlockedCryptos,
  toggleCryptoStatus,
  createCrypto,
  updateCrypto,
  updateCryptoImage,
  getSingleCrypto,
};

import { useNavigate } from "react-router";
import styled from "styled-components";
import notFoundImg from "../../images/404.svg";
import HomeLayout from "../components/homeLayout";
import mediaQueries from "../../utils/mediaQueries";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <HomeLayout>
      <Container>
        <BgImg src={notFoundImg} />
        <Title>Error 404 - Page Not Found</Title>
        <Subtitle>The page you requested could not be found</Subtitle>
        <BackToHomeBtn onClick={() => navigate("/")}>
          Go back home
        </BackToHomeBtn>
      </Container>
    </HomeLayout>
  );
};

export default NotFound;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  width: 697px;
  height: 480px;
  margin: 80px auto 0;
  isolation: isolate;

  ${mediaQueries.mobile} {
    width: 100%;
    height: 380px;
  }
`;

const Title = styled.h3`
  font-size: 24px;
  font-weight: 600;

  ${mediaQueries.mobile} {
    font-size: 18px;
  }
`;

const Subtitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #9a9a9a;

  ${mediaQueries.mobile} {
    font-size: 15px;
  }
`;

const BgImg = styled.img`
  position: absolute;
  bottom: 0;
  z-index: -1;

  ${mediaQueries.mobile} {
    width: 80%;
    bottom: unset;
    top: 0;
  }
`;

const BackToHomeBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 185px;
  height: 50px;
  background: transparent linear-gradient(181deg, #f8a758 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  margin-top: 18px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
  }

  ${mediaQueries.mobile} {
    height: 45px;
    width: 155px;
  }
`;

import styled from "styled-components";
import { cryptoSaleResponse } from "../../../../../api/cryptoSale/types";
import { TransactionStatusEnum } from "../../../../../../types/common";

export const StatusRenderer = ({ data }: { data: cryptoSaleResponse }) => {
  return (
    <StatusWrapper status={data.status}>
      <p>{TransactionStatusEnum[data.status]}</p>
    </StatusWrapper>
  );
};

interface statusProps {
  status: TransactionStatusEnum;
}

const StatusWrapper = styled.div<statusProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 13px;
  border-radius: 18px;
  border: ${(props) =>
    props.status === TransactionStatusEnum.Declined
      ? "2px solid #EA3A3E"
      : props.status === TransactionStatusEnum.Approved
      ? "2px solid #1ABA27"
      : props.status === TransactionStatusEnum.Partial
      ? "2px solid #FAA84F"
      : "2px solid #ffbf7b"};

  & > p {
    margin: 0;
    color: ${(props) =>
      props.status === TransactionStatusEnum.Declined
        ? "#EA3A3E"
        : props.status === TransactionStatusEnum.Approved
        ? "#1ABA27"
        : props.status === TransactionStatusEnum.Partial
        ? "#FAA84F"
        : "#ffbf7b"};
    font-size: 9px;
    font-weight: bold;
  }
`;

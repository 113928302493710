import HomeLayout from "../../components/homeLayout";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { useState } from "react";
import DeleteModal from "./deleteModal";

const Delete = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HomeLayout backHomeBtn>
      <Container>
        <HeaderWrapper>
          <h1>Optima By CJ Grand Data Handling Information</h1>
          <h4>App Name: Optima By CJ Grand</h4>
        </HeaderWrapper>
        <h3>Data Types Collected:</h3>
        <ol>
          <li>Usernames and passwords</li>
          <li>History of user transactions</li>
          <li>Device tokens for push notifications</li>
        </ol>
        <h3>User Data Handling:</h3>
        <p>
          The app only stores the mentioned data types, which include usernames,
          passwords, transaction history, and device tokens for push
          notifications.
        </p>
        <h3>Data Sharing Policy:</h3>
        <p>We do not share any user data with third parties.</p>
        <h2>Account Deletion Process:</h2>
        <h3>Users can request account deletion by following these steps:</h3>
        <ol>
          <li> Log into the latest version of the app.</li>
          <li>Navigate to "Profile."</li>
          <li>Click on "Delete Account."</li>
          <li>This action is irreversible.</li>
        </ol>
        <h3>Irreversible Action:</h3>
        <p>
          It's important to note that the deletion of the account is
          irreversible, emphasizing that users should proceed with caution.
        </p>
        <h3>Data Retention Policy:</h3>
        <p>
          Depending on user activities, we may retain transaction histories.
          Other personal information will be deleted upon account deletion.
        </p>
        <NB>
          Note: Alternatively, users can also delete their account by clicking
          on the "Delete Account" button on this page.
        </NB>
        <DeleteBtn onClick={() => setIsOpen(true)}>Delete Account</DeleteBtn>
      </Container>
      <DeleteModal handleClose={() => setIsOpen(false)} isOpen={isOpen} />
    </HomeLayout>
  );
};

export default Delete;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: -40px auto 0;
  padding: 30px 80px;
  max-height: 700px;
  overflow: scroll;

  h1 {
    font-size: 20px;
    margin-bottom: 3px;
  }

  h2 {
    font-size: 14px;
    margin: 10px 0 0px;
  }

  h3 {
    font-size: 12px;
    margin: 8px 0 4px;
  }

  h4 {
    font-size: 9px;
  }

  p {
    font-size: 12px;
  }

  ol {
    font-size: 12px;
    margin-left: 24px;
  }

  ${mediaQueries.tablet} {
    max-height: 410px;
  }

  ${mediaQueries.mobile} {
    padding: 0;
    margin: 40px auto 0;
    max-height: calc(100vh - 150px);
  }
`;

const HeaderWrapper = styled.div`
  margin-bottom: 10px;
`;

const NB = styled.h4`
  font-size: 11px !important;
  margin: 10px 0;
`;

const DeleteBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 121px;
  padding: 8px 12px;
  height: 35px;
  background: #d61010;
  color: #fff;
  margin-top: 10px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

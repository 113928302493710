import { ReactComponent as DashbooardIcon } from "../images/Category.svg";
import { ReactComponent as MarketIcon } from "../images/Work.svg";
import { ReactComponent as CardIcon } from "../images/credit-card.svg";
import { ReactComponent as UserIcon } from "../images/users.svg";
import { ReactComponent as CryptoIcon } from "../images/crypto.svg";
import { ReactComponent as SettingsIcon } from "../images/Setting.svg";
import { dropDownType, SideBarObj } from "../types/common";

export const defaultGuid = "00000000-0000-0000-0000-000000000000";

export const BaseUrl = "https://optimaadmin-001-site2.gtempurl.com/api";

export const sidebarData: SideBarObj[] = [
  {
    title: "Dashboard",
    icon: DashbooardIcon,
    link: "/dashboard",
    value: "dashboard",
  },
  {
    title: "Transactions",
    icon: MarketIcon,
    link: "/dashboard/transactions",
    value: "transactions",
    accordionData: [
      {
        title: "Gift Card",
        link: "/dashboard/transactions/sales",
      },
      {
        title: "Withdrawal Requests",
        link: "/dashboard/transactions/withdrawals",
      },
      {
        title: "Cryptocurrency",
        link: "/dashboard/transactions/cryptocurrency",
      },
    ],
  },
  {
    title: "Manage Cards",
    icon: CardIcon,
    link: "/dashboard/cards",
    value: "card",
  },
  {
    title: "Manage Crypto",
    icon: CryptoIcon,
    link: "/dashboard/crypto",
    value: "crypto",
  },
  {
    title: "Manage Users",
    icon: UserIcon,
    link: "/dashboard/users",
    value: "users",
  },
  {
    title: "Preferences",
    icon: SettingsIcon,
    link: "/dashboard/preferences",
    value: "settings",
  },
];

export const AdminPermissions: dropDownType[] = [
  {
    label: "Messages",
    value: "message",
  },
  {
    label: "Dashboard",
    value: "dashboard",
  },
  {
    label: "Transactions",
    value: "transactions",
  },
  {
    label: "Manage Cards",
    value: "card",
  },
  {
    label: "Manage Users",
    value: "users",
  },
  {
    label: "Manage Crypto",
    value: "crypto",
  },
  {
    label: "Preferences",
    value: "settings",
  },
];

export const truncateTxt = (text: string = "", maxLength: number) => {
  return text.length <= maxLength ? text : text.substring(0, maxLength) + "...";
};

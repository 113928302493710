import styled from "styled-components";
import { LoginPayload } from "../../api/auth/types";
import { emailIsValid } from "../../../utils/regex";
import { useFormik } from "formik";
import AuthPasswordInput from "../login/components/passwordInput";
import DualRing from "../../components/loader";
import mediaQueries from "../../../utils/mediaQueries";

interface props {
  handleSubmit: (values: LoginPayload) => void;
  pending: boolean;
}

const LoginContent: React.FC<props> = ({ handleSubmit, pending }) => {
  const validate = (values: LoginPayload) => {
    const errors: any = {};

    if (!values.emailAddress) {
      errors.emailAddress = "Email is required";
    } else if (!emailIsValid(values.emailAddress)) {
      errors.emailAddress = "Email is not valid";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const form = useFormik<LoginPayload>({
    initialValues: {
      emailAddress: "",
      password: "",
    },
    onSubmit: (values: LoginPayload) => {
      handleSubmit(values);
    },
    validate,
    validateOnChange: false,
  });

  const emailError: any = form.errors.emailAddress;
  const passwordError: any = form.errors.password;

  return (
    <ContentWrapper>
      <TopWrapper>
        <p>Login to get authorization</p>
      </TopWrapper>
      <Form onSubmit={form.handleSubmit}>
        <InputWrapper>
          <p>Email Address</p>
          <input
            type="text"
            onChange={form.handleChange}
            id="emailAddress"
            value={form.values.emailAddress}
          />
          {emailError && (
            <span>
              {emailError instanceof Array ? emailError.join(",") : emailError}
            </span>
          )}
        </InputWrapper>
        <AuthPasswordInput
          label={"Password"}
          value={form.values.password}
          onChange={form.handleChange}
          error={passwordError}
          id={"password"}
        />
        <ButtonWrapper>
          <SignInButton type="submit" disabled={pending}>
            {pending ? <DualRing width="10px" height="10px" /> : "Sign In"}
          </SignInButton>
        </ButtonWrapper>
      </Form>
    </ContentWrapper>
  );
};

export default LoginContent;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 45px 10px;
  background: #ffffff;
  border: 1px solid #707070;
  z-index: 2;

  ${mediaQueries.mobile} {
    width: 85%;
    padding: 40px 20px 0;
    margin-top: 20px;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  & > p {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.18px;
    color: #faa84f;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 40px;
  padding-bottom: 25px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  & > p {
    font-size: 14px;
    letter-spacing: -0.1px;
    color: #727272;
  }

  & > input {
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    padding: 0 10px;
    background: #f7f7f7;
  }

  & > span {
    text-align: left;
    font-size: 10px;
    color: #d90b2c;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const SignInButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border: none;
  outline: none;
  width: 139px;
  height: 40px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.9;
  }

  & > div {
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

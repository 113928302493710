import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const ProtectedRoute = ({ children }: { children: any }) => {
  const isLoggedIn = useAuth();
  let location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ path: location.pathname }} replace />;
  }

  return children;
};

export default ProtectedRoute;

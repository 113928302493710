import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";
import { LoaderWrapper } from "../views/Preferences/components/preferences";
import DualRing from "./loader";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  isOpen: boolean;
  isDeleting?: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const DeleteModal: React.FC<props> = ({
  isOpen,
  isDeleting,
  handleClose,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth={"xs"}
      sx={{ borderRadius: 2 }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          fontSize: 21,
          paddingTop: 5,
          paddingLeft: 4,
          paddingRight: 4,
          paddingBottom: 1,
          fontWeight: 500,
        }}
      >
        Sure you want to delete?
      </DialogTitle>
      <DialogContent sx={{ paddingLeft: 4, paddingRight: 4 }}>
        <DialogContentText id="alert-dialog-slide-description">
          This action cannot be reversed. Continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonWrapper>
          <CancelButton onClick={handleClose}>No, Go back</CancelButton>
          <ConfirmButton onClick={handleSubmit}>
            {isDeleting ? (
              <LoaderWrapper>
                <DualRing width="10px" height="10px" />
              </LoaderWrapper>
            ) : (
              "Yes, Delete"
            )}
          </ConfirmButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  width: 100%;
  padding: 20px 15px 15px;
  border-top: 1px solid #c3c3c3;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  letter-spacing: -0.2px;
  font-weight: bold;
  font-size: 12px;
  color: #727272;
  border-radius: 3px;
  cursor: pointer;
`;

interface confirmProps {
  isDisabled?: boolean;
}

const ConfirmButton = styled.div<confirmProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 134px;
  height: 33px;
  background: #ed3838;
  letter-spacing: -0.22px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
`;

import { useEffect, useState } from "react";
import styled from "styled-components";
import { TransactionStatusEnum } from "../../../../../types/common";
import { RecentActivitiesObj } from "../../../../api/dashboard/types";

export const StatusRenderer = ({ data }: { data: RecentActivitiesObj }) => {
  const [statusColor, setStatusColor] = useState("");

  useEffect(() => {
    setStatusColor(handleColorSelector(data.status));
  }, [data.status]);

  const handleColorSelector = (status: number) => {
    switch (status) {
      case TransactionStatusEnum.Pending:
        return "#FAA84F";
      case TransactionStatusEnum.Partial:
        return "#00912d";
      case TransactionStatusEnum.Approved:
        return "#20C353";
      case TransactionStatusEnum.Declined:
        return "#ED3838";
      default:
        return "#FFFFFF";
    }
  };

  return (
    <Container>
      <StatusWrapper color={statusColor} status={data.status}>
        <CustomCircle width={6} color={statusColor} />
        <p>{TransactionStatusEnum[data.status].toUpperCase()}</p>
      </StatusWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & > img {
    width: 13px;
    height: 13px;
  }
`;

interface statusProps {
  color: string;
  status: number;
}

const StatusWrapper = styled.div<statusProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 7px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.status === TransactionStatusEnum.Pending
      ? "#FAA84F26"
      : props.status === TransactionStatusEnum.Partial
      ? "#20c3534f"
      : props.status === TransactionStatusEnum.Declined
      ? "#ED383826"
      : "#20C35326"};

  & > p {
    margin: 0;
    font-size: 8px;
    font-weight: 800;
    color: ${(props) => props.color};
  }
`;

interface circleProps {
  color: string;
  width: number;
}

export const CustomCircle = styled.span<circleProps>`
  border-radius: 50%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  background-color: ${(props) => props.color};
`;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CryptoSalesState } from "./types";
import { getCryptoSaleAction } from "./actions";
import { RootState } from "..";

const initialState: CryptoSalesState = {
  getCryptoSales: {
    error: null,
    pending: false,
  },
  refreshNeeded: false,
};

const cryptoSaleSlice = createSlice({
  name: "cryptoSales",
  initialState,
  reducers: {
    refreshCryptoSaleNeeded: (state, action: PayloadAction<boolean>) => {
      state.refreshNeeded = action.payload;
    },
    resetCrypoSale: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getCryptoSaleAction.pending, (state) => {
        return {
          ...state,
          getCryptoSales: {
            ...state.getCryptoSales,
            pending: true,
            error: null,
            response: undefined,
          },
        };
      })
      .addCase(getCryptoSaleAction.fulfilled, (state, { payload }) => {
        return {
          ...state,
          getCryptoSales: {
            ...state.getCryptoSales,
            pending: false,
            error: null,
            response: payload,
          },
        };
      })
      .addCase(getCryptoSaleAction.rejected, (state, action) => {
        return {
          ...state,
          getCryptoSales: {
            ...state.getCryptoSales,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
  },
});

export const { refreshCryptoSaleNeeded, resetCrypoSale } =
  cryptoSaleSlice.actions;

export const refreshCryptoSaleSelector = (state: RootState) =>
  state.cryptoSale.refreshNeeded;

export const getCryptoselector = (state: RootState) => state;

export default cryptoSaleSlice.reducer;

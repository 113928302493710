import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ReactComponent as UploadIcon } from "../../../../images/upload-card.svg";
import DualRing from "../../../components/loader";
import { DocumentWrapper, UploadImageWrapper } from "../../Cards/add";
import { CreateCountryPayload } from "../../../api/countries/types";
import api from "../../../api";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  isOpen: boolean;
  handleClose: () => void;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const CountryModal: React.FC<props> = ({
  isOpen,
  handleClose,
  setShouldRefresh,
}) => {
  const [formData, setFormData] = React.useState<CreateCountryPayload>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [documentToUpload, setDocumentToUpload] = React.useState<File>();
  const [documentToDisplay, setDocumentToDisplay] = React.useState<string>();

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleCloseModal = () => {
    handleClose();
    setFormData({ countryName: "", logo: undefined });
  };

  const selectdocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let file = e!.target!.files![0];
      // check if file is greater than 1mb
      if (file.size > 1e6) {
        toast.error("Please upload file less than 1mb");
        return;
      }
      if (file.type.includes("image")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          if (event.target && event.target.result) {
            setDocumentToDisplay(event.target.result.toString());
            setDocumentToUpload(file);
          }
        });
      }
    }
  };

  //used any type to target the id of the element
  const handleFilePicking = (e: any) => {
    if (e.target.id !== "delete") {
      document!.getElementById("document")!.click();
    }
  };

  const handleFileDelete = () => {
    setDocumentToDisplay("");
    setDocumentToUpload(undefined);
  };

  const handleSubmit = async () => {
    if (!documentToUpload) {
      toast.error("Please upload a picture");
      return;
    }

    setIsLoading(true);
    if (formData) {
      try {
        const res = await api.countriesService.addCountry({
          ...formData,
          logo: documentToUpload,
        });
        res?.data.data && toast.success("Country has been created");
        setShouldRefresh(true);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    handleCloseModal();
    setIsLoading(false);
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseModal}
      fullWidth
      maxWidth={"xs"}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          fontSize: 22,
          paddingTop: 5,
          paddingBottom: 3,
          paddingLeft: 5,
          fontFamily: "Plus Jakarta Sans",
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        New Country Details
      </DialogTitle>
      <DialogContent
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <DialogContentText id="alert-dialog-slide-description">
          <ContentWrapper>
            <ShortCodeWrapper>
              <p>Country short code</p>
              <input
                name="countryName"
                value={formData?.countryName}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </ShortCodeWrapper>
            <ImageWrapper>
              <p>Upload Image</p>
              <UploadWrapper>
                <input
                  type="file"
                  id="document"
                  accept="image/png,image/jpg,image/jpeg"
                  onChange={selectdocument}
                />
                {!documentToDisplay ? (
                  <UploadImageWrapper
                    onClick={() =>
                      document!.getElementById("document")!.click()
                    }
                  >
                    <UploadIcon />
                    <p>Browse files</p>
                    <span>Supported formats: PNG, JPG, JPEG</span>
                  </UploadImageWrapper>
                ) : (
                  <DocumentWrapper onClick={(e) => handleFilePicking(e)}>
                    <img src={documentToDisplay} alt="" />
                    <span id="delete" onClick={() => handleFileDelete()}>
                      x
                    </span>
                  </DocumentWrapper>
                )}
              </UploadWrapper>
            </ImageWrapper>
          </ContentWrapper>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <ButtonWrapper>
          <CancelButton onClick={() => handleCloseModal()}>Cancel</CancelButton>
          <ConfirmButton onClick={() => handleSubmit()}>
            {isLoading ? (
              <DualRing width="10px" height="10px" />
            ) : (
              "Save Country"
            )}
          </ConfirmButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default CountryModal;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding: 20px 0px 15px;
  border-top: 1px solid #c3c3c3;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  letter-spacing: -0.2px;
  font-weight: bold;
  font-size: 12px;
  color: #727272;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    color: #4d4b4b;
  }
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  background: #1aba27;
  letter-spacing: -0.22px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;

  & > div {
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > textarea {
    height: 135px;
    padding: 10px;
    background: #ffffff;
    border: 0.5px solid #707070;
    border-radius: 5px;
    outline: none;
  }
`;

const ShortCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;

  & > p {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 10px;
    font-weight: bold;
  }

  & > input {
    padding-left: 8px;
    height: 35px;
    background: #ffffff;
    border: 0.5px solid #707070;
    border-radius: 3px;
  }
`;

const ImageWrapper = styled(ShortCodeWrapper)`
  width: 90%;
`;

const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #727272;
  border-radius: 5px;
  width: 100%;
  height: 165px;

  & > input {
    display: none;
  }
`;

import { ReactComponent as BlockIcon } from "../../../../../images/block.svg";
import { ReactComponent as PenIcon } from "../../../../../images/pen.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { ProfileResponse } from "../../../../api/auth/types";
import { refreshUsers } from "../../../../redux/users/userSlice";
import api from "../../../../api";
import { useAppDispatch } from "../../../../redux";
import Modal from "../../../Users/components/modal";
import { useMobileCheck } from "../../../../../hooks/useMobileCheck";
import mediaQueries from "../../../../../utils/mediaQueries";

const ActionRenderer = ({ data }: { data: ProfileResponse }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { isDesktop } = useMobileCheck();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleSubmit = async () => {
    setisLoading(true);
    try {
      const res = await api.userService.blockUser({
        userName: data.emailAddress,
      });
      toast.success(res.data.responseMessage);
      dispatch(refreshUsers(true));
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setisLoading(false);
    setIsOpen(false);
  };

  return (
    <Container>
      <ManageWrapper to={`/dashboard/admins/manage/${data.userId}`}>
        {isDesktop && <PenIcon />}
        <p>Manage</p>
      </ManageWrapper>
      <IconWrapper onClick={() => handleOpen()}>
        <BlockIcon />
      </IconWrapper>
      <Modal
        isLoading={isLoading}
        isDisabled={false}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        handleSubmit={() => handleSubmit()}
      />
    </Container>
  );
};

export default ActionRenderer;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ManageWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  width: 106px;
  height: 30px;
  background: #727272;
  border-radius: 3px;
  cursor: pointer;

  & > svg {
    width: 9px;
    height: 10px;
  }

  & > p {
    letter-spacing: -0.1px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
  }

  ${mediaQueries.mobileAndTablet} {
    width: unset;
    padding: 0 12px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 5px 10px;
  height: 30px;
  background: #f23030;
  border-radius: 3px;
  cursor: pointer;

  & > svg {
    width: 13px;
    height: 13px;
  }
`;

import { createAsyncThunk } from "@reduxjs/toolkit";
import parseError from "../../../utils/parseError";
import api from "../../api";

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (payload, thunkAPI) => {
    try {
      const response = await api.notificationService.getNotifications();
      return response.data;
    } catch (error) {
      let errorMessage = parseError(error as any);
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const readNotification = createAsyncThunk(
  "notifications/readNotification",
  async (payload: string, thunkAPI) => {
    try {
      const response = await api.notificationService.ReadNotification(payload);
      response.data.data && thunkAPI.dispatch(getNotifications());
      return response.data.data;
    } catch (error) {
      let errorMessage = parseError(error as any);
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

import axios, { AxiosError } from "axios";
import storage from "../../utils/storage";
import { store } from "../redux";
import { refreshToken } from "../redux/auth/actions";

const baseURL = process.env.REACT_APP_BASE_URL;
const request = axios.create({
  baseURL,
});

request.interceptors.request.use((config) => {
  const {
    auth: { accessToken: access_token },
  } = store.getState();

  const newConfig = { ...config };

  if (access_token) {
    newConfig.headers = {
      ...newConfig.headers,
      Authorization: `Bearer ${access_token}`,
    };
  }

  return newConfig;
});

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    if (error.response?.status === 400) {
      const errorInArray = error.response?.data?.errors
        ? (Object.values(error.response?.data?.errors)[0] as string[])
        : [];
      return Promise.reject(
        error.response?.data?.responseMessage ||
          error.response?.data?.errors[0] ||
          errorInArray[0]
      );
    }

    if (error.response?.status === 403) {
      return Promise.reject(
        "Sorry! You're not authorized to perform this action"
      );
    }

    // if user's token has expired or has been blacklisted
    if (error.response?.status === 401) {
      const {
        auth: { accessToken, refreshToken: refToken },
      } = store.getState();
      if (accessToken && refToken && !storage.get("isRefreshTokenCalled")) {
        storage.set("isRefreshTokenCalled", true);
        store.dispatch(
          refreshToken({
            accessToken: accessToken,
            refreshToken: refToken,
          })
        );
      }
    }
    return Promise.reject(error);
  }
);

export default request;

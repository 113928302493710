import styled from 'styled-components';

const EmptyStateRenderer = () => {
    return (
        <EmptyWrapper>
            <p>Nothing to see here</p>
            <p>Looks like nobody matches that search.</p>
        </EmptyWrapper>
    )
}

export default EmptyStateRenderer

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  & > p:first-of-type {
    letter-spacing: 0px;
    color: #FAA84F;
    font-size: 20px;
    font-weight: bold;
  }

  & > p:last-of-type {
    letter-spacing: 0px;
    color: #6F6F6F;
    font-size: 18px;
  }
`;
import styled from "styled-components";
import { motion } from "framer-motion";
import { componentSlide } from "../../../components/animate/variants";
import { useAppSelector } from "../../../redux";
import { getAuth } from "../../../redux/auth/authSlice";
import { useEffect, useState } from "react";
import { BasicInfoPayload } from "../../../api/auth/types";
import {
  BottomWrapper,
  ButtonWrapper,
  CancelButton,
  SaveButton,
} from "./security";
import CancelModal from "../../../components/cancelModal";
import { useNavigate } from "react-router";
import mediaQueries from "../../../../utils/mediaQueries";

const BasicInfo = () => {
  const [formData, setformData] = useState<BasicInfoPayload>({});
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const navigate = useNavigate();

  const {
    profile: { response },
  } = useAppSelector(getAuth);

  useEffect(() => {
    setformData({
      fullName: response?.name,
      phoneNumber: response?.phoneNumber,
    });
  }, [response]);

  const handleChange = (name: string, value: string) => {
    setformData({ ...formData, [name]: value });
  };

  const isValid = Object.values(formData).every((x) => x !== "");

  const handleSubmit = async () => {};

  return (
    <Container
      variants={componentSlide}
      exit={"exit"}
      animate={"animate"}
      initial={"initial"}
      transition={{ duration: 0.5 }}
    >
      <EntryWrapper>
        <EntryTitle>Username</EntryTitle>
        <EntryInfo fullWidth>{response?.emailAddress}</EntryInfo>
      </EntryWrapper>
      <EntryWrapper>
        <EntryTitle>Admin Role</EntryTitle>
        <EntryInfo>{response?.roles[0] || "Admin"}</EntryInfo>
      </EntryWrapper>
      <EntryWrapper>
        <EntryTitle>Full Name</EntryTitle>
        <input
          name="fullName"
          value={formData?.fullName}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </EntryWrapper>
      <EntryWrapper>
        <EntryTitle>Phone Number</EntryTitle>
        <input
          name="phoneNumber"
          value={formData?.phoneNumber}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </EntryWrapper>
      <EntryWrapper multiple>
        <EntryTitle>Admin Permissions</EntryTitle>
        <EntryInfoWrapper>
          {response?.permissions.map((x, i) => (
            <Permissions key={i}>{x}</Permissions>
          ))}
        </EntryInfoWrapper>
      </EntryWrapper>
      <BottomWrapper>
        <ButtonWrapper>
          <CancelButton onClick={() => setIsCancelOpen(true)}>
            Cancel
          </CancelButton>
          <SaveButton onClick={() => handleSubmit()} disabled={!isValid}>
            Save Changes
          </SaveButton>
        </ButtonWrapper>
      </BottomWrapper>
      <CancelModal
        handleClose={() => setIsCancelOpen(false)}
        isOpen={isCancelOpen}
        handleSubmit={() => navigate("/dashboard", { replace: true })}
      />
    </Container>
  );
};

export default BasicInfo;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  padding: 0px 50px 20px;

  ${mediaQueries.tablet} {
    padding: 0;
  }

  ${mediaQueries.mobile} {
    padding: 20px 0 0;
  }
`;

interface entryProp {
  multiple?: boolean;
}
export const EntryWrapper = styled.div<entryProp>`
  display: flex;
  align-items: ${(props) => (props.multiple ? "flex-start" : "center")};
  gap: 40px;

  & > input {
    border: 0.5px solid #b5b5b5;
    border-radius: 5px;
    width: 405px;
    height: 37px;
    outline: none;
    padding: 0 15px;
  }

  ${mediaQueries.tablet} {
    gap: 25px;
  }

  ${mediaQueries.mobile} {
    gap: 15px;

    & > input {
      width: 270px;
    }
  }
`;

export const EntryTitle = styled.p`
  color: #000000;
  font-size: 13px;
  font-weight: bold;
  width: 120px;

  ${mediaQueries.tablet} {
    flex-shrink: 0;
    width: 141px;
  }

  ${mediaQueries.mobile} {
    flex-shrink: 0;
    font-size: 12px;
    width: 112px;
  }
`;

interface infoProps {
  fullWidth?: boolean;
}

const EntryInfo = styled.div<infoProps>`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: ${(props) => (props.fullWidth ? "405px" : "fit-content")};
  background: #eaeaea;
  color: #727272;
  font-size: 12px;
  border: 0.5px solid #dbdbdb;
  border-radius: 5px;
`;

const Permissions = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  background: #eaeaea;
  color: #727272;
  font-size: 12px;
  border: 0.5px solid #dbdbdb;
  border-radius: 5px;
`;

const EntryInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 405px;
  height: 194px;
  overflow: scroll;
  background: #ffffff;
  border: 0.5px solid #b5b5b5;
  border-radius: 5px;
`;

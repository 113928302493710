import styled from "styled-components";
import HomeLayout from "../../components/homeLayout";
import appStoreImg from "../../../images/AppStore.png";
import playStoreImg from "../../../images/PlayStore.png";
import phone1 from "../../../images/phone1.png";
import phone2 from "../../../images/phone2.png";
import mediaQueries from "../../../utils/mediaQueries";
import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";

const Home = () => {
  const titleRef = useRef(null);
  const app = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      var tl = gsap.timeline({ defaults: { opacity: 0, ease: "back" } });
      tl.from(titleRef.current, { x: 80, duration: 1 })
        .from(
          ".subtitle",
          {
            x: -80,
            duration: 1,
          },
          "<"
        )
        .from(".phone1", { y: 200, duration: 1 })
        .from(".phone2", { y: 200, duration: 1.5 }, "-=0.6")
        .from(".playstore", { duration: 1 }, "-=0.5")
        .from(".appstore", { duration: 1 }, "-=0.7");
    }, app);

    return () => ctx.revert();
  }, []);

  return (
    <HomeLayout isHome>
      <Container ref={app}>
        <LeftWrapper>
          <Title ref={titleRef}>
            Simplest way to <br /> <span>trade your gift cards and Crypto</span>
          </Title>
          <Subtitle className="subtitle">
            With a wide range of gift cards options and the best crypto rates,
            we put your needs first. Start cashing out today.
          </Subtitle>
          <BtnWrapper>
            <BtnLink href="https://play.google.com/store/apps/details?id=com.optima.app">
              <PlayStoreImg
                src={playStoreImg}
                alt={"play store"}
                className="playstore"
              />
            </BtnLink>
            <BtnLink href="https://apps.apple.com/us/app/optima-by-cj-grand-exchange/id1659231337">
              <AppStoreImg
                src={appStoreImg}
                alt={"app store"}
                className="appstore"
              />
            </BtnLink>
          </BtnWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Phone1 src={phone1} className={"phone1"} />
          <Phone2 src={phone2} className={"phone2"} />
        </RightWrapper>
      </Container>
    </HomeLayout>
  );
};

export default Home;

const Container = styled.div`
  display: flex;
  margin: 85px auto 0;
  max-width: 1350px;
  position: relative;

  ${mediaQueries.mobile} {
    margin: 20px auto 0;
  }
`;

const LeftWrapper = styled.div`
  width: 42%;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const Title = styled.p`
  font-size: 57px;
  font-weight: 700;
  letter-spacing: -1.16px;
  color: #000;
  margin: 30px 0 20px;

  & span {
    color: #fca944;
  }

  ${mediaQueries.smallDesktop} {
    font-size: 48px;
  }

  ${mediaQueries.mobile} {
    font-size: 42px;
  }
`;

const Subtitle = styled.p`
  font-size: 22px;
  letter-spacing: -0.44px;
  font-weight: 500;
  line-height: 32px;
  color: #000000;
  width: 85%;

  ${mediaQueries.smallDesktop} {
    font-size: 20px;
  }

  ${mediaQueries.mobile} {
    font-size: 16px;
    width: 100%;
  }
`;

const BtnWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 44px;

  ${mediaQueries.mobile} {
    z-index: 2;
  }
`;

const BtnLink = styled.a`
  transition: all 0.25s ease;

  &:hover {
    transform: translateY(-3px);
  }
`;

const PlayStoreImg = styled.img`
  cursor: pointer;
`;

const AppStoreImg = styled(PlayStoreImg)``;

const RightWrapper = styled.div`
  position: relative;
  flex-grow: 1;

  /* ${mediaQueries.mobile} {
    display: none;
  } */
`;

const Phone1 = styled.img`
  position: absolute;
  top: -55px;
  left: -45px;
  height: 800px;
  z-index: 2;

  ${mediaQueries.smallDesktop} {
    left: -175px;
  }

  ${mediaQueries.mobile} {
    display: none;
  }
`;

const Phone2 = styled.img`
  position: absolute;
  top: -60px;
  right: -90px;

  ${mediaQueries.mobile} {
    top: unset;
    bottom: -237px;
    right: -140px;
    width: 360px;
  }
`;

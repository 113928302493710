import styled from "styled-components";
import { ReactComponent as TickIcon } from "../../../../../images/profile-check.svg";
import { ProfileResponse } from "../../../../api/auth/types";

const NameRenderer = ({ data }: { data: ProfileResponse }) => {
  return (
    <Container>
      <p>{data.name}</p>
      {data.verified && <TickIcon />}
    </Container>
  );
};

export default NameRenderer;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > p {
    font-weight: 600;
    letter-spacing: -0.02em;
    font-size: 12px;
    line-height: 18px;
  }

  & > svg {
    width: 12px;
    height: 12px;
  }
`;

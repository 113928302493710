import styled from "styled-components";
import moment from "moment";
import { NotificationResponse } from "../../api/notifications/types";
import { useAppDispatch } from "../../redux";
import { markAsRead } from "../../redux/notifications/notificationSlice";
import { readNotification } from "../../redux/notifications/actions";
import { NotificationDisplayType } from "../../../types/common";
import { useNavigate } from "react-router";

interface props {
  data: NotificationResponse;
}

const Entry: React.FC<props> = ({ data }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleMarkAsRead = () => {
    dispatch(readNotification(data.notificationId));
    dispatch(markAsRead(data.notificationId));
    if (data.notificationDisplayType === NotificationDisplayType.CARD_SALE) {
      navigate(`transactions/sales/${data.displayNotificationId}`);
    } else if (
      data.notificationDisplayType === NotificationDisplayType.WITHDRAWALS
    ) {
      navigate(`transactions/withdrawals/${data.displayNotificationId}`);
    }
  };

  return (
    <Container onClick={() => handleMarkAsRead()} isRead={data.isRead}>
      <AvatarImg />
      <ContentWrapper>
        <Content>{data.message}</Content>
        <span>{moment(data.dateCreated).fromNow()}</span>
      </ContentWrapper>
    </Container>
  );
};

export default Entry;

interface entryProps {
  isRead?: boolean;
}

const Container = styled.div<entryProps>`
  display: flex;
  gap: 20px;
  height: 70px;
  background: ${(props) => (props.isRead ? "#fff" : "#faa84f1a")};
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.2ms ease-in;
`;

const AvatarImg = styled.div`
  width: 34px;
  height: 34px;
  background: #fff;
  border-radius: 50%;
  flex-shrink: 0;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > span {
    letter-spacing: -0.08px;
    color: #727272;
    font-size: 10px;
  }
`;

const Content = styled.div`
  font-size: 12px;
  letter-spacing: -0.1px;
  color: #000000;

  & > span {
    font-weight: 600;
  }
`;

import { ApiResponse } from "../../redux/types";
import request from "../request";
import { NotificationResponse } from "./types";

const getNotifications = async () => {
  return await request.get<ApiResponse<NotificationResponse[]>>(
    "/notification/admin"
  );
};

const ReadNotification = async (id: string) => {
  return await request.post<ApiResponse<boolean>>(`/notification/read/${id}`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getNotifications,
  ReadNotification,
};

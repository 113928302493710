import { ApiResponse } from "../../redux/types";
import request from "../request";
import { blockUserPayload, userProfileResponse, UsersResponse } from "./types";

const getActiveUsers = async (pageIndex: number, keyword?: string) => {
  return await request.get<UsersResponse>(
    keyword
      ? `/User/Active?Filter=FullName&Keyword=${keyword}`
      : `/User/Active?pageSize=10&pageIndex=${pageIndex}`
  );
};
const getInactiveUsers = async (pageIndex: number, keyword?: string) => {
  return await request.get<UsersResponse>(
    keyword
      ? `/User/InActive?Filter=FullName&Keyword=${keyword}`
      : `/User/InActive?pageSize=10&pageIndex=${pageIndex}`
  );
};
const getDisabledUsers = async (pageIndex: number, keyword?: string) => {
  return await request.get<UsersResponse>(
    keyword
      ? `/User/Disabled?Filter=FullName&Keyword=${keyword}`
      : `/User/Disabled?pageSize=10&pageIndex=${pageIndex}`
  );
};
const getUserProfile = async (id: string) => {
  return await request.get<ApiResponse<userProfileResponse>>(
    `/User/BankAndTransactionDetails/${id}`
  );
};
const blockUser = async (payload: blockUserPayload) => {
  return await request.post<ApiResponse<boolean>>(
    `/Authentication/Lockoutuser`,
    payload
  );
};
const unblockUser = async (payload: blockUserPayload) => {
  return await request.post<ApiResponse<boolean>>(
    `/Authentication/UnlockUser`,
    payload
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getActiveUsers,
  getInactiveUsers,
  getDisabledUsers,
  getUserProfile,
  blockUser,
  unblockUser,
};

import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "../components/layout";
import Dashboard from "../views/Dashboard";
import Login from "../views/login/login";
import Users from "../views/Users";
import ManageUsers from "../views/Users/manage";
import ForgotPassword from "../views/forgotPassword";
import Cards from "../views/Cards";
import NewCard from "../views/Cards/add";
import ResetPassword from "../views/resetPassword";
import SelectType from "../views/Cards/selectType";
import ManageCards from "../views/Cards/configure";
import ProtectedRoute from "./ProtectedRoute";
import Profile from "../views/Profile";
import NotFound from "../views/notFound";
import EditCard from "../views/Cards/edit";
import CardSales from "../views/Transactions/cardSales";
import TransactionDetail from "../views/Transactions/cardSales/details";
import Admins from "../views/Admins";
import ManageAdmin from "../views/Admins/manage";
import Preferences from "../views/Preferences";
import Withdrawals from "../views/Transactions/withdrawals";
import WithdrawalDetail from "../views/Transactions/withdrawals/details";
import Messages from "../views/Messages";
import VerifyEmail from "../views/verifyEmail";
import Privacy from "../views/Privacy";
import Home from "../views/Home";
import ContactUs from "../views/ContactUs";
import Cryptocurrency from "../views/Transactions/cryptocurrency";
import CryptocurrencyDetail from "../views/Transactions/cryptocurrency/details";
import Crypto from "../views/Crypto";
import NewCrypto from "../views/Crypto/add";
import ConfigureCrypto from "../views/Crypto/configure";
import EditCrypto from "../views/Crypto/edit";
import ConfiguredEditCrypto from "../views/Crypto/edit/configure";
import Delete from "../views/DeleteAccount";
import Terms from "../views/Terms";

const AppRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <Routes key={location.pathname} location={location}>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/data" element={<Delete />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset" element={<ForgotPassword />} />
      <Route path="/verify-email/:token/:email" element={<VerifyEmail />} />
      <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />

      <Route path="/dashboard" element={<Layout />}>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/cards"
          element={
            <ProtectedRoute>
              <Cards />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/cards/type"
          element={
            <ProtectedRoute>
              <SelectType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/cards/configure/:id"
          element={
            <ProtectedRoute>
              <ManageCards />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/cards/add/:id"
          element={
            <ProtectedRoute>
              <NewCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/cards/edit/:id"
          element={
            <ProtectedRoute>
              <EditCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/crypto"
          element={
            <ProtectedRoute>
              <Crypto />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/crypto/add"
          element={
            <ProtectedRoute>
              <NewCrypto />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/crypto/configure"
          element={
            <ProtectedRoute>
              <ConfigureCrypto />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/crypto/edit/:id"
          element={
            <ProtectedRoute>
              <EditCrypto />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/crypto/edit/configure/:id"
          element={
            <ProtectedRoute>
              <ConfiguredEditCrypto />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/users/manage/:id"
          element={
            <ProtectedRoute>
              <ManageUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/preferences"
          element={
            <ProtectedRoute>
              <Preferences />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/transactions/sales"
          element={
            <ProtectedRoute>
              <CardSales />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/transactions/sales/:id"
          element={
            <ProtectedRoute>
              <TransactionDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/transactions/cryptocurrency"
          element={
            <ProtectedRoute>
              <Cryptocurrency />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/transactions/cryptocurrency/:id"
          element={
            <ProtectedRoute>
              <CryptocurrencyDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/transactions/withdrawals"
          element={
            <ProtectedRoute>
              <Withdrawals />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/transactions/withdrawals/:id"
          element={
            <ProtectedRoute>
              <WithdrawalDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/admins"
          element={
            <ProtectedRoute>
              <Admins />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/admins/manage/:id"
          element={
            <ProtectedRoute>
              <ManageAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/messages"
          element={
            <ProtectedRoute>
              <Messages />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;

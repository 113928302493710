import styled from "styled-components";
import optimaLogo from "../../images/optimaLogo.png";
import homeBg from "../../images/home-bg.png";
import hexagonImg from "../../images/hexagon.svg";
import { FC, useEffect } from "react";
import mediaQueries from "../../utils/mediaQueries";
import { Link } from "react-router-dom";

interface props {
  children: any;
  backHomeBtn?: boolean;
  isHome?: boolean;
}

const HomeLayout: FC<props> = ({ children, backHomeBtn, isHome = false }) => {
  useEffect(() => {
    // Tawk.to script initialization
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/632db7d554f06e12d89676c1/1gdl9c7af";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0?.parentNode?.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <Container>
      <ContentWrapper>
        <TopContent>
          <Link to={"/"}>
            <Logo src={optimaLogo} />
          </Link>
          <LeftContent>
            {backHomeBtn ? (
              <BackHomeBtn to={"/"}>Back To Home</BackHomeBtn>
            ) : (
              <>
                <HeaderTxt>Have any question?</HeaderTxt>
                <HeaderBtn to={"/contact-us"}>Contact Us</HeaderBtn>
              </>
            )}
          </LeftContent>
        </TopContent>
        <BottomContent>{children}</BottomContent>
        <Bg src={homeBg} />
      </ContentWrapper>
      <BottomTxt>
        Optima Technology Solutions {new Date().getFullYear()}
      </BottomTxt>
      {isHome && <Hexagon src={hexagonImg} />}
    </Container>
  );
};

export default HomeLayout;

const Container = styled.div`
  position: relative;
  width: 90%;
  padding-bottom: 80px;
  margin: auto;
  height: 100vh;
  overflow: hidden;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  padding-top: 40px;
  height: calc(100vh - 100px);
  width: 100%;
  isolation: isolate;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;

  ${mediaQueries.tablet} {
    padding-top: 30px;
    height: calc(100vh - 50px);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  ${mediaQueries.mobile} {
    padding-top: 20px;
    height: 100vh;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaQueries.mobile} {
    align-items: center;
    width: 90%;
    margin: 10px auto 0;
  }
`;

const BottomContent = styled.div`
  margin-top: 80px;

  ${mediaQueries.mobile} {
    margin-top: 20px;
    width: 90%;
    margin: auto;
  }
`;

const Logo = styled.img`
  width: 180px;
  height: 42px;
  flex-shrink: 0;

  ${mediaQueries.mobile} {
    width: 120px;
    height: 32px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HeaderTxt = styled.p`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;

  ${mediaQueries.mobile} {
    display: none;
  }
`;

const HeaderBtn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 121px;
  height: 35px;
  background: transparent linear-gradient(181deg, #f8a758 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

const BackHomeBtn = styled(HeaderBtn)`
  width: 150px;
`;

const Bg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const BottomTxt = styled.p`
  font-size: 14px;
  letter-spacing: 0px;
  color: #9a9a9a;
  margin: 18px 55px 0;

  ${mediaQueries.mobile} {
    font-size: 13px;
    position: absolute;
    bottom: 10px;
    left: -33px;
  }
`;

const Hexagon = styled.img`
  position: absolute;
  bottom: 54px;
  right: 120px;

  ${mediaQueries.mobile} {
    bottom: 25px;
    right: unset;
    left: 0px;
  }
`;

import { useState } from "react";
import { EntryTitle } from "../../Profile/components/basicInfo";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { motion } from "framer-motion";
import { componentSlide } from "../../../components/animate/variants";
import api from "../../../api";
import { toast } from "react-toastify";
import { LoaderWrapper } from "./preferences";
import DualRing from "../../../components/loader";

const PushNotifications = () => {
  const [formData, setFormData] = useState({ title: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await api.pushNotificationService.PushNotification(formData);
      res && toast.success("Push notifications has been sent");
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  };

  const isValid = Object.values(formData).every((x) => x !== "");

  return (
    <Container
      variants={componentSlide}
      exit={"exit"}
      animate={"animate"}
      initial={"initial"}
      transition={{ duration: 0.5 }}
    >
      <EntryWrapper>
        <EntryTitle>Message Title</EntryTitle>
        <TitleInput
          value={formData.title}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          name={"title"}
        />
      </EntryWrapper>
      <EntryWrapper>
        <EntryTitle>Message Body</EntryTitle>
        <BodyArea
          value={formData.message}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          name={"message"}
        />
      </EntryWrapper>
      <SubmitBtn onClick={() => handleSubmit()} disabled={!isValid}>
        {isLoading ? (
          <LoaderWrapper>
            <DualRing width="10px" height="10px" />
          </LoaderWrapper>
        ) : (
          "Send push notification"
        )}
      </SubmitBtn>
    </Container>
  );
};

export default PushNotifications;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 645px;
  height: 446px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 27px 25px;
  border-radius: 5px;
`;

const EntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  ${mediaQueries.tablet} {
    gap: 25px;
  }

  ${mediaQueries.mobile} {
    gap: 15px;
  }
`;

export const TitleInput = styled.input`
  width: 100%;
  height: 37px;
  padding-left: 12px;
  color: #000000;
  font-size: 12px;
  background: #ffffff;
  border: 0.5px solid #b9b9b9;
  border-radius: 5px;
  outline: none;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const BodyArea = styled.textarea`
  padding: 12px;
  width: 100%;
  font-size: 12px;
  height: 200px;
  outline: none;
  background: #ffffff;
  border: 1px solid #b9b9b9;
  border-radius: 5px;

  ${mediaQueries.smallDesktop} {
    width: 100%;
    outline: #faa84f;
  }
`;

const SubmitBtn = styled.button`
  height: 40px;
  background: transparent linear-gradient(179deg, #2b5a9d 0%, #041630 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-top: 10px;
  letter-spacing: -0.12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:disabled {
    background: #eaeaea;
    color: #000;
    cursor: not-allowed;
  }
`;

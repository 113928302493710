export const cardsData = [
  {
    id: 1,
    title: "Regular Gift Cards",
    examples: "Apple, Macy’s, Footlocker, Sephora Xbox, Steam etc.",
  },
  {
    id: 2,
    title: "Amazon Gift Cards",
  },
  {
    id: 3,
    title: "Special Gift Cards",
    examples: "VISA, AMEX, Vanilla etc.",
  },
];

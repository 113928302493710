import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAndSearchObj } from "../../../types/common";
import parseError from "../../../utils/parseError";
import api from "../../api";

export const getActiveCards = createAsyncThunk(
  "card/getActiveCards",
  async (payload: getAndSearchObj, thunkAPI) => {
    const { currentPage, filter } = payload;
    try {
      const response = await api.cardService.getActiveCards(
        currentPage,
        filter
      );
      return response.data;
    } catch (error) {
      let errorMessage = parseError(error as any);
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
export const getInactiveCards = createAsyncThunk(
  "card/getInactiveCards",
  async (payload: getAndSearchObj, thunkAPI) => {
    const { currentPage, filter } = payload;
    try {
      const response = await api.cardService.getInactiveCards(
        currentPage,
        filter
      );
      return response.data;
    } catch (error) {
      let errorMessage = parseError(error as any);
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
export const getDisabledCards = createAsyncThunk(
  "card/getDisabledCards",
  async (payload: getAndSearchObj, thunkAPI) => {
    const { currentPage, filter } = payload;
    try {
      const response = await api.cardService.getDisabledCards(
        currentPage,
        filter
      );
      return response.data;
    } catch (error) {
      let errorMessage = parseError(error as any);
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

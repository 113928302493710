import { ReactComponent as BlockIcon } from "../../../../../../images/block.svg";
import styled from "styled-components";
import { useState } from "react";
import Modal from "../../modal";
import api from "../../../../../api";
import { toast } from "react-toastify";
import { refreshUsers } from "../../../../../redux/users/userSlice";
import { useAppDispatch } from "../../../../../redux";
import { UsersResponse } from "../../../../../api/users/types";
import { useMobileCheck } from "../../../../../../hooks/useMobileCheck";

const ActionRenderer = ({ data }: { data: UsersResponse }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { isMobile } = useMobileCheck();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleSubmit = async () => {
    setisLoading(true);
    try {
      const res = await api.userService.unblockUser({
        userName: data.emailAddress,
      });
      toast.success(res.data.responseMessage);
      dispatch(refreshUsers(true));
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setisLoading(false);
    setIsOpen(false);
  };

  return (
    <Container>
      <IconWrapper onClick={() => handleOpen()}>
        {!isMobile && <BlockIcon />}
        <p>{isMobile ? `Enable` : `Enable User`}</p>
      </IconWrapper>
      <Modal
        isLoading={isLoading}
        isDisabled={true}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        handleSubmit={() => handleSubmit()}
      />
    </Container>
  );
};

export default ActionRenderer;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 5px 22px;
  height: 30px;
  background: #1aba27;
  border-radius: 3px;
  cursor: pointer;

  & > p {
    font-size: 11px;
    letter-spacing: -0.09px;
    color: #ffffff;
    margin: 0;
  }

  & > svg {
    margin-top: 2px;
    width: 13px;
    height: 13px;
  }
`;

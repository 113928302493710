import { AnimatePresence, motion } from "framer-motion";

const variants = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.18,
      ease: "easeIn",
      type: "tween",
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.1,
      ease: "easeOut",
      type: "tween",
    },
  },
};

interface props {
  isOpen?: boolean;
  children: React.ReactNode;
}

const Fade: React.FC<props> = ({ isOpen, children }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div animate={isOpen ? "open" : "closed"} variants={variants}>
        {isOpen && children}
      </motion.div>
    </AnimatePresence>
  );
};

export default Fade;

import { ApiResponse } from "../../redux/types";
import request from "../request";
import { pushNotificationPayload } from "./types";

const PushNotification = async (payload: pushNotificationPayload) => {
  return await request.post<ApiResponse<string>>(
    `/pushnotification/sendpushnotificationtoactiveusers`,
    payload
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  PushNotification,
};

import { ColDef } from "ag-grid-community";
import { UsersResponse } from "../../../../../api/users/types";
import ActionRenderer from "./actionRenderer";
import { StatusRenderer } from "./statusRenderer";

type UsersField = keyof UsersResponse | "action" | "sn" | undefined;

export interface GridDef extends ColDef {
  field: UsersField;
}

export const columnDefsDesktop = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Sn",
      field: "sn",
      sortable: false,
      valueGetter: "node.rowIndex + 1",
      flex: 0.3,
    },
    {
      headerName: "Full Name",
      field: "fullName",
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1.2,
    },
    {
      headerName: "Email Address",
      field: "emailAddress",
      flex: 1.2,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Status",
      sortable: false,
      field: "isActive",
      filter: false,
      flex: 0.6,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Action",
      sortable: false,
      field: "isActive",
      filter: false,
      flex: 1.4,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

export const columnDefsTablet = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Sn",
      field: "sn",
      sortable: false,
      valueGetter: "node.rowIndex + 1",
      flex: 0.3,
    },
    {
      headerName: "Full Name",
      field: "fullName",
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1.2,
    },
    {
      headerName: "Email Address",
      field: "emailAddress",
      flex: 1.3,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
    },
    {
      headerName: "Action",
      sortable: false,
      field: "isActive",
      filter: false,
      flex: 1.4,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

export const columnDefsMobile = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "Sn",
      field: "sn",
      sortable: false,
      valueGetter: "node.rowIndex + 1",
      flex: 0.3,
    },
    {
      headerName: "Full Name",
      field: "fullName",
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      flex: 1.2,
    },
    {
      headerName: "Action",
      sortable: false,
      field: "isActive",
      filter: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
        fontSize: "12px",
      },
      cellRendererFramework: ActionRenderer,
    },
  ];

  return columnDefs;
};

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

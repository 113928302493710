import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import api from "../../../api";
import { faqObj } from "../../../api/faq/types";
import DeleteModal from "../../../components/deleteModal";
import DualRing from "../../../components/loader";
import { LoaderWrapper } from "./preferences";

interface props {
  data: faqObj;
  index: number;
  handleRemove: (index: number) => void;
  setReloadData: Dispatch<SetStateAction<boolean>>;
}

const FaqEntry: React.FC<props> = ({
  data,
  index,
  handleRemove,
  setReloadData,
}) => {
  const [formData, setFormData] = useState<faqObj>(data);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isValid = Object.values(formData).every((x) => x !== "");

  const handleChange = (name: string, value: string) => {
    setIsEdited(true);
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const res = data.id
        ? await api.faqService.UpdateFaq(data.id, formData)
        : await api.faqService.CreateFaqs(formData);
      res.data.data && toast.success("Saved successfully");
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsSaving(false);
    setIsEdited(false);
    setReloadData(true);
  };

  const handleDeleteBtnClick = () => {
    // if id exist, open delete modal, else remove unsaved entry
    //**subtracted the 1 added to the index in the parent
    data.id ? setIsModalOpen(true) : handleRemove(index - 1);
  };

  const handleDelete = async () => {
    if (data.id) {
      setIsDeleting(true);
      try {
        const res = await api.faqService.DeleteFaq(data.id);
        res.data.data && toast.success("Deleted successfully");
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsDeleting(false);
      setIsModalOpen(false);
      setReloadData(true);
    }
  };

  return (
    <Container id={index.toString()}>
      <Entry>
        <p>Question {index}</p>
        <input
          type="text"
          name="question"
          value={formData.question}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Entry>
      <Entry>
        <p>Answer {index}</p>
        <textarea
          value={formData.answer}
          name="answer"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Entry>
      <BtnWrapper>
        <SaveBtn onClick={() => handleSave()} disabled={!isEdited || !isValid}>
          {isSaving ? (
            <LoaderWrapper>
              <DualRing width="10px" height="10px" />
            </LoaderWrapper>
          ) : (
            "Save Question"
          )}
        </SaveBtn>
        <DeleteBtn onClick={() => handleDeleteBtnClick()}>
          Delete Question
        </DeleteBtn>
      </BtnWrapper>
      <DeleteModal
        handleClose={() => setIsModalOpen(false)}
        handleSubmit={() => handleDelete()}
        isOpen={isModalOpen}
        isDeleting={isDeleting}
      />
    </Container>
  );
};

export default FaqEntry;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Entry = styled.div`
  display: flex;
  gap: 15px;

  & > p {
    letter-spacing: -0.12px;
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    width: 80px;
    margin-top: 16px;
  }

  & > input {
    padding-left: 10px;
    background: #ffffff;
    border: 1px solid #b9b9b9;
    border-radius: 5px;
    width: 453px;
    height: 50px;
    outline: none;

    &:focus {
      border: 1px solid #faa84f;
    }
  }

  & > textarea {
    width: 453px;
    height: 121px;
    background: #ffffff;
    border: 1px solid #b9b9b9;
    border-radius: 5px;
    padding: 15px;
    outline: none;

    &:focus {
      border: 1px solid #faa84f;
    }
  }

  ${mediaQueries.smallDesktop} {
    gap: 10px;

    & > input,
    textarea {
      width: 400px;
    }

    & > p {
      margin-top: 0;
    }
  }

  ${mediaQueries.mobile} {
    flex-direction: column;

    & > input,
    textarea {
      width: 100%;
    }

    & > p {
      font-size: 14px;
    }
  }
`;

const BtnWrapper = styled.div`
  position: absolute;
  left: 580px;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${mediaQueries.smallDesktop} {
    left: 515px;
  }

  ${mediaQueries.mobile} {
    position: relative;
    left: 0;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    margin-top: 5px;
  }
`;

const SaveBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 34px;
  letter-spacing: -0.09px;
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  opacity: 1;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const DeleteBtn = styled(SaveBtn)`
  background: #ed38381a 0% 0% no-repeat padding-box;
  border: 1px solid #ed3838;
  color: #ed3838;
`;

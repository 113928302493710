import styled from "styled-components";
// import { ReactComponent as ChatIcon } from "../../images/Chat.svg";
import { ReactComponent as LogoutIcon } from "../../images/Logout.svg";
import { ReactComponent as OptimaLogo } from "../../images/logo.svg";
import { sidebarData } from "../../utils/data";
import CustomLink from "./customLink";
import { useAppDispatch } from "../redux";
import { logout } from "../redux/auth/actions";
import { useNavigate } from "react-router";
import storage from "../../utils/storage";
import mediaQueries from "../../utils/mediaQueries";

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cachedTokens = storage.get("optimaUser");

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login", { replace: true });
  };

  return (
    <Container>
      <ContentWrapper>
        <OptimaLogo />
        {/* <CustomLink link="/messages" icon={ChatIcon} title="Messages" /> */}
        <LinksWrapper>
          {sidebarData
            .filter((x) =>
              cachedTokens?.permissions?.includes(x.value.toUpperCase())
            )
            .map((item, index) => (
              <CustomLink key={index} {...item} />
            ))}
        </LinksWrapper>
        <CustomLink action={handleLogout} icon={LogoutIcon} title="Logout" />
      </ContentWrapper>
    </Container>
  );
};

export default Sidebar;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 16%;
  padding-top: 50px;
  height: 100vh;
  max-height: 100vh;
  background: #ffffff;
  border: 1px solid #e3e3e3;

  & > img {
    width: 165px;
    height: 50px;
  }

  ${mediaQueries.tablet} {
    display: none;
  }

  ${mediaQueries.mobile} {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 120px;
  width: 100%;
  max-height: 675px;
  height: 90%;
  background: #ffffff;

  & > svg {
    width: 165px;
    height: 50px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5vh;
  width: 100%;
`;

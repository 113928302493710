import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { TransactionActionPayload } from "../../../../../api/transactions/types";
import { TransactionStatusEnum } from "../../../../../../types/common";
import api from "../../../../../api";
import DualRing from "../../../../../components/loader";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  isOpen: boolean;
  handleClose: () => void;
  id?: string;
}

export const initialTransactionObj: TransactionActionPayload = {
  amount: "",
  comment: "",
  transactionStatus: undefined,
};

const PartialModal: React.FC<props> = ({ isOpen, handleClose, id }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState<TransactionActionPayload>();
  const navigate = useNavigate();

  const handleChange = (name: string, value: string | number) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleCloseModal = () => {
    handleClose();
    setFormData(initialTransactionObj);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (formData && id) {
        if (formData.amount && isNaN(Number(formData.amount))) {
          toast.error("Amount is not a number");
          setIsLoading(false);
          return;
        }
        const res = await api.cryptoSaleService.cryptoSaleAction(id, {
          ...formData,
          transactionStatus: TransactionStatusEnum.Partial,
          amount: Number(formData.amount),
        });
        res?.data.data &&
          toast.success("Transaction has been partially approved");
      }
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
    handleCloseModal();
    setTimeout(() => {
      navigate("/dashboard/transactions/cryptocurrency", { replace: true });
    }, 1500);
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          fontSize: 22,
          paddingTop: 5,
          paddingBottom: 3,
          paddingLeft: 4,
          fontFamily: "Plus Jakarta Sans",
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        Partial Approval
      </DialogTitle>
      <DialogContent
        sx={{
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <DialogContentText id="alert-dialog-slide-description">
          <ContentWrapper>
            <TopContent>
              <p>Enter Amount Approved:</p>
              <input
                type="text"
                name="amount"
                value={formData?.amount}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </TopContent>
            <textarea
              rows={8}
              name="comment"
              maxLength={85}
              value={formData?.comment}
              placeholder="Enter feedback for customer here"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </ContentWrapper>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <ButtonWrapper>
          <CancelButton onClick={() => handleCloseModal()}>
            Go back
          </CancelButton>
          <ConfirmButton
            onClick={() => handleSubmit()}
            disabled={!formData?.comment || !formData?.amount}
          >
            {isLoading ? (
              <DualRing width="10px" height="10px" />
            ) : (
              "Confirm Approval"
            )}
          </ConfirmButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default PartialModal;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding: 20px 0px 15px;
  border-top: 1px solid #c3c3c3;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  letter-spacing: -0.2px;
  font-weight: bold;
  font-size: 12px;
  color: #727272;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    color: #4d4b4b;
  }
`;

const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  background: #1aba27;
  letter-spacing: -0.22px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  outline: none;
  border: none;
  cursor: pointer;

  & > div {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  &:disabled {
    background-color: #727272;
    cursor: not-allowed;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > textarea {
    height: 135px;
    padding: 10px;
    background: #ffffff;
    border: 0.5px solid #707070;
    border-radius: 5px;
    outline: none;
  }
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > p {
    letter-spacing: -0.26px;
    color: #000000;
    font-size: 13px;
    font-weight: 500;
  }

  & > input {
    height: 42px;
    width: 60%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 5px;
    outline: none;
    padding-left: 12px;
  }
`;

import styled from "styled-components";
import mediaQueries from "../../../../../../utils/mediaQueries";

export const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "600",
  "letter-spacing": "-0.02em",
  "font-size": "14px",
  "line-height": "18px",
};

export const GridWrapper = styled.div`
  height: 500px;
  width: 100%;
  margin-bottom: 10px;

  ${mediaQueries.tablet} {
    height: 400px;
  }
`;

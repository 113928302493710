import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { cardSoldType } from "../../../api/transactions/types";

interface props {
  data?: cardSoldType[];
}

const TransactionDetails: React.FC<props> = ({ data }) => {
  return (
    <Container>
      <Header>
        <p>Denomination</p>
        <p>Gift Card Value</p>
        <p>Rate</p>
        <p>Quantity</p>
      </Header>
      <BodyWrapper>
        {data?.map((x) => (
          <DetailEntry>
            <p>{x.denomination}</p>
            <p>{x.actualGiftCardDenomination}</p>
            <p>{x.amount}</p>
            <p>{x.quantity}</p>
          </DetailEntry>
        ))}
      </BodyWrapper>
    </Container>
  );
};

export default TransactionDetails;

const Container = styled.div`
  width: 100%;
  height: 210px;
  padding: 20px;
  background: #fdfdfd;
  border: 0.5px solid #707070;
  border-radius: 5px;

  ${mediaQueries.mobile} {
    padding: 12px;
  }
`;

const Header = styled.tbody`
  display: flex;

  & > p {
    letter-spacing: -0.1px;
    color: #7c7c7c;
    font-weight: 600;
    font-size: 12px;
    width: min(28%, 150px);
  }

  & > p:last-of-type {
    width: min(200px, 30%);
  }

  ${mediaQueries.tablet} {
    & > p {
      min-width: 28%;
    }
  }

  ${mediaQueries.mobile} {
    & > p {
      min-width: 30%;
    }
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 145px;
  overflow: scroll;
  margin-top: 14px;
`;

const DetailEntry = styled.div`
  display: flex;

  & > p {
    letter-spacing: -0.1px;
    color: #080808;
    font-size: 12px;
    width: min(28%, 150px);
  }

  & > p:last-of-type {
    width: min(200px, 30%);
  }
`;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { contactPageInterface } from "../../../../types/common";
import api from "../../../api";
import { contactObj, contactRes } from "../../../api/contact/types";
import { componentSlide } from "../../../components/animate/variants";
import CancelModal from "../../../components/cancelModal";
import DualRing from "../../../components/loader";
import { EntryTitle, EntryWrapper } from "../../Profile/components/basicInfo";
import { PasswordInput } from "../../Profile/components/passwordInput";
import {
  BottomWrapper,
  ButtonWrapper,
  CancelButton,
  SaveButton,
} from "../../Profile/components/security";
import { contactLabelData } from "../data";
import { LoaderWrapper } from "./preferences";
import styled from "styled-components";
import { motion } from "framer-motion";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [formData, setFormData] =
    useState<contactPageInterface[]>(contactLabelData);
  const navigate = useNavigate();

  useEffect(() => {
    async function getContacts() {
      try {
        const res = await api.contactService.getContacts();
        res.data.data && parseToContactObj(res.data.data);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    getContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (name: string, value: string) => {
    const index = formData.findIndex((x) => x.name === name);
    formData.splice(index, 1, {
      label: formData[index].label,
      url: value,
      name: formData[index].name,
    });
    setFormData([...formData]);
    setIsEdited(true);
  };

  const parseToContactPayload = (data: contactPageInterface[]) => {
    const arr: contactObj[] = [];
    data.forEach((x) => {
      arr.push({
        name: x.name,
        address: x.url,
      });
    });

    return arr;
  };

  const parseToContactObj = (data: contactRes[]) => {
    data.forEach((res) => {
      const index = formData.findIndex(
        (x) => res.name.toLowerCase() === x.name.toLowerCase()
      );
      if (index > -1) {
        formData[index].url = res.address;
        setFormData([...formData]);
      }
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await api.contactService.createContact({
        contactsDTO: parseToContactPayload(formData),
      });
      res.data.data && toast.success("Details updated successfully");
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <Container
      variants={componentSlide}
      exit={"exit"}
      animate={"animate"}
      initial={"initial"}
      transition={{ duration: 0.5 }}
    >
      <ContentWrapper>
        {contactLabelData.map((x, i) => (
          <EntryWrapper key={i}>
            <EntryTitle>{x.label}</EntryTitle>
            <PasswordInput
              value={formData[i]?.url}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name={x.name}
            />
          </EntryWrapper>
        ))}
        <BottomWrapper>
          <ButtonWrapper>
            <CancelButton onClick={() => setIsCancelOpen(true)}>
              Cancel
            </CancelButton>
            <SaveButton onClick={() => handleSubmit()} disabled={!isEdited}>
              {isLoading ? (
                <LoaderWrapper>
                  <DualRing width="10px" height="10px" />
                </LoaderWrapper>
              ) : (
                "Save Changes"
              )}
            </SaveButton>
          </ButtonWrapper>
        </BottomWrapper>
      </ContentWrapper>
      <CancelModal
        handleClose={() => setIsCancelOpen(false)}
        isOpen={isCancelOpen}
        handleSubmit={() => navigate("/dashboard", { replace: true })}
      />
    </Container>
  );
};

export default Contact;

const Container = styled(motion.div)``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: fit-content;
`;

import { useState } from "react";
import styled from "styled-components";
import ApproveModal from "./actionModals/approve";
import PartialModal from "./actionModals/partialApprove";

interface props {
  onClose: () => void;
  id?: string;
  amount?: number;
}

const ActionDropdown: React.FC<props> = ({ onClose, id, amount }) => {
  const [isPartialOpen, setIsPartialOpen] = useState(false);
  const [isApproveOpen, setIsApproveOpen] = useState(false);

  return (
    <>
      <Container>
        <Entry
          onClick={() => {
            setIsApproveOpen(true);
          }}
        >
          Full Approval
        </Entry>
        <Divider />
        <Entry
          onClick={() => {
            setIsPartialOpen(true);
          }}
        >
          Partial Approval
        </Entry>
      </Container>
      <PartialModal
        handleClose={() => {
          setIsPartialOpen(false);
          onClose();
        }}
        isOpen={isPartialOpen}
        id={id}
      />
      <ApproveModal
        handleClose={() => {
          setIsApproveOpen(false);
          onClose();
        }}
        isOpen={isApproveOpen}
        amount={amount}
        id={id}
      />
    </>
  );
};

export default ActionDropdown;

const Container = styled.div`
  position: absolute;
  top: -100px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 7px 10px;
  background: #ffffff;
  box-shadow: 0px 3px 20px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

const Entry = styled.p`
  letter-spacing: -0.13px;
  color: #000000;
  font-size: 13px;
  padding: 7px 8px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background: #eaeaea;
  }
`;

const Divider = styled.div`
  border-bottom: 0.5px solid #d5d5d5;
  width: 100%;
`;

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import api from "../../../../../api";
import { TransactionStatusEnum } from "../../../../../../types/common";
import DualRing from "../../../../../components/loader";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  isOpen: boolean;
  id?: string;
  amount?: number;
  handleClose: () => void;
}

const ApproveModal: React.FC<props> = ({ isOpen, handleClose, id, amount }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (id) {
        const res = await api.cryptoSaleService.cryptoSaleAction(id, {
          amount: amount,
          transactionStatus: TransactionStatusEnum.Approved,
          comment: "",
        });

        res?.data.data && toast.success("Transaction has been approved");
      }
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
    setIsLoading(false);
    handleClose();
    setTimeout(() => {
      navigate("/dashboard/transactions/cryptocurrency", { replace: true });
    }, 1500);
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth={"xs"}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          fontSize: 21,
          paddingTop: 4,
          fontFamily: "Plus Jakarta Sans",
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        Approve this transaction and credit user’s wallet?
      </DialogTitle>
      <DialogActions>
        <ButtonWrapper>
          <CancelButton onClick={handleClose}>No, Go back</CancelButton>
          <ConfirmButton onClick={() => handleSubmit()}>
            {isLoading ? (
              <DualRing width="10px" height="10px" />
            ) : (
              "Yes, Approve"
            )}
          </ConfirmButton>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveModal;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding: 20px 15px 15px;
  border-top: 1px solid #c3c3c3;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  letter-spacing: -0.2px;
  font-weight: bold;
  font-size: 12px;
  color: #727272;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    color: #4d4b4b;
  }
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 33px;
  background: #1aba27;
  letter-spacing: -0.22px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;

  & > div {
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

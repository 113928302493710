import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CardState } from "./types";
import { getActiveCards, getDisabledCards, getInactiveCards } from "./actions";
import { RootState } from "..";

const initialState: CardState = {
  activeCards: {
    error: null,
    pending: false,
  },
  disabledCards: {
    error: null,
    pending: false,
  },
  inactiveCards: {
    error: null,
    pending: false,
  },
  refreshNeeded: false,
};

const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    refreshNeeded: (state, action: PayloadAction<boolean>) => {
      state.refreshNeeded = action.payload;
    },
    resetCards: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getActiveCards.pending, (state) => {
        return {
          ...state,
          activeCards: {
            ...state.activeCards,
            pending: true,
            error: null,
            response: undefined,
          },
        };
      })
      .addCase(getActiveCards.fulfilled, (state, { payload }) => {
        return {
          ...state,
          activeCards: {
            ...state.activeCards,
            pending: false,
            error: null,
            response: payload,
          },
        };
      })
      .addCase(getActiveCards.rejected, (state, action) => {
        return {
          ...state,
          activeCards: {
            ...state.activeCards,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
    builder
      .addCase(getInactiveCards.pending, (state) => {
        return {
          ...state,
          inactiveCards: {
            ...state.inactiveCards,
            pending: true,
            error: null,
            response: undefined,
          },
        };
      })
      .addCase(getInactiveCards.fulfilled, (state, { payload }) => {
        return {
          ...state,
          inactiveCards: {
            ...state.inactiveCards,
            pending: false,
            error: null,
            response: payload,
          },
        };
      })
      .addCase(getInactiveCards.rejected, (state, action) => {
        return {
          ...state,
          inactiveCards: {
            ...state.inactiveCards,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
    builder
      .addCase(getDisabledCards.pending, (state) => {
        return {
          ...state,
          disabledCards: {
            ...state.disabledCards,
            pending: true,
            error: null,
            response: undefined,
          },
        };
      })
      .addCase(getDisabledCards.fulfilled, (state, { payload }) => {
        return {
          ...state,
          disabledCards: {
            ...state.disabledCards,
            pending: false,
            error: null,
            response: payload,
          },
        };
      })
      .addCase(getDisabledCards.rejected, (state, action) => {
        return {
          ...state,
          disabledCards: {
            ...state.disabledCards,
            pending: false,
            error: action.payload,
            response: null,
          },
        };
      });
  },
});

export const { refreshNeeded, resetCards } = cardSlice.actions;

export const activeCardSelector = (state: RootState) => state.cards.activeCards;
export const inactiveCardSelector = (state: RootState) =>
  state.cards.inactiveCards;
export const disabledCardSelector = (state: RootState) =>
  state.cards.disabledCards;
export const refreshCardsSelector = (state: RootState) =>
  state.cards.refreshNeeded;

export default cardSlice.reducer;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import DualRing from "../../../components/loader";
import { LoaderWrapper } from "./preferences";
import { ReactComponent as UploadIcon } from "../../../../images/upload-card.svg";
import { motion } from "framer-motion";
import { componentSlide } from "../../../components/animate/variants";
import mediaQueries from "../../../../utils/mediaQueries";

const HomeScreen = () => {
  const [documentToDisplay, setDocumentToDisplay] = useState<string>("");
  const [documentToUpload, setDocumentToUpload] = useState<File>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    async function getAd() {
      try {
        const res = await api.adService.getAd();
        setDocumentToDisplay(res.data.data.url);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }

    getAd();
  }, []);

  const selectdocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let file = e!.target!.files![0];
      // check if file is greater than 1mb
      if (file.size > 1e6) {
        toast.error("Please upload file less than 1mb");
        return;
      }
      if (file.type.includes("image")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          if (event.target && event.target.result) {
            setDocumentToDisplay(event.target.result.toString());
            setDocumentToUpload(file);
          }
        });
      }
    }
  };

  //used any type to target the id of the element
  const handleFilePicking = (e: any) => {
    if (e.target.id !== "delete") {
      document!.getElementById("document")!.click();
    }
  };

  const handleFileDelete = () => {
    setDocumentToDisplay("");
    setDocumentToUpload(undefined);
  };

  const handleSubmit = async () => {
    if (documentToUpload) {
      setIsSubmitting(true);
      try {
        const res = await api.adService.uploadAd(documentToUpload);
        res.data.data && toast.success("ad uploaded successfully");
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Container
      variants={componentSlide}
      exit={"exit"}
      animate={"animate"}
      initial={"initial"}
      transition={{ duration: 0.5 }}
    >
      <UploadWrapper>
        <input
          type="file"
          id="document"
          accept="image/png,image/jpg,image/jpeg"
          onChange={selectdocument}
        />
        <UploadBox>
          {!documentToDisplay ? (
            <UploadImageWrapper
              onClick={() => document!.getElementById("document")!.click()}
            >
              <UploadIcon />
              <p>Browse files</p>
              <span>Supported formats: PNG, JPG, JPEG</span>
            </UploadImageWrapper>
          ) : (
            <DocumentWrapper onClick={(e) => handleFilePicking(e)}>
              <img src={documentToDisplay} alt="" />
              <span id="delete" onClick={() => handleFileDelete()}>
                x
              </span>
            </DocumentWrapper>
          )}
        </UploadBox>
      </UploadWrapper>
      <RightContentWrapper>
        <UploadButton onClick={() => handleSubmit()}>
          {isSubmitting ? (
            <LoaderWrapper>
              <DualRing width="10px" height="10px" />
            </LoaderWrapper>
          ) : (
            "Save Image"
          )}
        </UploadButton>
        <FileFormatWrapper>
          <p>The recommended size of this image is 2000x2333 pixels.</p>
          <p>Supported formats: PNG, JPG, JPEG</p>
        </FileFormatWrapper>
      </RightContentWrapper>
    </Container>
  );
};

export default HomeScreen;

const Container = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  gap: 25px;

  ${mediaQueries.mobile} {
    flex-direction: column;
  }
`;

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > p {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #000000;
  }

  & > input {
    display: none;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const UploadImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  cursor: pointer;

  & > p {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #faa84f;
    margin-top: 5px;
  }

  & > span {
    font-size: 8px;
    letter-spacing: -0.08px;
    color: #7c7c7c;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.11px;
  color: #ffffff;
  font-size: 11px;
  font-weight: 800;
  width: 300px;
  height: 40px;
  background: transparent linear-gradient(163deg, #2b5a9d 0%, #041630 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  cursor: pointer;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const UploadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 307px;
  height: 467px;
  background: #f8f8f8;
  border: 1px solid #707070;
  border-radius: 10px;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const DocumentWrapper = styled.div`
  position: relative;
  width: 94%;
  height: 95%;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }

  & > span {
    position: absolute;
    top: 7px;
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #131519;
    color: #fff;
    cursor: pointer;
  }
`;

const RightContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 300px;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const FileFormatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  & > p {
    letter-spacing: -0.11px;
    color: #727272;
    font-size: 13px;
    width: 75%;
    text-align: center;
  }
`;

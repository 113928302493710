import {
  ButtonWrapper,
  CancelButton,
  Container,
  ContentWrapper,
  ContinueButton,
  NewCardWrapper,
  TopWrapper,
} from "./selectType";
import { ReactComponent as LeftIcon } from "../../../images/left-arrow.svg";
import { ReactComponent as UploadIcon } from "../../../images/upload-card.svg";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import Select, { ActionMeta } from "react-select";
import { useEffect, useState } from "react";
import { dropDownType } from "../../../types/common";
import CancelModal from "../../components/cancelModal";
import api from "../../api";
import { toast } from "react-toastify";
import { CountriesResponse } from "../../api/countries/types";
import { LoaderWrapper } from "../Preferences/components/preferences";
import DualRing from "../../components/loader";
import mediaQueries from "../../../utils/mediaQueries";

const NewCard = () => {
  const [apiCountries, setApiCountries] = useState<CountriesResponse[]>([]);
  const [country, setCountry] = useState<string>();
  const [countriesArray, setCountriesArray] = useState<string[]>([]);
  const [documentToDisplay, setDocumentToDisplay] = useState<string>("");
  const [documentToUpload, setDocumentToUpload] = useState<File>();
  const [cardName, setCardName] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    async function getCountries() {
      const res = await api.countriesService.getCountries();
      setApiCountries(res.data.data);
    }
    getCountries();
  }, []);

  const handleNavigate = () => {
    navigate("/dashboard/cards/type", { replace: true });
  };

  const dropDownOptions = () => {
    const arr: dropDownType[] = [];
    apiCountries.forEach((x) => {
      arr.push({ value: x?.id, label: x?.name });
    });

    return arr;
  };

  const onTypeChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    setCountry(newValue.value);
  };

  const handleAdd = () => {
    if (!country) {
      toast.info("Please select a country");
      return;
    }
    if (countriesArray.includes(country)) {
      toast.error("Country already selected");
      return;
    }
    setCountriesArray([...countriesArray, country]);
    setCountry("");
  };

  const handleDelete = (index: number) => {
    const tempArr = [...countriesArray];
    tempArr.splice(index, 1);
    setCountriesArray(tempArr);
  };

  const selectdocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e!.target!.files!.length > 0) {
      let file = e!.target!.files![0];
      // check if file is greater than 1mb
      if (file.size > 1e6) {
        toast.error("Please upload file less than 1mb");
        return;
      }
      if (file.type.includes("image")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          if (event.target && event.target.result) {
            setDocumentToDisplay(event.target.result.toString());
            setDocumentToUpload(file);
          }
        });
      }
    }
  };

  //used any type to target the id of the element
  const handleFilePicking = (e: any) => {
    if (e.target.id !== "delete") {
      document!.getElementById("document")!.click();
    }
  };

  const handleFileDelete = () => {
    setDocumentToDisplay("");
    setDocumentToUpload(undefined);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await api.cardService.createCard({
        baseCardType: params?.id,
        countryIds: countriesArray,
        logo: documentToUpload,
        name: cardName,
      });

      if (res.data.data.id) {
        toast.success("Card created successfully");
        navigate("/dashboard/cards");
      }
    } catch (error) {
      const errorMessage = error as any;
      toast.error(errorMessage?.toString() || "Card creation failed");
    }
    setIsSubmitting(false);
  };

  const isValid =
    countriesArray.length > 0 && !!cardName && !!documentToDisplay;

  return (
    <Container>
      <h1>Manage Cards</h1>
      <AddContentWrapper>
        <TopWrapper>
          <div onClick={() => handleNavigate()}>
            <LeftIcon />
            <p>Back</p>
          </div>
        </TopWrapper>
        <NewCardWrapper>
          <h1>Add New Card</h1>
          <p>Input Card Details</p>
          <MainContentWrapper>
            <FormContainer>
              <InputEntry>
                <p>Enter Card Name</p>
                <input
                  type="text"
                  value={cardName}
                  onChange={(e) => setCardName(e.target.value)}
                />
              </InputEntry>
              <InputEntry>
                <p>Select Countries</p>
                <div>
                  <Select
                    options={dropDownOptions()}
                    value={dropDownOptions().filter((x) => x.value === country)}
                    onChange={onTypeChange}
                  />
                  <AddButton onClick={handleAdd}>Add</AddButton>
                </div>
              </InputEntry>
              <SelectedCountries>
                {countriesArray.map((x, i) => (
                  <CountryCard>
                    <p>
                      {apiCountries.find((country) => country.id === x)?.name}
                    </p>
                    <span onClick={() => handleDelete(i)}>x</span>
                  </CountryCard>
                ))}
              </SelectedCountries>
            </FormContainer>
            <UploadWrapper>
              <p>Upload Card Image</p>
              <input
                type="file"
                id="document"
                accept="image/png,image/jpg,image/jpeg"
                onChange={selectdocument}
              />
              <UploadBox>
                {!documentToDisplay ? (
                  <UploadImageWrapper
                    onClick={() =>
                      document!.getElementById("document")!.click()
                    }
                  >
                    <UploadIcon />
                    <p>Browse files</p>
                    <span>Supported formats: PNG, JPG, JPEG</span>
                  </UploadImageWrapper>
                ) : (
                  <DocumentWrapper onClick={(e) => handleFilePicking(e)}>
                    <img src={documentToDisplay} alt="" />
                    <span id="delete" onClick={() => handleFileDelete()}>
                      x
                    </span>
                  </DocumentWrapper>
                )}
              </UploadBox>
            </UploadWrapper>
          </MainContentWrapper>
          <ButtonWrapper>
            <CancelButton onClick={() => setIsOpen(true)}>Cancel</CancelButton>
            <ContinueButton onClick={handleSubmit} disabled={!isValid}>
              {isSubmitting ? (
                <LoaderWrapper>
                  <DualRing width="10px" height="10px" />
                </LoaderWrapper>
              ) : (
                "Save"
              )}
            </ContinueButton>
          </ButtonWrapper>
        </NewCardWrapper>
      </AddContentWrapper>
      <CancelModal
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
        handleSubmit={() => navigate("/dashboard/cards", { replace: true })}
      />
    </Container>
  );
};

export default NewCard;

const AddContentWrapper = styled(ContentWrapper)`
  width: 100%;
`;

const MainContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  min-height: 280px;

  ${mediaQueries.mobile} {
    flex-direction: column;
    margin-top: 30px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 47%;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const InputEntry = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  & > p {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #000000;
  }

  & > input {
    width: 100%;
    max-width: 430px;
    height: 45px;
    background: #f7f7f7;
    padding-left: 12px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #b9b9b9;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;

    & > div {
      width: 50%;
    }
  }

  .css-1s2u09g-control {
    cursor: pointer;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;

  & > p {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #000000;
  }

  & > input {
    display: none;
  }

  ${mediaQueries.tablet} {
    width: 47%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

export const UploadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #727272;
  border-radius: 5px;
  width: 100%;
  height: 240px;
`;

export const UploadImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  cursor: pointer;

  & > p {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.11px;
    color: #faa84f;
    margin-top: 5px;
  }

  & > span {
    font-size: 8px;
    letter-spacing: -0.08px;
    color: #7c7c7c;
  }
`;

const SelectedCountries = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
`;

const CountryCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px;
  height: 38px;
  background: transparent linear-gradient(180deg, #faa84f 0%, #f05c38 100%) 0%
    0% no-repeat padding-box;
  border-radius: 14px;

  & > p {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.14px;
    color: #ffffff;
  }

  & > span {
    position: absolute;
    top: 0;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    background: #fdd5d5;
    color: #f05c38;
    border-radius: 50%;
    font-size: 14px;
    padding-bottom: 4px;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const AddButton = styled(ContinueButton)`
  width: 75px;
`;

export const DocumentWrapper = styled.div`
  position: relative;
  width: 90%;
  height: 90%;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }

  & > span {
    position: absolute;
    top: 7px;
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #131519;
    color: #fff;
    cursor: pointer;
  }

  ${mediaQueries.mobileAndTablet} {
    width: 95%;
    height: 92%;
  }
`;

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { cryptoSaleResponse } from "../../../../../api/cryptoSale/types";
import mediaQueries from "../../../../../../utils/mediaQueries";

const ActionRenderer = ({ data }: { data: cryptoSaleResponse }) => {
  const navigate = useNavigate();

  return (
    <ActionButton
      onClick={() =>
        navigate(`/dashboard/transactions/cryptocurrency/${data.id}`)
      }
    >
      View Details
    </ActionButton>
  );
};

export default ActionRenderer;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 29px;
  letter-spacing: -0.09px;
  color: #ffffff;
  font-weight: bold;
  font-size: 9px;
  background: #faa84f;
  border-radius: 3px;
  cursor: pointer;

  ${mediaQueries.mobile} {
    width: 82px;
  }
`;

import { combineReducers } from "redux";
import auth from "./auth/authSlice";
import cards from "./cards/cardSlice";
import crypto from "./crypto/cryptoSlice";
import cryptoSale from "./cryptoSale/cryptoSaleSlice";
import users from "./users/userSlice";
import notifications from "./notifications/notificationSlice";

const RootReducer = combineReducers({
  auth,
  cards,
  users,
  notifications,
  crypto,
  cryptoSale,
});

export default RootReducer;

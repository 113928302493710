import React, { useRef } from "react";
import {
  AgGridEvent,
  GridApi,
  GridColumnsChangedEvent,
  GridReadyEvent,
} from "ag-grid-community";
import { gridCellStyle, GridWrapper } from "./gridComponents/styles";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import EmptyStateRenderer from "./gridComponents/emptyStateRenderer";
import { UsersResponse } from "../../../../api/users/types";
import {
  UserGridConfigTypes,
  UserGridDef,
  UserGridProps,
} from "../activeGrid/grid";
import { useMobileCheck } from "../../../../../hooks/useMobileCheck";
import {
  columnDefsDesktop,
  columnDefsMobile,
  columnDefsTablet,
} from "./gridComponents/columnDef";
import mediaQueries from "../../../../../utils/mediaQueries";

const PendingGrid: React.FC<UserGridProps> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const { isMobile, isTablet } = useMobileCheck();

  const columnDefs: UserGridDef[] = isTablet
    ? columnDefsTablet()
    : isMobile
    ? columnDefsMobile()
    : columnDefsDesktop();

  const gridConfig: UserGridConfigTypes = {
    columnDefs: columnDefs,
    rowData: data,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <>
      {data?.length > 0 ? (
        <GridWrapper id="OpenOrdersGridWrapper" className={`ag-theme-balham`}>
          <AgGridReact
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={43}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            defaultColDef={{
              sortable: true,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            getRowNodeId={(data: UsersResponse) => {
              return data.id.toString();
            }}
            noRowsOverlayComponentFramework={EmptyStateRenderer}
            gridOptions={{
              onSortChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onFilterChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onGridColumnsChanged(e: GridColumnsChangedEvent) {
                e.api.refreshCells();
              },
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>No pending users found</p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default PendingGrid;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 150px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }

  ${mediaQueries.mobile} {
    & > p {
      font-size: 15px;
    }
  }
`;

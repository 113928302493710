import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAndSearchObj } from "../../../types/common";
import parseError from "../../../utils/parseError";
import api from "../../api";

export const getActiveUsers = createAsyncThunk(
  "user/getActiveUsers",
  async (payload: getAndSearchObj, thunkAPI) => {
    const { currentPage, filter } = payload;
    try {
      const response = await api.userService.getActiveUsers(
        currentPage,
        filter
      );
      return response.data;
    } catch (error) {
      let errorMessage = parseError(error as any);
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
export const getInactiveUsers = createAsyncThunk(
  "user/getInactiveUsers",
  async (payload: getAndSearchObj, thunkAPI) => {
    const { currentPage, filter } = payload;
    try {
      const response = await api.userService.getInactiveUsers(
        currentPage,
        filter
      );
      return response.data;
    } catch (error) {
      let errorMessage = parseError(error as any);
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
export const getDisabledUsers = createAsyncThunk(
  "user/getDisabledUsers",
  async (payload: getAndSearchObj, thunkAPI) => {
    const { currentPage, filter } = payload;
    try {
      const response = await api.userService.getDisabledUsers(
        currentPage,
        filter
      );
      return response.data;
    } catch (error) {
      let errorMessage = parseError(error as any);
      errorMessage =
        errorMessage.responseMessage || errorMessage.errors[0] || errorMessage;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
